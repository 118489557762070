import {Deserializable} from "./deserializable.interface";

export class EncuestaResultadoCabeceraModel implements Deserializable<EncuestaResultadoCabeceraModel> {
  public estadoEncuesta: string;
  public nombreEncuesta: string;
  public nombreEmpresa: string;
  public tiempoRestante: string;
  public fechaEnvio: string;
  public fechaLimiteRespuesta: string;
  public cantPregObligatorias: number;
  public cantTotalPreguntas: number;
  public cantidadParticipantes: number;
  public respondidas: number;
  public incompletas: number;
  public norespondidas: number;

  constructor(encuesta?: any) {
    this.estadoEncuesta = encuesta && encuesta.estadoEncuesta || null;
    this.nombreEncuesta = encuesta && encuesta.nombreEncuesta || null;
    this.nombreEmpresa = encuesta && encuesta.nombreEmpresa || null;
    this.tiempoRestante = encuesta && encuesta.tiempoRestante || null;
    this.fechaEnvio = encuesta && encuesta.fechaEnvio || null;
    this.fechaLimiteRespuesta = encuesta && encuesta.fechaLimiteRespuesta || null;
    this.cantPregObligatorias = encuesta && encuesta.cantPregObligatorias || 0;
    this.cantTotalPreguntas = encuesta && encuesta.cantTotalPreguntas || 0;
    this.cantidadParticipantes = encuesta && encuesta.cantidadParticipantes || 0;
    this.respondidas = encuesta && encuesta.respondidas || 0;
    this.incompletas = encuesta && encuesta.incompletas || 0;
    this.norespondidas = encuesta && encuesta.norespondidas || 0;
  }

  deserialize(input: any): EncuestaResultadoCabeceraModel {
    Object.assign(this, input);
    return this;
  }

}


