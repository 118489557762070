import {Deserializable} from "./deserializable.interface";

import {CursoModel} from './curso.model';
import {EmpresaModel} from './empresa.model';

export class EncuestaModel implements Deserializable<EncuestaModel> {
  public idEncuesta: number;
  public nombreEncuesta: string;
  public codigoEncuesta: string;
  public idPlanillaEncuesta: number;
  public preguntasPlantillaEncuesta: string;
  public empresa: EmpresaModel;
  public curso: CursoModel;
  public fechaEnvio: string;
  public fechaMaxRpta: string;
  public fechaLimiteRespuesta: string;
  public fechaRecordatorio: string;
  public recordatorio: number;
  public permiteMasRespuestas: boolean;
  public estadoServicio: boolean;
  public estadoEncuesta: number;
  public urlEncuesta: string;
  public textoDespedida: string;
  public participantes: string;
  public plantillasCorreos: string;
  public identificadorEncuesta: string;
  public hashUUID: string;
  public eliminado: boolean;

  constructor(encuesta?: any) {
    this.idEncuesta = encuesta && encuesta.idEncuesta || null;
    this.nombreEncuesta = encuesta && encuesta.nombreEncuesta || null;
    this.codigoEncuesta = encuesta && encuesta.codigoEncuesta || null;
    this.idPlanillaEncuesta = encuesta && encuesta.idPlanillaEncuesta || null;
    this.preguntasPlantillaEncuesta = encuesta && encuesta.preguntasPlantillaEncuesta || null;
    this.empresa = encuesta && encuesta.empresa || null;
    this.curso = encuesta && encuesta.curso || null;
    this.fechaEnvio = encuesta && encuesta.fechaEnvio || null;
    this.fechaMaxRpta = encuesta && encuesta.fechaMaxRpta || null;
    this.fechaLimiteRespuesta = encuesta && encuesta.fechaLimiteRespuesta || null;
    this.fechaRecordatorio = encuesta && encuesta.fechaRecordatorio || null;
    this.recordatorio = encuesta && encuesta.recordatorio || null;
    this.urlEncuesta = encuesta && encuesta.urlEncuesta || null;
    this.textoDespedida = encuesta && encuesta.textoDespedida || null;
    this.participantes = encuesta && encuesta.participantes || null;
    this.plantillasCorreos = encuesta && encuesta.plantillasCorreos || null;
    this.identificadorEncuesta = encuesta && encuesta.identificadorEncuesta || null;
    if (encuesta) {
      this.permiteMasRespuestas = encuesta.permiteMasRespuestas;
      this.estadoServicio = encuesta.estadoServicio;
      this.estadoEncuesta = encuesta.estadoEncuesta;
      this.eliminado = encuesta.eliminado;
    } else {
      this.permiteMasRespuestas = null;
      this.estadoServicio = null;
      this.estadoEncuesta = null;
      this.eliminado = null;
    }
    this.hashUUID = encuesta && encuesta.hashUUID || null;
  }

  deserialize(input: any): EncuestaModel {
    Object.assign(this, input);
    return this;
  }
}
