import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserLoginService } from '../../services/user-login.service';
import { LoggedInCallback, CognitoUtilService, Callback } from '../../services/cognito.service';
import { UserParameterService } from '../../services/user-parameter.service';
import { LocalStorageService } from 'angular-web-storage';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { GLOBALS } from '../../shared/globals.shared';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, LoggedInCallback, Callback {
  notifications: any; //@todo: refactor to model
  userData : any = {nombre: null};
  isActive: boolean = false;

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private router: Router,
              private userLoginService: UserLoginService,
              private cognitoUtilService: CognitoUtilService,
              private userParameterService: UserParameterService,
              private localStorageService: LocalStorageService) {
    this.userLoginService.isAuthenticated(this);
    console.log('HeaderComponent: constructor');

    this.notifications = {//@TODO: pasar a model
      title : '¡Tienes notificaciones!',
      message : 'Tienes 20 cursos en Plan de Capacitación sin planificar.',
      total : 20
    };
  }

  ngOnInit(): void {
    console.log('HeaderComponent:on init');
  }

  isLoggedIn(message: string, isLoggedIn: boolean): void{
    console.log('HeaderComponent: verificacion sesion');

    if (!isLoggedIn){
      console.log('GestionCursosListComponent: usuario no autenticado, redireccionando al login.');
      // this.router.navigate(['/login']);
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    }else{
      this.userData = this.localStorageService.get('userData');

      if (this.userData != null && this.userData.nombre === null){
        this.userParameterService.getParameters(this);
      }
    }
  }

  callback(): void{
  }

  callbackWithParam(result: any): void{
    console.log(result);
    result.map(userAttr => {
      if (userAttr.Name === 'name'){
        this.userData.nombre = userAttr.Value;
      }
    });

    this.localStorageService.set('userData', this.userData, 0, 's');
  }

  doLogout(): void {
    console.log('HeaderComponent: cerrando sesion');

    this.localStorageService.remove('userData');
    this.localStorageService.remove('accessToken');
    this.localStorageService.remove('idToken');
    this.userLoginService.logout();
  }

  openModalCambiarEmpresa(refModalCambiarEmpresa): void{
    this.modalService.open(refModalCambiarEmpresa);
  }

  setEmpresa(): void{

  }

  btnOpenSandwich(){
    this.isActive = !this.isActive;
  }

}
