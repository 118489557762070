<nav class="navbar navbar-expand-xl navbar-light bg-light">
  <a [routerLink]="['/']" class="navbar-brand">SISTEMA DE ENCUESTAS DE EFECTIVIDAD<br/><span>OTIC-SOFOFA</span></a>


  <div class="navbar-collapse">
    <div class="float-right ml-auto row margin-right-90">
      <div class="nav-item" style="padding-right:20px;">
        <div data-placement="bottom" data-toggle="popover" [title]="notifications.title"
             [attr.data-content]="notifications.message">
          <img src="assets/img/notificacion.png" class="padding-5 margin-top-10"><span class="badge badge-success">{{notifications.total}}</span>
        </div>
      </div>
      <a>
        <div class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false">
          <img src="assets/img/profile.jpg" style="height:50px;width:auto;" class="rounded-circle">
          <span style="margin-top:-20px;">{{userData.nombre}}</span>
        </div>
        <span class="header-datos-profile" style="margin-bottom:10px;">
        <p class="bold">{{userData.empresa}}</p>
        <span>{{userData.rol}}
        </span>
      </span>
        <span style="margin-left:53px;">
        <span href="#" data-toggle="modal" data-target="#">
          <img src="assets/img/editar.png" style="height:30px;width: auto;">
        </span>
        <span>
          <a href="javascript:;" (click)="doLogout()">
            <img src="assets/img/eliminar.png" data-toggle="tooltip" title="Cerrar Sesión" data-placement="bottom"
                 style="height:30px;width:auto;">
          </a>
        </span>
      </span>
      </a>
      <div class="nav-item">
        <div class="sandwich padding-5" [ngClass]="{'active': isActive}" id="btn" (click)="btnOpenSandwich()">
          <span class="top"></span>
          <span class="middle"></span>
          <span class="bottom"></span>
        </div>
      </div>
    </div>
    <div class="margin-right-50 margin-left-15">
      <div class="menu" id="menu"  [ngClass]="{'open': isActive}">
        <div class="menu-list">
          <div class="col-xl-12 col-12" style="padding-top:30px;padding-bottom:20px;color:#fff;">
            <h1>Seleccione una opción</h1>
          </div>
          <div class="col-xl-12 col-12 row text-white text-center">
            <ul class="col-xl-4 col-12">
              <li data-toggle="collapse" href="#gestiondeencuestas"><h2>Gestión de Encuestas</h2></li>
              <div class="collapse show" id="gestiondeencuestas">
                <li><a [routerLink]="['/crud-plantilla-encuesta']">Plantillas de Encuestas</a></li>
                <li><a [routerLink]="['/crud-encuesta']">Servicio de Encuestas de Efectividad</a></li>
                <li><a [routerLink]="['/crud-plantilla-correo']">Mantención de Plantillas de Correo</a></li>
              </div>
            </ul>
            <div class="col-xl-12 col-12">
              <button (click)="btnOpenSandwich()" class="btn btn-primary" style="margin-top:5px;">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Modal Cambiar Empresa -->
<ng-template #refModalCambiarEmpresa let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Cambiar de Empresa</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body col-xl-12 col-12">
      <p>Seleccione otra Empresa:</p>
      <div class="form-group col-xl-12 col-12">
        <label>Empresa:</label>
        <select class="form-control">
          <option>Seleccione</option>
          <option>Jumbo</option>
          <option>Almacenes Paris</option>
          <option>Easy</option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="setEmpresa()">Seleccionar</button>
      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
    </div>
  </div>
</ng-template>
