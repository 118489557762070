//angular
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';

//shared
import {GLOBALS} from '../../shared/globals.shared';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';

//models
import {PlantillasEncuestasModel} from "../../models/plantillas-encuestas.model";

@Component({
  selector: 'app-crud-plantilla-encuesta-preview',
  templateUrl: './crud-plantilla-encuesta-preview.component.html',
  styleUrls: ['./crud-plantilla-encuesta-preview.component.css']
})
export class CrudPlantillaEncuestaPreviewComponent implements OnInit, LoggedInCallback {

  pathParams: any;
  modelEncuesta: PlantillasEncuestasModel = new PlantillasEncuestasModel();
  estructuraPreguntas: any[];
  itemsByPage: any[];
  pager: any = {
    pages : [],
    totalPages: '',
    currentPage: 1
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService) {
    console.log('CrudPlantillaEncuestaPreviewComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaEncuestaPreviewComponent: verificacion sesion');
    if (!isLoggedIn) {
      console.log('CrudPlantillaEncuestaPreviewComponent: usuario no autenticado, redireccionando al login.');
      this.router.navigate(['/login']);
    } else {
      this.route.params.subscribe(params => this.pathParams = params);
      if (Number.isNaN(this.pathParams.id)) {
        this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/crud-plantilla-encuesta']);
      }
      this.loadPlantillaEncuesta(this.pathParams.id);
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }


  loadPlantillaEncuesta(id) {
    console.log('CrudPlantillaEncuestaPreviewComponent: obteniendo datos de la planilla de encuesta con id  ' + id);
    this.spinner.show();
    this.plantillaEncuestaService.get(id).subscribe(
      (result: PlantillasEncuestasModel) => {
        console.log('CrudPlantillaEncuestaPreviewComponent: plantilla de encuesta obtenida');
        this.modelEncuesta = result;
        this.estructuraPreguntas = JSON.parse(result.preguntas);
        console.log(this.estructuraPreguntas);
        this.setCountPage();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('No se encontro planilla de encuesta.', GLOBALS.TOASTER.TITLE_INFO);
          this.router.navigate(['/crud-plantilla-encuesta']);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  setCountPage() {
    this.estructuraPreguntas.map(item=> {
      this.pager.pages.push(item.pagina)
    });
    this.pager.totalPages = this.pager.pages.length;
    this.setItemsByPage(this.pager.currentPage);
  }

  setItemsByPage(page: number){
    this.itemsByPage = this.estructuraPreguntas.filter( d => {
      return d.pagina === page;
    })[0].preguntas;
  }

  changePage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager.currentPage = page;
    this.setItemsByPage(this.pager.currentPage);
  }

}
