import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from "@angular/router";

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DateTimeAdapter} from 'ng-pick-datetime';
import * as moment from 'moment';
import {SimpleCrypt} from 'ngx-simple-crypt';
import * as cloneDeep from 'lodash/cloneDeep';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';
import {PlantillaCorreoService} from '../../services/plantilla-correo.service';
import {ComunesService} from '../../services/comunes.service';
import {ExternoService} from '../../services/externo.service';
import {EncuestaService} from '../../services/encuesta.service';

//shared
import {GLOBALS, Utils} from '../../shared/globals.shared';
import {environment} from "../../../environments/environment";

//models
import {PlantillasEncuestasListModel} from '../../models/plantillas-encuestas-list.model';
import {PlantillasCorreosListModel} from '../../models/plantillas-correos-list.model';
import {EmpresaListModel} from '../../models/empresa-list.model';
import {CatalogoContenidoCursoListModel} from '../../models/catalogo-contenido-curso-list.model';
import {EncuestaModel} from '../../models/encuesta.model';
import {ParametroComunModel} from '../../models/parametro-comun.model';
import {PlantillasCorreosModel} from '../../models/plantillas-correos.model';
import {ParticipantesModel} from "../../models/participantes.model";
import {EncuestaListModel} from "../../models/encuesta-list.model";


@Component({
  selector: 'app-crud-encuesta-add',
  templateUrl: './crud-encuesta-add.component.html',
  styleUrls: ['./crud-encuesta-add.component.css']
})
export class CrudEncuestaAddComponent implements OnInit, LoggedInCallback {
  @ViewChild('templateFechaEnvio') modalFechaEnvio;
  modalRef: NgbModalRef;
  dateMin: any = new Date();
  config: any = GLOBALS.CONFIG_EDITOR;
  todos: boolean = true;
  listCmbPlantillasEncuestas: PlantillasEncuestasListModel[];
  listCmbEmpresas: EmpresaListModel[];
  listCmbCursos: CatalogoContenidoCursoListModel[];
  listCmbEtapas: ParametroComunModel[];
  listCmbPlantillasCorreosCierre: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosInvitacion: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosRecordatorio: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosConfirmacion: PlantillasCorreosListModel[];
  modelEncuesta: EncuestaModel = new EncuestaModel();
  modelAddParticipante: ParticipantesModel = new ParticipantesModel();
  url: string;
  modelEmails: any = {
    invitacion: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      asunto: '',
      encabezado: '',
      mensaje: '',
      logo: ''
    },
    recordatorio: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      asunto: '',
      encabezado: '',
      mensaje: '',
      logo: ''
    },
    cierre: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      asunto: '',
      encabezado: '',
      mensaje: '',
      logo: ''
    },
    envioRpta: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      asunto: '',
      encabezado: '',
      mensaje: '',
      logo: ''
    }
  };
  empresaSeleccionada: boolean = false;
  cursoModel: any;
  empresaModel: any;
  etapaCursoModel: any;
  plantillaInvitacionModel: any;
  plantillaRecordatorioModel: any;
  plantillaCierreModel: any;
  plantillaEnvioRptaModel: any;
  flagEmail: boolean = true;
  flagUrl: boolean = true;
  encuestaListRows: EncuestaListModel[];

  recopiladores: any = [{
    label: 'URL'
  }, {
    label: 'Email'
  }];

  dummyEmpresa: any = [{
    razonSocial: 'Comercializadora Costanera'
  }];

  dummyCurso: any = [{
    nombreCurso: 'Excel Avanzado pro',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Básico',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Ofimática',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }];

  dummyParticipantes: any = [{
    contrasena: Utils.randomUuid(),
    recibeEncuesta: true,
    recordar: '',
    identificador: '',
    recopilador: 'url',
    rutParticipante: '22061082-9',
    nombreParticipante: 'José Perez',
    email: 'jose.perez@ozlo.cl'
  }, {
    contrasena: Utils.randomUuid(),
    recibeEncuesta: true,
    recordar: '',
    identificador: '',
    recopilador: 'url',
    rutParticipante: '11832389-0',
    nombreParticipante: 'Mario González',
    email: 'jose.perez@ozlo.cl'
  }, {
    contrasena: Utils.randomUuid(),
    recibeEncuesta: true,
    recordar: '',
    identificador: '',
    recopilador: 'url',
    rutParticipante: '18480169-8',
    nombreParticipante: 'Juan Dominguez',
    email: 'jose.perez@ozlo.cl'
  }, {
    contrasena: Utils.randomUuid(),
    recibeEncuesta: true,
    recordar: '',
    identificador: '',
    recopilador: 'url',
    rutParticipante: '11865632-6',
    nombreParticipante: 'Luis Martino',
    email: 'jose.perez@ozlo.cl'
  }, {
    contrasena: Utils.randomUuid(),
    recibeEncuesta: true,
    recordar: '',
    identificador: '',
    recopilador: 'url',
    rutParticipante: '11865632-6',
    nombreParticipante: 'Rodrigo Aránguiz',
    email: 'ext.raranguiz@oticsofofa.cl'
  }];

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private plantillaCorreoService: PlantillaCorreoService,
              private comunesService: ComunesService,
              private externoService: ExternoService,
              private encuestaService: EncuestaService,
              private modalService: NgbModal,
              private dateTimeAdapter: DateTimeAdapter<any>) {
    this.dateTimeAdapter.setLocale('es-CL');
    console.log('CrudEncuestaAddComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaAddComponent: verificacion sesion');

    if (!isLoggedIn) {
      console.log('CrudEncuestaAddComponent: usuario no autenticado, redireccionando al login.');
      // this.router.navigate(['/login']);
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    } else {
      // this.modelEncuesta.curso = {
      //   etapaCurso: ''
      // };
      //this.initEmpresas();
      // this.asignarParticipantesDummy();
      this.loadEncuestas();
      this.initPlantillasEncuestas();
      this.initParametroComun();
      this.initPlantillasCorreos();
      //this.modelEncuesta.urlEncuesta = Utils.randomUuid();
      this.url = environment.LINK_BASE_URL_RESPONDER;
      this.dateMin.setMinutes(this.dateMin.getMinutes() + 20);
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  asignarParticipantesDummy() {
    this.dummyParticipantes = [{
      contrasena: Utils.randomUuid(),
      recibeEncuesta: true,
      recordar: '',
      identificador: '',
      recopilador: 'url',
      rutParticipante: '22061082-9',
      nombreParticipante: 'José Perez',
      email: 'jose.perez@ozlo.cl'
    }, {
      contrasena: Utils.randomUuid(),
      recibeEncuesta: true,
      recordar: '',
      identificador: '',
      recopilador: 'url',
      rutParticipante: '11832389-0',
      nombreParticipante: 'Mario González',
      email: 'jose.perez@ozlo.cl'
    }, {
      contrasena: Utils.randomUuid(),
      recibeEncuesta: true,
      recordar: '',
      identificador: '',
      recopilador: 'url',
      rutParticipante: '18480169-8',
      nombreParticipante: 'Juan Dominguez',
      email: 'jose.perez@ozlo.cl'
    }, {
      contrasena: Utils.randomUuid(),
      recibeEncuesta: true,
      recordar: '',
      identificador: '',
      recopilador: 'url',
      rutParticipante: '11865632-6',
      nombreParticipante: 'Luis Martino',
      email: 'jose.perez@ozlo.cl'
    }, {
      contrasena: Utils.randomUuid(),
      recibeEncuesta: true,
      recordar: '',
      identificador: '',
      recopilador: 'url',
      rutParticipante: '11865632-6',
      nombreParticipante: 'Rodrigo Aránguiz',
      email: 'ext.raranguiz@oticsofofa.cl'
    }];
  }

  /**
   * Lista las encuestas
   */
  loadEncuestas(): void {
    this.spinner.show();
    console.log('CrudEncuestaComponent: recuperando el listado de encuestas de efectividad.');
    this.encuestaService.list().subscribe(
      (result: EncuestaListModel[]) => {
        this.encuestaListRows = result;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.encuestaListRows = [];
          //this.toastr.info('No se encontraron encuestas de efectividad.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista las plantilas de encuestas.
   */
  initPlantillasEncuestas(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.plantillaEncuestaService.list().subscribe(
      (result: PlantillasEncuestasListModel[]) => {
        this.spinner.hide();
        this.listCmbPlantillasEncuestas = result.filter(function (item) {
          return !item.eliminado && item.nombreEstadoPlantillaEncuesta != 'Borrador'
        });
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista las empresas
   */
  initEmpresas(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.externoService.listEmpresas().subscribe(
      (result: EmpresaListModel[]) => {
        this.spinner.hide();
        this.listCmbEmpresas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista las etapas de los cursos
   */
  initParametroComun(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de etapas de curso.');
    this.spinner.show();
    this.externoService.listParametroComun(14).subscribe(
      (result: ParametroComunModel[]) => {
        this.spinner.hide();
        this.listCmbEtapas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron estapas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista las plantillas de correos disponibles
   */
  initPlantillasCorreos(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de correos.');
    this.spinner.show();
    this.plantillaCorreoService.list().subscribe(
      (result: PlantillasCorreosListModel[]) => {
        this.spinner.hide();
        this.listCmbPlantillasCorreosInvitacion = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 1;
        });
        this.listCmbPlantillasCorreosRecordatorio = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 2;
        });
        this.listCmbPlantillasCorreosCierre = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 3;
        });
        this.listCmbPlantillasCorreosConfirmacion = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 4;
        });
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron estapas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  /**
   * Lista los cursos de un rut de empresa
   */
  loadCursosByRut(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.externoService.listCursos().subscribe(
      (result: CatalogoContenidoCursoListModel[]) => {
        this.spinner.hide();
        console.log(result);
        this.listCmbCursos = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Guarda la encuesta
   */
  saveEncuesta(form): void {
    if (!form.valid) {
      this.toastr.warning('Existen campos incompletos');
      return;
    }
    if (this.validateUrl()) {
      if (this.validateCode()) {
        if (this.validateSelectedParticipantes()) {
          if (this.validateInputIndentificador()) {
            this.modelEncuesta.estadoServicio = true;
            this.modalRef = this.modalService.open(this.modalFechaEnvio);
            // this.spinner.show();
            // this.modelEncuesta.recordatorio = this.modelEncuesta.recordatorio === null ? 0 : this.modelEncuesta.recordatorio;
            // if (this.modelEncuesta.recordatorio > 0) {
            //   this.modelEncuesta.fechaRecordatorio = moment(this.modelEncuesta.fechaEnvio).add(this.modelEncuesta.recordatorio, 'days').unix().toString();
            // }
            // this.modelEncuesta.permiteMasRespuestas = this.modelEncuesta.permiteMasRespuestas ? this.modelEncuesta.permiteMasRespuestas : false;
            // this.modelEncuesta.participantes = JSON.stringify(this.dummyParticipantes);
            // this.modelEncuesta.plantillasCorreos = JSON.stringify(this.modelEmails);

            // this.modelEncuesta.estadoEncuesta = 1;
            // this.modelEncuesta.fechaEnvio = moment(this.modelEncuesta.fechaEnvio).unix().toString();
            // this.modelEncuesta.fechaLimiteRespuesta = this.modelEncuesta.fechaMaxRpta ? moment(this.modelEncuesta.fechaLimiteRespuesta).unix().toString() : null;
            // this.encuestaService.add(this.modelEncuesta).subscribe(
            //   (result) => {
            //     let encodedObjectAsString = SimpleCrypt.encodeDefault(environment.CRYPT_KEY, {
            //       idEncuesta: result.idEncuesta,
            //       modoLogin: this.modelEncuesta.identificadorEncuesta
            //     });
            //     this.modelEncuesta.idEncuesta = result.idEncuesta;
            //     this.modelEncuesta.hashUUID = encodedObjectAsString;
            //     this.modelEncuesta.participantes = null;
            //     this.modelEncuesta.eliminado = false;
            //     this.encuestaService.update(this.modelEncuesta)
            //       .subscribe((result) => {
            //         this.spinner.hide();
            //         console.log("CrudEncuestaAddComponent: Encuesta de satisifacción creada");
            //         this.router.navigate(['/crud-encuesta']);
            //         this.toastr.success('Encuesta de satifacción creada con éxito.')
            //       }, error1 => {
            //         this.spinner.hide();
            //         if (error1.status === 404) {
            //           this.toastr.info(
            //             "Ocurrio un error en el servicio.",
            //             GLOBALS.TOASTER.TITLE_INFO
            //           );
            //         } else {
            //           console.log(<any>error1);
            //           this.toastr.error(error1.error, GLOBALS.TOASTER.TITLE_ERROR);
            //         }
            //       });
            //   },
            //   error => {
            //     this.spinner.hide();
            //     if (error.status === 404) {
            //       this.toastr.info(
            //         "Ocurrio un error en el servicio.",
            //         GLOBALS.TOASTER.TITLE_INFO
            //       );
            //     } else {
            //       console.log(<any>error);
            //       this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            //     }
            //   }
            // );
          }
          else {
            this.toastr.warning('Existen identificadores no completados.');
          }
        } else {
          this.toastr.warning('Debe seleccionar al menos un participante antes de guardar');
        }
      } else {
        this.toastr.warning('El código de encuesta ingresado ya se encuentra registrado.');
      }
    } else {
      this.toastr.warning('La URL ingresada ya se encuestra registrada.');
    }

  }

  /**
   * Guarda la encuesta en estado borrador
   */
  saveDraftEncuesta(form): void {

    if (!form.valid) {
      this.toastr.warning('Existen campos incompletos');
      return;
    }
    if (this.validateUrl()) {
      if (this.validateCode()) {
        if (this.validateSelectedParticipantes()) {
          if (this.validateInputIndentificador()) {
            this.modelEncuesta.estadoServicio = false;
            this.modalRef = this.modalService.open(this.modalFechaEnvio);
          } else {
            this.toastr.warning('Existen identificadores no completados.');
          }
        } else {
          this.toastr.warning('Debe seleccionar al menos un participante antes de guardar');
        }
      } else {
        this.toastr.warning('El código de encuesta ingresado ya se encuentra registrado.');
      }
    } else {
      this.toastr.warning('La URL ingresada ya se encuestra registrada.');
    }

  }

  selectPlantillaEncuesta(event) {
    this.modelEncuesta.preguntasPlantillaEncuesta = event.preguntas;
  }

  /**
   * Obtiene la información de la plantilla de correo por su id
   * @param event
   * @param type
   */
  selectPlantillaCorreo(event, type) {
    if (event) {
      this.spinner.show();
      this.plantillaCorreoService.get(event.idPlantillaCorreo).subscribe
      ((result: PlantillasCorreosModel) => {
          this.getDetailPlantillaCorreo(result, type);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          if (error.status === 404) {
            this.toastr.info(
              "Ocurrio un error en el servicio.",
              GLOBALS.TOASTER.TITLE_INFO
            );
          } else {
            console.log(<any>error);
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        })
    } else {
      this.getDetailPlantillaCorreo(null, type);
    }

  }

  /**
   * Asigna según el tipo la plantilla de correo
   * @param detail
   * @param type
   */
  getDetailPlantillaCorreo(detail: PlantillasCorreosModel, type: string) {
    switch (type) {
      case 'INVITACION':
        this.modelEmails.invitacion.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.invitacion.encabezado = detail && detail.encabezado || null;
        this.modelEmails.invitacion.mensaje = detail && detail.mensaje || null;
        this.modelEmails.invitacion.asunto = detail && detail.asunto || null;
        this.modelEmails.invitacion.tema = detail && detail.tema || null;
        this.modelEmails.invitacion.logo = detail && detail.logo || null;
        break;
      case 'RECORDATORIO':
        this.modelEmails.recordatorio.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.recordatorio.encabezado = detail && detail.encabezado || null;
        this.modelEmails.recordatorio.mensaje = detail && detail.mensaje || null;
        this.modelEmails.recordatorio.asunto = detail && detail.asunto || null;
        this.modelEmails.recordatorio.tema = detail && detail.tema || null;
        this.modelEmails.recordatorio.logo = detail && detail.logo || null;
        break;
      case 'CIERRE':
        this.modelEmails.cierre.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.cierre.encabezado = detail && detail.encabezado || null;
        this.modelEmails.cierre.mensaje = detail && detail.mensaje || null;
        this.modelEmails.cierre.asunto = detail && detail.asunto || null;
        this.modelEmails.cierre.tema = detail && detail.tema || null;
        this.modelEmails.cierre.logo = detail && detail.logo || null;
        break;
      case 'ENVIORPTA':
        this.modelEmails.envioRpta.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.envioRpta.encabezado = detail && detail.encabezado || null;
        this.modelEmails.envioRpta.mensaje = detail && detail.mensaje || null;
        this.modelEmails.envioRpta.asunto = detail && detail.asunto || null;
        this.modelEmails.envioRpta.tema = detail && detail.tema || null;
        this.modelEmails.envioRpta.logo = detail && detail.logo || null;
        break;
    }
  }

  /**
   * Se asigna el valor de compo curso en la vista a el atributo del modelo.
   * @param event
   */
  selectCurso(event): void {
    this.modelEncuesta.curso = event;
  }

  /**
   * Se asigna el valor de campo de la empresa en la vista a el atributo del modelo.
   *
   * Si el campo se limpia el cursos también se limpia
   *
   * @param event
   */
  selectEmpresa(event): void {
    if (event) {
      this.modelEncuesta.empresa = event;
      this.modelEncuesta.curso = null;
      this.empresaSeleccionada = true;
    } else {
      this.cursoModel = null;
      this.modelEncuesta.curso = null;
      this.empresaSeleccionada = false;
    }
  }

  /**
   * Se asigna el valor de campo etapa en la vista a el atributo del modelo.
   * @param event
   */
  selectEtapa(event): void {
    this.modelEncuesta.curso.etapaCurso = event.nombreDetalle
  }

  onPage(event) {
    console.log('paged!', event);
  }

  /**
   * selecciona a todos los participantes
   * @param event
   */
  seleccionarTodos(event) {
    this.dummyParticipantes.map(function (item) {
      item.recibeEncuesta = event.target.checked;
    });
  }

  /**
   * Actualiza los valores de la tabla cuando son cambiados, esto quiere decir que se actualizan los atributos del modelo.
   * @param event
   * @param name
   * @param index
   */
  updateValue(event, name, index) {
    console.log(event.target.value);
    if (event.target.type == 'checkbox') {
      this.dummyParticipantes[index][name] = event.target.checked;
    } else {
      this.dummyParticipantes[index][name] = event.target.value;
    }
  }

  /**
   * Valida si se ha seleccionado al menos 1 participantes que recibe la encuesta.
   */
  validateSelectedParticipantes() {
    let filter = this.dummyParticipantes.filter((item) => {
      return item.recibeEncuesta;
    });
    return filter.length > 0;
  }

  /**
   * Valida los identificadores de los participantes
   */
  validateInputIndentificador() {
    let array = [];
    if (this.modelEncuesta.identificadorEncuesta != 'rut') {
      this.dummyParticipantes.filter((item) => {
        return item.recibeEncuesta;
      }).map((item) => {
        switch (item.recopilador) {
          case 'url':
            if (item.identificador == '')
              array.push(item);
            break;
          case 'email':
            if (item.email == '')
              array.push(item);
            break;
        }
      });
    } else if (this.modelEncuesta.identificadorEncuesta == 'rut') {
      this.dummyParticipantes.filter((item) => {
        return item.recibeEncuesta;
      }).map((item) => {
        switch (item.recopilador) {
          case 'email':
            if (item.email == '')
              array.push(item);
            break;
        }
      });
    }

    return array.length <= 0;
  }


  /**
   * Valida que la fecha limite sea mayor a la de envío
   */
  validateDates() {
    if (this.modelEncuesta.fechaMaxRpta) {
      let fechaEnvio = moment(this.modelEncuesta.fechaEnvio);
      let fechaLimite = moment(this.modelEncuesta.fechaLimiteRespuesta);
      let after = fechaLimite.isAfter(fechaEnvio);
      return after;
    } else {
      return true;
    }
  }

  /**
   * Mostrar modal para agregar participante
   */
  openModalParticipante(refModalAddParticipante): void {
    this.modelAddParticipante = new ParticipantesModel();
    this.modalRef = this.modalService.open(refModalAddParticipante);
  }

  /**
   * Muestra el popuo para confirmar el quitar al participante
   * @param refModalDeleteParticipante
   */
  openModalDeleteParticipante(refModalDeleteParticipante): void {
    this.modalRef = this.modalService.open(refModalDeleteParticipante);
  }

  /**
   * Muestra el popuo para confirmar el quitar al participante
   * @param refModalDeleteParticipante
   */
  openModalFechaEnvio(refModalFechaEnvio): void {
    this.modalRef = this.modalService.open(refModalFechaEnvio);
  }

  /**
   * Agrega un nuevo participantes a la tabla
   */
  agregarParticipante(form) {
    if (!form.valid) {
      return;
    }
    this.modelAddParticipante.contrasena = Utils.randomUuid();
    this.modelAddParticipante.recibeEncuesta = false;
    this.modelAddParticipante.recopilador = 'url';
    var clone = cloneDeep(this.dummyParticipantes);
    clone.push(this.modelAddParticipante);
    this.dummyParticipantes = clone;
    this.modalRef.close();
  }

  /**
   * Quita un participante desde la lista
   * @param row
   * @param index
   */
  quitarParticipante(index) {
    var clone = cloneDeep(this.dummyParticipantes);
    clone.splice(index, 1);
    this.dummyParticipantes = clone;
    this.modalRef.close();
  }

  /**
   * Valida si la fecha de envío es mayor a la actual
   */
  validateDateSend() {
    let fechaEnvio = moment(this.modelEncuesta.fechaEnvio);
    let fechaActual = moment();
    let after = fechaEnvio.isAfter(fechaActual);
    return after;
  }

  /**
   * Valida si el código de la encuesta existe ya creada
   */
  validateCode() {
    if (this.encuestaListRows.length > 0) {
      let filter = this.encuestaListRows.filter((item) => {
        return item.codigoEncuesta !== null ? item.codigoEncuesta.toLowerCase() === this.modelEncuesta.codigoEncuesta.toLowerCase() : false
      });
      return filter.length <= 0;
    } else {
      return true;
    }
  }

  /**
   * Valida si la URL personalizada ya existe
   */
  validateUrl() {
    let filter = this.encuestaListRows.filter((item) => {
      return item.urlEncuesta.toLowerCase() === this.modelEncuesta.urlEncuesta.toLowerCase()
    });
    return filter.length <= 0;
  }

  /**
   * Una vez que es seleccionada la fecha envío, llama al servicio que guarda la encuesta.
   * @param formformFechaEnvio
   */
  guardarFechaEnvio(form) {
    if (!form.valid) {
      this.toastr.warning('Existen campos incompletos');
      return;
    }
    if (this.validateDateSend()) {
      if (this.validateDates()) {
        this.spinner.show();
        this.modelEncuesta.recordatorio = this.modelEncuesta.recordatorio === null ? 0 : this.modelEncuesta.recordatorio;
        if (this.modelEncuesta.recordatorio > 0) {
          this.modelEncuesta.fechaRecordatorio = moment(this.modelEncuesta.fechaEnvio).add(this.modelEncuesta.recordatorio, 'days').unix().toString();
        }
        this.modelEncuesta.permiteMasRespuestas = this.modelEncuesta.permiteMasRespuestas ? this.modelEncuesta.permiteMasRespuestas : false;
        this.modelEncuesta.participantes = JSON.stringify(this.dummyParticipantes);
        this.modelEncuesta.plantillasCorreos = JSON.stringify(this.modelEmails);

        this.modelEncuesta.estadoEncuesta = 1;
        this.modelEncuesta.fechaEnvio = moment(this.modelEncuesta.fechaEnvio).unix().toString();
        this.modelEncuesta.fechaLimiteRespuesta = this.modelEncuesta.fechaMaxRpta ? moment(this.modelEncuesta.fechaLimiteRespuesta).unix().toString() : null;

        this.encuestaService.add(this.modelEncuesta).subscribe(
          (result) => {
            let encodedObjectAsString = SimpleCrypt.encodeDefault(environment.CRYPT_KEY, {
              idEncuesta: result.idEncuesta,
              modoLogin: this.modelEncuesta.identificadorEncuesta
            });
            this.modelEncuesta.idEncuesta = result.idEncuesta;
            this.modelEncuesta.hashUUID = encodedObjectAsString;
            this.modelEncuesta.participantes = null;
            this.modelEncuesta.eliminado = false;
            this.encuestaService.update(this.modelEncuesta)
              .subscribe((result) => {
                this.spinner.hide();
                this.modalRef.close();
                console.log("CrudEncuestaAddComponent: Encuesta de satisifacción creada");
                this.router.navigate(['/crud-encuesta']);
                this.toastr.success('Encuesta de satifacción creada con éxito.')
              }, error1 => {
                this.spinner.hide();
                if (error1.status === 404) {
                  this.toastr.info(
                    "Ocurrio un error en el servicio.",
                    GLOBALS.TOASTER.TITLE_INFO
                  );
                } else {
                  console.log(<any>error1);
                  this.toastr.error(error1.error, GLOBALS.TOASTER.TITLE_ERROR);
                }
              });
          },
          error => {
            this.spinner.hide();
            if (error.status === 404) {
              this.toastr.info(
                "Ocurrio un error en el servicio.",
                GLOBALS.TOASTER.TITLE_INFO
              );
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          }
        );
      } else {
        this.toastr.warning('La fecha de límite de Rpta. debe ser mayor a la fecha de envío');
      }
    }
    else {
      this.toastr.warning('La fecha de envío debe ser mayor a la fecha actual');
    }
  }

  /**
   * Si se des-seleccionado la fecha limite, este limpia los campos de la fecha y el recoratorio.
   * @param event
   */
  checkSelectedDateLimit(event) {
    if (!event.target.checked) {
      this.modelEncuesta.fechaLimiteRespuesta = null;
      this.modelEncuesta.recordatorio = null;
    }
  }


}
