<app-header></app-header>
<section>
  <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
    <div class="container-fluid">
      <div class="row">
        <div class="col margin-top-20">
          <i>Bienvenido</i>
        </div>
        <div class="col text-right margin-top-20">
          Encuestas / Mantención de Plantillas de Correo
        </div>
      </div>
    </div>
    <div class="row-fluid">
      <div class="col-xl-10 col-12">
        <h1 class="margin-top-20">Plantillas de Correo</h1>
      </div>
    </div>
    <div class="col-xl-12 col-12 padding-inside">
      <div class="col-xl-12 col-12 card">
        <div class="col-xl-6 col-12 margin-top-20 mx-auto">
          <div class="row-fluid mx-auto">
            <h2>Filtros:</h2>
          </div>
          <div class="form-row mx-auto">
            <div class="form-group col-xl-8 col-12">
              <label>Nombre Plantilla de Correo:</label>
              <input type="text" class="form-control" placeholder="Ingrese"
                     (keyup)="gridFilter($event, 'NOMBRE_PLANTILLA')" [(ngModel)]="filtersModel.nombrePlantilla">
            </div>
            <div class="form-group col-xl-4 col-12">
              <label>Tipo Plantilla de Correo:</label>
              <ng-select id="tipoplantilla" name="tipoplantilla"
                         [items]="listCmbTipoPlantillaCorreo"
                         bindLabel="nombreTipoPlantillaCorreo"
                         bindValue="nombreTipoPlantillaCorreo"
                         [(ngModel)]="filtersModel.tipoPlantilla"
                         (change)="gridFilter($event, 'TIPO_PLANTILLA')">
              </ng-select>
            </div>
          </div>
          <div class="row-fluid">
            <div class="col-xl-12 col-lg-12 col-12">
              <div class="dropdown">
                <button type="button" class="btn btn-primary dropdown-toggle float-right margin-bottom-30"
                        data-toggle="dropdown">
                  Nueva Plantilla de Correo
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" [routerLink]="['/crud-plantilla-correo-add']">Nueva Plantilla de Correo</a>
                  <a class="dropdown-item" (click)="openModalClonPlantilla(refModalClonPlantilla)">Clonar
                    Plantilla de Correo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-12 margin-top-20 mx-auto">
          <div class="row row-fluid mx-auto">
            <ngx-datatable
              #gridPlantillasCorreosList
              class="bootstrap table table-hover table-striped"
              [rows]="plantillasCorreosListRows"
              [columns]="plantillasCorreosListColumns"
              [rowHeight]="'auto'"
              [sortType]="'multi'"
              [limit]="10"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50">
            </ngx-datatable>
            <ng-template #fechaCreacionHeaderTpl let-column="column" let-sort="sortFn">
                <span class="datatable-header-cell-wrapper">
                  <span class="datatable-header-cell-label draggable" (click)="sort()">Fecha Creación</span>
                </span>
            </ng-template>
            <ng-template #fechaCreacionRowTpl let-row="row" let-value="value">
              {{ value | date: 'dd/MM/yyyy':'+0000' }}
            </ng-template>
            <ng-template #opcionesHeaderTpl let-column="column">Opciones</ng-template>
            <ng-template #opcionesRowTpl let-row="row" let-value="value">
              <a href="javascript:;" [routerLink]="['/crud-plantilla-correo-edit/' + value]"><img
                src="assets/img/editar.png"></a>
              <a href="#" data-toggle="modal" data-target="#modal-eliminar-documento" data-placement="bottom">
                <img src="assets/img/eliminar.png" (click)="openModalDeletePlantilla(refModalDeletePlantilla)"></a>

              <ng-template #refModalDeletePlantilla let-c="close()" let-d="dismiss">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="eliminarInsumo">Eliminar Plantilla de Correo</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body col-xl-12 col-12">
                    <p>¿Está seguro de eliminar esta plantilla de correo?</p>
                    <b>{{row.nombrePlantillaCorreo}}</b>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="deletePlantillaCorreo(row)">Eliminar</button>
                    <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c">Cancelar</button>
                  </div>
                </div>
              </ng-template>
            </ng-template>
            <!-- Modal Eliminar Insumo -->

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>

<ng-template #refModalClonPlantilla let-c="close" let-d="dismiss">
  <div class="modal-content">
    <form #formClonPlantilla="ngForm" (ngSubmit)="clonarPlantilla();" novalidate>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Clonar Plantilla de Correo</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Seleccione la Plantilla de Correo que desea Clonar:</p>
      </div>
      <div class="form-group col-xl-12 col-12">
        <label>Seleccione</label>
        <ng-select class="form-control" id="nombreplantilla" name="nombreplantilla"
                   [items]="listCmbPlantillasCorreos"
                   bindLabel="nombrePlantillaCorreo"
                   bindValue="idPlantillaCorreo"
                   [(ngModel)]="clonModel.idPlantillaCorreo" #nombreplantilla="ngModel" required>
        </ng-select>
        <span
          *ngIf="nombreplantilla.invalid && (nombreplantilla.dirty || nombreplantilla.touched )"
          class="form-error">
                    <span *ngIf="formClonPlantilla.hasError('required', 'nombreplantilla')">Debe seleccionar una Plantilla.</span>
                </span>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!formClonPlantilla.form.valid">Clonar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
      </div>
    </form>
  </div>
</ng-template>
