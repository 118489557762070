import {Deserializable} from "./deserializable.interface";

import {EmpresaModel} from './empresa.model';
import {CursoModel} from './curso.model';

export class EncuestaListModel implements Deserializable<EncuestaListModel> {
  public idEncuesta: number;
  public nombreEncuesta: string;
  public codigoEncuesta: string;
  public tipoEncuesta: string;
  public nombrePlantillaEncuesta: string;
  public fechaEnvio: string;
  public fechaLimiteRespuesta: string;
  public estadoEncuesta: string;
  public estadoServicio: boolean;
  public curso: CursoModel;
  public empresa: EmpresaModel;
  public urlEncuesta: string;
  public eliminado: boolean;
  public totalParticipantes: number;
  public totalParticipantesRespondidos: number;


  constructor(encuesta?: any) {
    this.idEncuesta = encuesta && encuesta.idEncuesta || null;
    this.nombreEncuesta = encuesta && encuesta.nombreEncuesta || null;
    this.codigoEncuesta = encuesta && encuesta.codigoEncuesta || null;
    this.tipoEncuesta = encuesta && encuesta.tipoEncuesta || null;
    this.nombrePlantillaEncuesta = encuesta && encuesta.nombrePlantillaEncuesta || null;
    this.fechaEnvio = encuesta && encuesta.fechaEnvio || null;
    this.fechaLimiteRespuesta = encuesta && encuesta.fechaLimiteRespuesta || null;
    this.estadoEncuesta = encuesta && encuesta.estadoEncuesta || null;
    this.totalParticipantes = encuesta && encuesta.totalParticipantes || 0;
    this.totalParticipantesRespondidos = encuesta && encuesta.totalParticipantesRespondidos || 0;
    if (encuesta) {
      this.estadoServicio = encuesta.estadoServicio;
      this.eliminado = encuesta.eliminado;
    } else {
      this.estadoServicio = null;
      this.eliminado = null;
    }
    this.curso = encuesta && encuesta.curso || null;
    this.empresa = encuesta && encuesta.empresa || null;
    this.urlEncuesta = encuesta && encuesta.urlEncuesta || null;
  }

  deserialize(input: any): EncuestaListModel {
    Object.assign(this, input);
    return this;
  }
}
