//angular
import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormGroup} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import * as cloneDeep from 'lodash/cloneDeep';

//shared
import {GLOBALS} from '../../shared/globals.shared';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {ComunesService} from '../../services/comunes.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';
import {DocumentosService} from "../../services/documentos.service";

//models
import {PlantillasEncuestasModel} from '../../models/plantillas-encuestas.model';
import {TiposPlantillasEncuestasModel} from '../../models/tipos-plantillas-encuestas.model';
import {PlantillasEncuestasListModel} from "../../models/plantillas-encuestas-list.model";

@Component({
  selector: 'app-crud-plantilla-encuesta-clon',
  templateUrl: './crud-plantilla-encuesta-clon.component.html',
  styleUrls: ['./crud-plantilla-encuesta-clon.component.css']
})
export class CrudPlantillaEncuestaClonComponent implements OnInit, LoggedInCallback {
  @ViewChild('modalTextComponent') modalText;
  @ViewChild('modalTextareaComponent') modalTextarea;
  @ViewChild('modalSelectComponent') modalSelect;
  @ViewChild('modalRadioComponent') modalRadio;
  @ViewChild('modalNumberComponent') modalNumber;
  @ViewChild('modalCheckComponent') modalCheckbox;
  @ViewChild('modalStarsComponent') modalStars;
  @ViewChild('modalMatrizComponent') modalMatriz;
  @ViewChild('modalTitleComponent') modalTitle;
  private modalREf: NgbModalRef;
  pathParams: any;
  modeloTexto: any;
  angForm: FormGroup;
  pageIndex: any;
  questionIndex: any;
  isEdit: boolean = false;
  receivedData: Array<any> = [];
  estructuraPreguntas: any = [];
  modelEncuesta: PlantillasEncuestasModel = new PlantillasEncuestasModel();
  listCmbTipoEncuesta: TiposPlantillasEncuestasModel[];
  fileLogo: any = null;
  listPlantillasEncuestas: PlantillasEncuestasListModel[];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private modalService: NgbModal,
              private comunesService: ComunesService,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private documentosService: DocumentosService) {
    console.log('CrudPlantillaEncuestaClonComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaEncuestaClonComponent: verificacion sesion');

    if (!isLoggedIn) {
      console.log('CrudPlantillaEncuestaClonComponent: usuario no autenticado, redireccionando al login.');
      this.router.navigate(['/login']);
    } else {
      this.route.params.subscribe(params => this.pathParams = params);
      console.log(this.pathParams.id);
      if (Number.isNaN(this.pathParams.id)) {
        this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/crud-plantilla-encuesta']);
      }

      this.initListTiposPlantillasEncuestas();
      this.initListPlantillasEncuestas();
      this.loadPlantillaEncuesta(this.pathParams.id);
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  initListTiposPlantillasEncuestas() {
    this.spinner.show();
    this.comunesService.listTipoPlantillasEncuestas().subscribe(
      (result: TiposPlantillasEncuestasModel[]) => {
        this.spinner.hide();
        this.listCmbTipoEncuesta = result;
        this.modelEncuesta.idTipoEncuesta = 1;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info(
          //   "No se encontraron Tipos de Plantillas de Encuestas.",
          //   GLOBALS.TOASTER.TITLE_INFO
          // );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  /**
   * Lista las plantullas de encuestas
   */
  initListPlantillasEncuestas() {
    console.log('CrudPlantillaEncuestaComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.plantillaEncuestaService.list().subscribe(
      (result: PlantillasEncuestasListModel[]) => {
        this.spinner.hide();
        this.listPlantillasEncuestas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.listPlantillasEncuestas = [];
          // this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  loadPlantillaEncuesta(id) {
    console.log('CrudPlantillaEncuestaEditComponent: obteniendo datos de la planilla de encuesta con id  ' + id);
    this.spinner.show();
    this.plantillaEncuestaService.get(id).subscribe(
      (result: PlantillasEncuestasModel) => {
        console.log('CrudPlantillaEncuestaEditComponent: plantilla de encuesta obtenida');
        console.log(result);
        this.modelEncuesta = result;
        this.estructuraPreguntas = JSON.parse(result.preguntas);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();

        if (error.status === 404) {
          this.toastr.info('No se encontro planilla de correo.', GLOBALS.TOASTER.TITLE_INFO);
          this.router.navigate(['/crud-plantilla-encuesta']);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  transferDataSuccess($event: any, index: any) {
    this.modeloTexto = this.getModelByType($event.dragData);
    this.pageIndex = index;
    this.openModelByType($event.dragData);
  }

  openModelByType(type: string) {
    switch (type) {
      case 'text':
        this.modalREf = this.modalService.open(this.modalText);
        break;
      case 'textarea':
        this.modalREf = this.modalService.open(this.modalTextarea);
        break;
      case 'select':
        this.modalREf = this.modalService.open(this.modalSelect);
        break;
      case 'radio':
        this.modalREf = this.modalService.open(this.modalRadio);
        break;
      case 'number':
        this.modalREf = this.modalService.open(this.modalNumber);
        break;
      case 'checkbox':
        this.modalREf = this.modalService.open(this.modalCheckbox);
        break;
      case 'stars':
        this.modalREf = this.modalService.open(this.modalStars);
        break;
      case 'matriz':
        this.modalREf = this.modalService.open(this.modalMatriz);
        break;
      case 'title':
        this.modalREf = this.modalService.open(this.modalTitle);
        break;
    }
  }

  /**
   * Obtiene el modelo correspondiente al tipo de input.
   * @param {string} type
   * @returns {any}
   */
  getModelByType(type: string) {
    let objeto;
    switch (type) {
      case 'text' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          requerido: false,
          tipo: 'text'
        };
        break;
      case 'textarea' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          requerido: false,
          tipo: 'textarea'
        };
        break;
      case 'number' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          requerido: false,
          tipo: 'number'
        };
        break;
      case 'select' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          options: [{label: '', value: ''}, {label: '', value: ''}],
          requerido: false,
          tipo: 'select'
        };
        break;
      case 'radio' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          options: [{label: '', value: ''}, {label: '', value: ''}],
          requerido: false,
          tipo: 'radio'
        };
        break;
      case 'checkbox' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          options: [{label: '', value: ''}, {label: '', value: ''}],
          requerido: false,
          tipo: 'checkbox'
        };
        break;
      case 'stars' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          valor: '',
          requerido: false,
          tipo: 'stars'
        };
        break;
      case 'matriz' :
        objeto = {
          posicion: '',
          numeroPregunta: '',
          nombre: '',
          preguntas: [{titulo: ''}],
          alternativas: [{label: '', value: ''}],
          estructura: [],
          requerido: false,
          tipo: 'matriz',
          tipoCampo: 'radio'
        };
        break;
      case 'title' :
        objeto = {
          posicion: '',
          nombre: '',
          tipo: 'title',
        };
        break;
    }
    return objeto;
  }

  /**
   * Agrega una nueva pregunta
   */
  addItem() {
    if (this.validateValueInput(this.modeloTexto.tipo)) {
      let length = 0, lenghtPostion = 0;
      for (let i = 0; i < this.estructuraPreguntas.length; i++) {
        if (this.pageIndex === i) {
          if (this.estructuraPreguntas[i].preguntas.length > 0) {
            lenghtPostion += this.estructuraPreguntas[i].preguntas.length;
            this.modeloTexto.posicion = lenghtPostion + 1;
            if (this.modeloTexto.tipo != 'title') {
              length += this.estructuraPreguntas[i].preguntas.filter((item) => {
                return item.tipo != 'title'
              }).length;
              this.modeloTexto.numeroPregunta = length + 1;
              if (this.modeloTexto.tipo == 'matriz') {
                this.modeloTexto.preguntas.map((item) => {
                  this.modeloTexto.estructura.push({
                    titulo: item.titulo,
                    alternativas: this.modeloTexto.alternativas
                  })
                });
              }
            }
            this.estructuraPreguntas[this.pageIndex].preguntas.push(this.modeloTexto);
          } else {
            this.modeloTexto.posicion = lenghtPostion + 1;
            if (this.modeloTexto.tipo != 'title') {
              this.modeloTexto.numeroPregunta = length + 1;
              if (this.modeloTexto.tipo == 'matriz') {
                this.modeloTexto.preguntas.map((item) => {
                  this.modeloTexto.estructura.push({
                    titulo: item.titulo,
                    alternativas: this.modeloTexto.alternativas
                  })
                });
              }
            }
            this.estructuraPreguntas[this.pageIndex].preguntas.push(this.modeloTexto);
          }
        } else {
          if (this.pageIndex < i) {
            for (let j = 0; j < this.estructuraPreguntas[i].preguntas.length; j++) {
              this.estructuraPreguntas[i].preguntas[j].posicion = this.estructuraPreguntas[i].preguntas[j].posicion + 1;
              this.estructuraPreguntas[i].preguntas[j].numeroPregunta = this.estructuraPreguntas[i].preguntas[j].numeroPregunta + 1;
            }
          } else {
            lenghtPostion += this.estructuraPreguntas[i].preguntas.length;
            length += this.estructuraPreguntas[i].preguntas.filter((item) => {
              return item.tipo != 'title'
            }).length;
          }
        }
      }
      this.modalREf.close();
    }
    else {
      this.toastr.warning('Campos incompletos');
    }
  }

  /**
   * Sube de posición una pregunta.
   *
   * @param indexPage
   * @param indexQuestion
   */
  upItem(indexPage: number, indexQuestion: number) {
    this.estructuraPreguntas[indexPage].preguntas[indexQuestion - 1].posicion = this.estructuraPreguntas[indexPage].preguntas[indexQuestion - 1].posicion + 1;
    this.estructuraPreguntas[indexPage].preguntas[indexQuestion].posicion = this.estructuraPreguntas[indexPage].preguntas[indexQuestion].posicion - 1;
    if (this.estructuraPreguntas[indexPage].preguntas[indexQuestion].tipo !== 'title' && this.estructuraPreguntas[indexPage].preguntas[indexQuestion - 1].tipo !== 'title') {
      this.estructuraPreguntas[indexPage].preguntas[indexQuestion - 1].numeroPregunta = this.estructuraPreguntas[indexPage].preguntas[indexQuestion - 1].numeroPregunta + 1;
      this.estructuraPreguntas[indexPage].preguntas[indexQuestion].numeroPregunta = this.estructuraPreguntas[indexPage].preguntas[indexQuestion].numeroPregunta - 1;
    }
    const arr = this.estructuraPreguntas[indexPage].preguntas.splice(indexQuestion, 1) [0];
    this.estructuraPreguntas[indexPage].preguntas.splice(indexQuestion - 1, 0, arr);
  }

  /**
   * Baja de posición una pregunta.
   * @param indexPage
   * @param indexQuestion
   */
  downItem(indexPage: number, indexQuestion: number) {
    this.estructuraPreguntas[indexPage].preguntas[indexQuestion + 1].posicion = this.estructuraPreguntas[indexPage].preguntas[indexQuestion + 1].posicion - 1;
    this.estructuraPreguntas[indexPage].preguntas[indexQuestion].posicion = this.estructuraPreguntas[indexPage].preguntas[indexQuestion].posicion + 1;
    if (this.estructuraPreguntas[indexPage].preguntas[indexQuestion].tipo !== 'title' && this.estructuraPreguntas[indexPage].preguntas[indexQuestion + 1].tipo !== 'title') {
      this.estructuraPreguntas[indexPage].preguntas[indexQuestion + 1].numeroPregunta = this.estructuraPreguntas[indexPage].preguntas[indexQuestion + 1].numeroPregunta - 1;
      this.estructuraPreguntas[indexPage].preguntas[indexQuestion].numeroPregunta = this.estructuraPreguntas[indexPage].preguntas[indexQuestion].numeroPregunta + 1;
    }
    const arr = this.estructuraPreguntas[indexPage].preguntas.splice(indexQuestion, 1) [0];
    this.estructuraPreguntas[indexPage].preguntas.splice(indexQuestion + 1, 0, arr);
  }

  /**
   * Elimina una pregunta
   * @param indexPage
   * @param indexQuestion
   */
  deleteItem(indexPage: number, indexQuestion: number) {

    for (let i = 0; i < this.estructuraPreguntas.length; i++) {
      if (indexPage === i) {
        for (let j = 0; j < this.estructuraPreguntas[i].preguntas.length; j++) {
          if (indexQuestion < j) {
            this.estructuraPreguntas[i].preguntas[j].posicion = this.estructuraPreguntas[i].preguntas[j].posicion - 1;
            if (this.estructuraPreguntas[i].preguntas[j].tipo !== 'title' && this.estructuraPreguntas[indexPage].preguntas[indexQuestion].tipo !== 'title') {
              this.estructuraPreguntas[i].preguntas[j].numeroPregunta = this.estructuraPreguntas[i].preguntas[j].numeroPregunta - 1;
            }
          }
        }
      } else {
        for (let j = 0; j < this.estructuraPreguntas[i].preguntas.length; j++) {
          this.estructuraPreguntas[i].preguntas[j].posicion = this.estructuraPreguntas[i].preguntas[j].posicion - 1;
          if (this.estructuraPreguntas[i].preguntas[j].tipo !== 'title' && this.estructuraPreguntas[indexPage].preguntas[indexQuestion].tipo !== 'title') {
            this.estructuraPreguntas[i].preguntas[j].numeroPregunta = this.estructuraPreguntas[i].preguntas[j].numeroPregunta - 1;
          }
        }
      }
    }
    this.estructuraPreguntas[indexPage].preguntas.splice(indexQuestion, 1);
  }

  /**
   * Agrega una nueva página.
   */
  addPage() {
    this.estructuraPreguntas.push({
      pagina: this.estructuraPreguntas.length + 1,
      preguntas: []
    })
  }

  /**
   * Elimina una página.
   * @param index
   */
  deletePage(index: number) {
    const structuraTemp = this.estructuraPreguntas;
    for (let i = 0; i < structuraTemp.length; i++) {
      if (i > index) {
        structuraTemp[i].pagina = structuraTemp[i].pagina - 1;
      }
    }
    structuraTemp.splice(index, 1);
    this.estructuraPreguntas = structuraTemp;
    let posicion = 1, numeroPregunta = 1;
    for (let i = 0; i < this.estructuraPreguntas.length; i++) {
      for (let j = 0; j < this.estructuraPreguntas[i].preguntas.length; j++) {
        this.estructuraPreguntas[i].preguntas[j].posicion = posicion;
        if (this.estructuraPreguntas[i].preguntas[j].tipo !== 'title') {
          this.estructuraPreguntas[i].preguntas[j].numeroPregunta = numeroPregunta;
          numeroPregunta += 1;
        }
        posicion += 1;

      }
    }
  }

  showEditItem(indexPage: number, indexQuestion: number) {
    this.isEdit = true;
    this.pageIndex = indexPage;
    this.questionIndex = indexQuestion;
    this.modeloTexto = cloneDeep(this.estructuraPreguntas[indexPage].preguntas[indexQuestion]);
    this.openModelByType(this.modeloTexto.tipo);
  }

  editItem() {
    if (this.validateValueInput(this.modeloTexto.tipo)) {
      if (this.modeloTexto.tipo == 'matriz') {
        this.modeloTexto.estructura = [];
        this.modeloTexto.preguntas.map((item) => {
          this.modeloTexto.estructura.push({
            titulo: item.titulo,
            alternativas: this.modeloTexto.alternativas
          })
        });
        this.estructuraPreguntas[this.pageIndex].preguntas[this.questionIndex] = this.modeloTexto;

      } else {
        this.estructuraPreguntas[this.pageIndex].preguntas[this.questionIndex] = this.modeloTexto;
      }

      this.isEdit = false;
      this.pageIndex = '';
      this.questionIndex = '';
      this.modalREf.close();
    } else {
      this.toastr.warning('Campos incompletos');
    }
  }

  /**
   * Guarda la plantilla de encuesta.
   */
  savePlantilla(form) {
    if (!form.valid) {
      return;
    }
    if (this.modelEncuesta.instruccionesOnline !== null && this.modelEncuesta.instruccionesOnline !== '') {
      if (this.validateName()) {
        if (this.validateLengthQuestions()) {
          this.spinner.show();
          this.modelEncuesta.eliminado = false;
          this.modelEncuesta.estado = 1;
          this.modelEncuesta.preguntas = JSON.stringify(this.estructuraPreguntas);
          if (this.fileLogo != null) {
            this.documentosService.requestUrlToLoadFile(this.fileLogo)
              .subscribe((result) => {
                this.modelEncuesta.logo = result;
                this.plantillaEncuestaService.add(this.modelEncuesta)
                  .subscribe(
                    (result) => {
                      this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
                      console.log("CrudPlantillaEncuestaAddComponent: Plantilla de encuesta creada");
                      this.router.navigate(['/crud-plantilla-encuesta']);
                    }, error => {
                      this.spinner.hide();
                      if (error.status === 404) {
                        this.toastr.info(
                          "Ocurrio un error en el servicio.",
                          GLOBALS.TOASTER.TITLE_INFO
                        );
                      } else {
                        console.log(<any>error);
                        this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
                      }
                    });
              }, error => {
                console.log(error);
                this.spinner.hide();
                this.toastr.info(
                  "Ocurrio un error en el servicio.",
                  GLOBALS.TOASTER.TITLE_INFO
                );
              });
          }
          else {
            this.plantillaEncuestaService.add(this.modelEncuesta).subscribe(
              (result) => {
                this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
                console.log("CrudPlantillaEncuestaAddComponent: Plantilla de encuesta creada");
                this.router.navigate(['/crud-plantilla-encuesta']);
              }, error => {
                this.spinner.hide();
                if (error.status === 404) {
                  this.toastr.info(
                    "Ocurrio un error en el servicio.",
                    GLOBALS.TOASTER.TITLE_INFO
                  );
                } else {
                  console.log(<any>error);
                  this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
                }
              })
          }
        }
        else {
          this.toastr.info(
            "Debe agregar al menos una pregunta.",
            GLOBALS.TOASTER.TITLE_INFO
          );
        }
      } else {
        this.toastr.info(
          "El título de la plantilla de encuesta ingresado ya se encuentra registrado.",
          GLOBALS.TOASTER.TITLE_INFO
        );
      }
    } else {
      this.toastr.info(
        "Debe ingresar las instrucciones on-line.",
        GLOBALS.TOASTER.TITLE_INFO
      );
    }
  }


  /**
   * Guardar plantilla de encuesta en estado borrador
   */
  saveDraftPlantilla(form) {
    if (!form.valid) {
      return;
    }
    if (this.validateName()) {
      if (this.validateLengthQuestions()) {
        this.modelEncuesta.eliminado = false;
        this.modelEncuesta.estado = 1;
        this.modelEncuesta.preguntas = JSON.stringify(this.estructuraPreguntas);
        if (this.fileLogo != null) {
          this.documentosService.requestUrlToLoadFile(this.fileLogo)
            .subscribe((result) => {
              this.modelEncuesta.logo = result;
              this.plantillaEncuestaService.add(this.modelEncuesta).subscribe(
                (result) => {
                  this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
                  console.log("CrudPlantillaEncuestaAddComponent: Plantilla de encuesta creada");
                  this.router.navigate(['/crud-plantilla-encuesta']);
                }, error => {
                  this.spinner.hide();
                  if (error.status === 404) {
                    this.toastr.info(
                      "Ocurrio un error en el servicio.",
                      GLOBALS.TOASTER.TITLE_INFO
                    );
                  } else {
                    console.log(<any>error);
                    this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
                  }
                })
            }, error1 => {
              this.toastr.info(
                "Ocurrio un error en el servicio.",
                GLOBALS.TOASTER.TITLE_INFO
              );
            });
        } else {
          this.plantillaEncuestaService.add(this.modelEncuesta).subscribe(
            (result) => {
              this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
              console.log("CrudPlantillaEncuestaAddComponent: Plantilla de encuesta creada");
              this.router.navigate(['/crud-plantilla-encuesta']);
            }, error => {
              this.spinner.hide();
              if (error.status === 404) {
                this.toastr.info(
                  "Ocurrio un error en el servicio.",
                  GLOBALS.TOASTER.TITLE_INFO
                );
              } else {
                console.log(<any>error);
                this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
              }
            })
        }
      }
      else {
        this.toastr.info(
          "Debe agregar al menos una pregunta.",
          GLOBALS.TOASTER.TITLE_INFO
        );
      }
    } else {
      this.toastr.info(
        "Debe ingresar al menos el título de la plantilla de encuesta.",
        GLOBALS.TOASTER.TITLE_INFO
      );
    }
  }

  /**
   * Seleccionado el color del tema.
   * @param color
   */
  temaSelected(color): void {
    this.modelEncuesta.tema = color;
  }

  /**
   *
   * Valida si la encuesta tiene agregada preguntas
   * @returns {boolean}
   */
  validateLengthQuestions() {
    if (this.estructuraPreguntas[0].preguntas.length === 0)
      return false;
    else
      return true;
  }

  /**
   * Agrega una nueva opción de respuesta a la pregunta.
   */
  addAlternative() {
    this.modeloTexto.options.push({label: '', value: ''});
  }

  /**
   * Agrega un título de pregunta a la matriz
   */
  addQuestionMatriz() {
    this.modeloTexto.preguntas.push({titulo: ''})
  }

  /**
   * Elimina una pregunta a la matriz
   * @param index Indice de la pregunta
   */
  removeQuestionMatriz(index) {
    this.modeloTexto.preguntas.splice(index, 1);
  }

  /**
   * Agrega una alternativa a la matriz
   */
  addAlternativeMatriz() {
    this.modeloTexto.alternativas.push({label: '', value: ''});
  }

  /**
   * Elimina una alternativa
   *
   * @param index indice de la alternativa
   */
  removeAlternativeMatriz(index) {
    this.modeloTexto.alternativas.splice(index, 1);
  }

  /**
   * Elimina una opción de respuesta a la pregunta.
   * @param indexAlternative
   */
  removeAlternative(indexAlternative) {
    this.modeloTexto.options.splice(indexAlternative, 1);
  }

  /**
   * Actualiza el valor del label dentro del array de objetos
   * @param event
   * @param index
   */
  updateValueAlternative(event, index) {
    this.modeloTexto.options[index].label = event.target.value;
  }


  /**
   * Actualiza el titulo de la pregunta en la matriz.
   * @param event input
   * @param index indice de la pregunta
   */
  updateValueQuestionMatriz(event, index) {
    this.modeloTexto.preguntas[index].titulo = event.target.value;
  }

  /**
   * Actualiza el valor de la alternativa en la matriz
   * @param event Input
   * @param index Indice de la alternativa
   */
  updateValueAlternativeMatriz(event, index) {
    this.modeloTexto.alternativas[index].label = event.target.value;
  }


  /**
   * Valida si se han ingresado el valor de las alternativas
   *
   * @param type
   * @returns isValid
   */
  validateValueInput(type) {
    let isValid = true;
    let counter = 0;
    switch (type) {
      case 'select':
        counter = this.modeloTexto.options.filter(function (item) {
          return item.label.trim() === "";
        }).length;
        break;
      case 'radio':
        counter = this.modeloTexto.options.filter(function (item) {
          return item.label.trim() === "";
        }).length;
        break;
      case'checkbox':
        counter = this.modeloTexto.options.filter(function (item) {
          return item.label.trim() === "";
        }).length;
        break;
      case 'matriz':
        counter = this.modeloTexto.preguntas.filter(function (item) {
          return item.titulo.trim() === "";
        }).length;
        counter === 0 ? counter = this.modeloTexto.alternativas.filter(function (item) {
          return item.label.trim() === "";
        }).length : counter;
        break;
    }
    if (counter > 0)
      isValid = false;
    return isValid;
  }

  /**
   * Asigna el archivo seleccionado a la variable para poder ser subido a S3
   * @param file
   */
  cargarArchivo(file) {
    this.fileLogo = file.target.files[0];
  }

  /**
   * Valida si el nombre que se está ingresando ya existe dentro de las pantillas ya creadas.
   * @param value
   */
  validateName() {
    if (this.listPlantillasEncuestas.length > 0) {
      let filter = this.listPlantillasEncuestas.filter((item) => {
        return item.tituloPlantilla.toLowerCase() === this.modelEncuesta.tituloPlantilla.toLowerCase()
      });
      return filter.length <= 0;
    } else {
      return true;
    }

  }
}
