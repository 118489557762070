import {Deserializable} from "./deserializable.interface";

export class PlantillasCorreosListModel implements Deserializable<PlantillasCorreosListModel>{
  public idPlantillaCorreo: number;
  public nombrePlantillaCorreo: string;
  public idTipoPlantillaCorreo: number;
  public nombreTipoPlantillaCorreo: string;
  public fechaCreacion: string;


  constructor(plantillaCorreoJson: any){
    this.idPlantillaCorreo = plantillaCorreoJson.idPlantillaCorreo;
    this.nombrePlantillaCorreo = plantillaCorreoJson.nombrePlantillaCorreo;
    this.idTipoPlantillaCorreo = plantillaCorreoJson.idTipoPlantillaCorreo;
    this.nombreTipoPlantillaCorreo = plantillaCorreoJson.nombreTipoPlantillaCorreo;
    this.fechaCreacion = plantillaCorreoJson.fechaCreacion;
  }

  deserialize(input:any): PlantillasCorreosListModel {
    Object.assign(this, input);

    return this;
  }
}
