//Angular
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';


//utils
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";

//shared
import {GLOBALS} from "../../shared/globals.shared";

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {ComunesService} from '../../services/comunes.service';
import {PlantillaCorreoService} from '../../services/plantilla-correo.service';
import {DocumentosService} from "../../services/documentos.service";

//models
import {PlantillasCorreosModel} from '../../models/plantillas-correos.model';
import {TiposPlantillasCorreosModel} from '../../models/tipos-plantillas-correos.model';


@Component({
  selector: 'app-crud-plantilla-correo-clon',
  templateUrl: './crud-plantilla-correo-clon.component.html',
  styleUrls: ['./crud-plantilla-correo-clon.component.css']
})
export class CrudPlantillaCorreoClonComponent implements OnInit, LoggedInCallback {

  pathParams: any;
  formModel: PlantillasCorreosModel = new PlantillasCorreosModel();
  listCmbTipoPlantillaCorreo: TiposPlantillasCorreosModel[];
  config: any = GLOBALS.CONFIG_EDITOR;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private comunesService: ComunesService,
    private userLoginService: UserLoginService,
    private plantillaCorreoService: PlantillaCorreoService,
    private documentosService: DocumentosService
  ) {
    console.log('CrudPlantillaCorreoClonComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaCorreoClonComponent: verificacion sesion');

    if (!isLoggedIn) {
      console.log('CrudPlantillaCorreoClonComponent: usuario no autenticado, redireccionando al login.');
      this.router.navigate(['/login']);
    } else {
      this.route.params.subscribe(params => this.pathParams = params);
      console.log(this.pathParams.id);
      if (Number.isNaN(this.pathParams.id)) {
        this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/crud-plantilla-correo']);
      }

      this.loadPlantillaCorreo(this.pathParams.id);
      this.initListTiposPlantillasCorreos();
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  initListTiposPlantillasCorreos() {
    this.spinner.show();
    this.comunesService.listTipoPlantillasCorreos().subscribe(
      (result: TiposPlantillasCorreosModel[]) => {
        this.spinner.hide();
        this.listCmbTipoPlantillaCorreo = result;
        console.log(result);
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info(
          //   "No se encontraron Tipos de Plantillas de Correos.",
          //   GLOBALS.TOASTER.TITLE_INFO
          // );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  loadPlantillaCorreo(id) {
    console.log('CrudPlantillaCorreoEditComponent: obteniendo datos de la planilla de correo con id  ' + id);
    this.spinner.show();
    this.plantillaCorreoService.get(id).subscribe(
      (result: PlantillasCorreosModel) => {
        console.log('CrudPlantillaCorreoEditComponent: plantilla de correo obtenida');
        this.spinner.hide();
        console.log(result);
        this.formModel = result;
        this.formModel.nombrePlantillaCorreo = '';
      },
      error => {
        this.spinner.hide();

        if (error.status === 404) {
          this.toastr.info('No se encontro planilla de correo.', GLOBALS.TOASTER.TITLE_INFO);
          this.router.navigate(['/crud-plantilla-correo']);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  temaSelected(color) {
    this.formModel.tema = color;
  }

  guardarPlantillaCorreo(form): void {
    if (!form.valid) {
      return;
    }
    this.spinner.show();
    this.setTemplatetml();
    this.plantillaCorreoService.add(this.formModel).subscribe(
      (result) => {
        this.spinner.hide();
        this.toastr.success(result, GLOBALS.TOASTER.TITLE_OK);
        console.log("CrudPlantillaCorreoAddComponent: Plantilla de correo creada");
        this.router.navigate(['/crud-plantilla-correo']);
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info(
            "Ocurrio un error en el servicio.",
            GLOBALS.TOASTER.TITLE_INFO
          );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  cargarArchivo(event) {
    this.spinner.show();
    this.documentosService.requestUrlToLoadFile(event.target.files[0])
      .subscribe((result) => {
        console.log(result);
        this.formModel.logo = result;
        this.spinner.hide();
      }, error1 => {
        console.log(error1);
        this.spinner.hide();
      });

  }

  /**
   * Carga el template html a la propiedad del modelo antes de editar
   */
  setTemplatetml() {
    this.formModel.template = `<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
  </head>
  <body style="height:auto;width:550px;font-family:'Arial, sans-serif';">
  <table [ngStyle]="{'background-color':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">
    <body style="background-color: #ffffff !important;">
    <tr>
      <td style="height:10px;width:100%;background-color: ` + this.formModel.tema + `"></td>
    </tr>
    <tr>
      <td style="width:550px;height:50px;padding:10px 5px;text-align:center;background: #ffffff;">
        <span style="font-size:25px;color:blue;">
            <img src="http://encuestas.0s.cl/assets/img/otic.png">
        </span>
      </td>
    </tr>
    <tr>
      <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
        <span style="font-size:25px;color:blue;"> ` + this.formModel.encabezado + `</span>
      </td>
    </tr>
    ` + this.getLogo() + `
    <tr>
      <td style="width:550px;height:auto;padding:10px 5px;">
        ` + this.formModel.mensaje + `
      </td>
    </tr>
    <tr>
      <td
        style="width:550px; height:auto;padding:10px 5px;color:#fff;text-align:center;background-color :` + this.formModel.tema + `">
        OTIC Sofofa 2018, Servicio de Encuestas
      </td>
    </tr>
    </body>
  </table>
  </body>
  </html>`;
  }

  getLogo() {
    if (this.formModel.logo != null) {
      return `<tr>
                <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                    <img src="` + this.formModel.logo + `" style="height:40px;width:auto;text-align:center;">
                </td>
             </tr>`;
    } else {
      return '';
    }
  }
}
