//angular
import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

//shared
import {GLOBALS} from "../../shared/globals.shared";
import {environment} from "../../../environments/environment";

//service
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {EncuestaService} from '../../services/encuesta.service';
import {ComunesService} from '../../services/comunes.service';
import {ExternoService} from '../../services/externo.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';
import {ReportesService} from "../../services/reportes.service";

//models
import {EncuestaListModel} from '../../models/encuesta-list.model';
import {TiposPlantillasEncuestasModel} from "../../models/tipos-plantillas-encuestas.model";
import {ParametroComunModel} from "../../models/parametro-comun.model";
import {EstadosEncuestasModel} from "../../models/estados-encuestas.model";
import {PlantillasEncuestasListModel} from "../../models/plantillas-encuestas-list.model";
import {EncuestaModel} from "../../models/encuesta.model";


@Component({
  selector: 'app-crud-encuesta',
  templateUrl: './crud-encuesta.component.html',
  styleUrls: ['./crud-encuesta.component.css']
})
export class CrudEncuestaComponent implements OnInit, LoggedInCallback {

  @ViewChild(DatatableComponent) table: DatatableComponent;

  encuestaListRows: EncuestaListModel[];
  encuestaListRowsTemp: EncuestaListModel[];
  listCmbTipoEncuesta: TiposPlantillasEncuestasModel[];
  listCmbEtapas: ParametroComunModel[];
  listCmbEstados: EstadosEncuestasModel[];
  listCmbPlantillasEncuestas: PlantillasEncuestasListModel[];
  filtersModel: any = {
    nombreEncuesta: '',
    codigoEncuesta: '',
    tipoEncuesta: '',
    nombreEmpresa: '',
    nombreCurso: '',
    nombreEtapaCurso: '',
    nombreEstado: '',
    tituloPlantilla: ''
  };

  dummyEmpresa: any = [{
    nombreEmpresa: 'Comercializadora Costanera'
  }];

  dummyCurso: any = [{
    nombreCurso: 'Excel Avanzado pro',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Básico',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Ofimática',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }];

  private modalRef: NgbModalRef;


  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private userLoginService: UserLoginService,
              private comunesService: ComunesService,
              private encuestaService: EncuestaService,
              private externoService: ExternoService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private modalService: NgbModal,
              private reportesService: ReportesService) {

    console.log('CrudEncuestaComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaComponent: verificacion sesion');
    if (!isLoggedIn) {
      console.log('CrudEncuestaComponent: usuario no autenticado, redireccionando al login.');
      // this.router.navigate(['/login']);
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    } else {
      this.table.messages.emptyMessage = "No hay registros disponibles.";
      this.loadEncuestas();
      this.initListTiposPlantillasEncuestas();
      this.initParametroComun();
      this.initListEstadosEncuesta();
      this.initPlantillasEncuestas();
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  /**
   * Lista las encuestas
   */
  loadEncuestas(): void {
    this.spinner.show();
    console.log('CrudEncuestaComponent: recuperando el listado de encuestas de efectividad.');
    this.encuestaService.list().subscribe(
      (result: EncuestaListModel[]) => {
        this.encuestaListRows = this.formatData(result);
        this.encuestaListRowsTemp = result;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.encuestaListRows = [];
          //this.toastr.info('No se encontraron encuestas de efectividad.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista los tipos de plantillas de encuestas
   */
  initListTiposPlantillasEncuestas() {
    //this.spinner.show();
    this.comunesService.listTipoPlantillasEncuestas().subscribe(
      (result: TiposPlantillasEncuestasModel[]) => {
        //  this.spinner.hide();
        this.listCmbTipoEncuesta = result;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info("No se encontraron Tipos de Plantillas de Encuestas.", GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  /**
   * lista las etapas de curso
   */
  initParametroComun(): void {
    console.log('CrudEncuestaComponent: recuperando el listado de etapas de curso.');
    //this.spinner.show();
    this.externoService.listParametroComun(14).subscribe(
      (result: ParametroComunModel[]) => {
        //  this.spinner.hide();
        this.listCmbEtapas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron estapas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista los estados de las encuestas
   */
  initListEstadosEncuesta() {
    //this.spinner.show();
    this.comunesService.listEstadosEncuestas().subscribe(
      (result: EstadosEncuestasModel[]) => {
        //  this.spinner.hide();
        this.listCmbEstados = result;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info("No se encontraron Tipos de Plantillas de Encuestas.",GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  /**
   * lista las plantillas de encuestas
   */
  initPlantillasEncuestas(): void {
    console.log('CrudEncuestaComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.plantillaEncuestaService.list().subscribe(
      (result: PlantillasEncuestasListModel[]) => {
        this.spinner.hide();
        this.listCmbPlantillasEncuestas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  onPage(event) {
    console.log('paged!', event);
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  /**
   * Realiza el formato de las fechas y url cuando se lee desde el servicio
   * @param array
   */
  formatData(array: EncuestaListModel[]) {
    array.map(function (item) {
      item.fechaEnvio = moment.unix(parseInt(item.fechaEnvio)).format('DD/MM/YYYY HH:mm').toString();
      item.fechaLimiteRespuesta = item.fechaLimiteRespuesta != null ? moment.unix(parseInt(item.fechaLimiteRespuesta)).format('DD/MM/YYYY HH:mm').toString() : '';
      item.urlEncuesta = environment.LINK_BASE_URL_RESPONDER + item.urlEncuesta;
    });
    return array;
  }

  /**
   * Filtro de busqueda para las encuestas
   * @param event
   * @param {string} tipoOrigen
   */
  gridFilter(event: any, tipoOrigen: string): void {
    switch (tipoOrigen) {
      case 'NOMBRE_ENCUESTA': {
        if (event !== undefined) {
          this.filtersModel.nombreEncuesta = event.target.value.toString().toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombreEncuesta = '';
        }
      }
      case 'CODIGO_ENCUESTA': {
        if (event !== undefined) {
          this.filtersModel.codigoEncuesta = event.target.value.toString().toLowerCase();
          break;
        }
        else {
          this.filtersModel.codigoEncuesta = '';
        }
      }
      case 'TIPO_ENCUESTA': {
        if (event !== undefined) {
          this.filtersModel.tipoEncuesta = event.nombreTipoEncuesta.toLowerCase();
          break;
        }
        else {
          this.filtersModel.tipoEncuesta = '';
        }
      }
      case 'NOMBRE_EMPRESA': {
        if (event !== undefined) {
          this.filtersModel.nombreEmpresa = event.nombreEmpresa.toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombreEmpresa = '';
        }
      }
      case 'NOMBRE_CURSO': {
        if (event !== undefined) {
          this.filtersModel.nombreCurso = event.nombreCurso.toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombreCurso = '';
        }
      }
      case 'ETAPA_CURSO': {
        if (event !== undefined) {
          this.filtersModel.nombreEtapaCurso = event.nombreDetalle.toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombreEtapaCurso = '';
        }
      }
      case 'NOMBRE_ESTADO': {
        if (event !== undefined) {
          this.filtersModel.nombreEstado = event.nombreEstadoEncuesta.toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombreEstado = '';
        }
      }
      case 'TITULO_PLANTILLA': {
        if (event !== undefined) {
          this.filtersModel.tituloPlantilla = event.tituloPlantilla.toLowerCase();
          break;
        }
        else {
          this.filtersModel.tituloPlantilla = '';
        }
      }
    }

    if (this.filtersModel.nombreEncuesta !== null ||
      this.filtersModel.codigoEncuesta !== null ||
      this.filtersModel.tipoEncuesta !== null ||
      this.filtersModel.nombreEmpresa !== null ||
      this.filtersModel.nombreCurso !== null ||
      this.filtersModel.nombreEtapaCurso !== null ||
      this.filtersModel.nombreEstado !== null ||
      this.filtersModel.tituloPlantilla !== null) {


      //filtrado de data
      const temp = this.encuestaListRowsTemp.filter(d => {
        return (d.nombreEncuesta.toLowerCase().indexOf(this.filtersModel.nombreEncuesta) !== -1 &&
          (this.filtersModel.codigoEncuesta !== '' ? (d.codigoEncuesta !== null ? d.codigoEncuesta.toLowerCase().indexOf(this.filtersModel.codigoEncuesta) !== -1 : false) : true) &&
          d.tipoEncuesta.toLowerCase().indexOf(this.filtersModel.tipoEncuesta) !== -1 &&
          d.empresa.razonSocial.toLowerCase().indexOf(this.filtersModel.nombreEmpresa) !== -1 &&
          d.curso.nombreCurso.toLowerCase().indexOf(this.filtersModel.nombreCurso) !== -1 &&
          d.curso.etapaCurso.toLowerCase().indexOf(this.filtersModel.nombreEtapaCurso) !== -1 &&
          d.estadoEncuesta.toLowerCase().indexOf(this.filtersModel.nombreEstado) !== -1 &&
          d.nombrePlantillaEncuesta.toLowerCase().indexOf(this.filtersModel.tituloPlantilla) !== -1);
      });

      this.encuestaListRows = temp;
      //this.gridCursosList.offset = 0;

      if (this.encuestaListRows.length == 0) {
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    } else {
      this.encuestaListRows = this.encuestaListRowsTemp;
    }
  }


  /**
   * Levanta el popup dependiendo del id
   * @param modalId
   */
  openModal(modalId): void {
    this.modalRef = this.modalService.open(modalId);
  }

  /**
   * Elimina la encuesta logicamente
   * @param item
   */
  deletePlantillaEncuesta(item) {
    console.log(item);
    this.spinner.show();
    this.encuestaService.get(item.idEncuesta)
      .subscribe((result: EncuestaModel) => {
        result.eliminado = !result.eliminado;
        this.encuestaService.update(result)
          .subscribe((r) => {
            this.loadEncuestas();
            this.modalRef.close();
            this.spinner.hide();
            if (result.eliminado)
              this.toastr.success('Encuesta desactivada con éxito');
            else
              this.toastr.success('Encuesta activada con éxito');
          }, error => {
            if (error.status === 404) {
              this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          })
      }, error => {
        if (error.status === 404) {
          this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  /**
   * Cambia la encuesta de estado borrador a activa
   * @param item
   */
  activarPlantillaEncuesta(item) {
    this.spinner.show();
    this.encuestaService.get(item.idEncuesta)
      .subscribe((result: EncuestaModel) => {
        result.estadoServicio = !result.estadoServicio;
        this.encuestaService.update(result)
          .subscribe((r) => {
            this.loadEncuestas();
            this.modalRef.close();
            this.spinner.hide();
            this.toastr.success('Se ha activado exitosamente la encuesta de efectividad.');
          }, error => {
            this.spinner.hide();
            if (error.status === 404) {
              this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          })
      }, error => {
        if (error.status === 404) {
          this.spinner.hide();
          this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }


  /**
   * Exportar en excel el reporte completo para una encuesta
   * @param idEncuesta
   */
  exportarReporteGeneral(idEncuesta) {
    this.spinner.show();
    this.reportesService.exportarReporteGeneral(idEncuesta)
      .subscribe((result) => {
        const link = document.createElement('a');
        // Add the element to the DOM
        link.setAttribute("type", "hidden"); // make it hidden if needed
        link.download = result.metadata.filename;
        link.href = 'data:' + result.metadata.mimeType + ';base64,' + result.b64;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  /**
   * Exporta el reporte PPT
   * @param idEncuesta
   */
  exportarReportePpt(idEncuesta) {
    this.spinner.show();
    this.reportesService.exportarReportePpt(idEncuesta)
      .subscribe((result) => {
        var link = document.createElement('a');
        link.href = 'data:' + result.metadata.mimeType + ';base64,' + result.b64;
        link.download = result.metadata.filename;
        link.click();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

}
