import {Deserializable} from "./deserializable.interface";

export class TiposPlantillasCorreosModel implements Deserializable<TiposPlantillasCorreosModel> {
  public idTipoPlantillaCorreo: number;
  public nombreTipoPlantillaCorreo: string;
  public eliminado: boolean;

  constructor(objJson?: any) {
    this.idTipoPlantillaCorreo = objJson.idTipoPlantillaCorreo;
    this.nombreTipoPlantillaCorreo = objJson.nombreTipoPlantillaCorreo;
    this.eliminado = objJson.eliminado;
  }

  deserialize(input: any): TiposPlantillasCorreosModel {
    Object.assign(this, input);

    return this;
  }
}
