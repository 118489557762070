import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';

import {PlantillasCorreosListModel} from '../models/plantillas-correos-list.model';
import {PlantillasCorreosModel} from '../models/plantillas-correos.model';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlantillaCorreoService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<PlantillasCorreosListModel[]> {
    return this.http
      .get<PlantillasCorreosListModel[]>(`${environment.API_DOMAINS.PLANTILLAS_CORREOS}/plantillaCorreo`)
      .pipe(
        map((result: any) => {
          return result.map((plantilla: any) => {
            return new PlantillasCorreosListModel(plantilla);
          });
        })
      );
  }

  add(plantillaCorreo: PlantillasCorreosModel): Observable<any> {
    const body = JSON.stringify(plantillaCorreo);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.PLANTILLAS_CORREOS}/plantillaCorreo`, body, {headers: headers});
  }

  get(id): Observable<PlantillasCorreosModel> {
    return this.http
      .get<PlantillasCorreosModel>(`${environment.API_DOMAINS.PLANTILLAS_CORREOS}/plantillaCorreo/${id}`)
      .pipe(
        map((result: any) => {
          console.log(result);
          return new PlantillasCorreosModel(result)
        })
      );
  }

  update(plantillaCorreo: PlantillasCorreosModel): Observable<any> {
    const body = JSON.stringify(plantillaCorreo);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.PLANTILLAS_CORREOS}/plantillaCorreo`, body, {headers: headers});
  }
}
