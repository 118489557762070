import {Deserializable} from './deserializable.interface';

export class EmpresaListModel implements Deserializable<EmpresaListModel>{
  public rut: number;
  public dv: string;
  public razonSocial: string;
  public nombreFantasia: string;
  public casaMatriz: string;
  public telefono: string;
  public giro: string;

  constructor(objJson?: any) {
    this.rut = objJson && objJson.rut || null;
    this.dv = objJson && objJson.dv || null;
    this.razonSocial = objJson && objJson.razonSocial || null;
    this.nombreFantasia = objJson && objJson.nombreFantasia || null;
    this.casaMatriz = objJson && objJson.casaMatriz || null;
    this.telefono = objJson && objJson.telefono || null;
    this.giro = objJson && objJson.giro || null;
  }

  deserialize(input: any): EmpresaListModel {
    Object.assign(this, input);
    return this;
  }
}
