//angular
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

//shared
import {GLOBALS} from '../../shared/globals.shared';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';
import {ComunesService} from '../../services/comunes.service';


//models
import {PlantillasEncuestasListModel} from '../../models/plantillas-encuestas-list.model';
import {TiposPlantillasEncuestasModel} from "../../models/tipos-plantillas-encuestas.model";
import {EstadosPlantillasEncuestasModel} from "../../models/estados-plantillas-encuestas.model";
import {PlantillasEncuestasModel} from "../../models/plantillas-encuestas.model";


@Component({
  selector: 'app-crud-plantilla-encuesta',
  templateUrl: './crud-plantilla-encuesta.component.html',
  styleUrls: ['./crud-plantilla-encuesta.component.css']
})
export class CrudPlantillaEncuestaComponent implements OnInit, LoggedInCallback {
  @ViewChild('vistaPreviaHeaderTpl') vistaPreviaHdrTpl: TemplateRef<any>;
  @ViewChild('vistaPreviaRowTpl') vistaPreviaRowTpl: TemplateRef<any>;
  @ViewChild('opcionesHeaderTpl') opcionesHdrTpl: TemplateRef<any>;
  @ViewChild('opcionesRowTpl') opcionesRowTpl: TemplateRef<any>;

  @ViewChild(DatatableComponent) gridPlantillasEncuestaList: DatatableComponent;

  private modalRef: NgbModalRef;
  plantillasEncuestasListRows: PlantillasEncuestasListModel[];
  plantillasEncuestasListRowsTemp: PlantillasEncuestasListModel[];
  plantillasEncuestasListColumns: any[];
  listCmbTipoEncuesta: TiposPlantillasEncuestasModel[];
  listCmbEstadoPlantillaEncuesta: EstadosPlantillasEncuestasModel[];
  listCmbPlantillasEncuestas: PlantillasEncuestasListModel[];

  filtersModel: any = {
    nombrePlantilla: '',
    tipoEncuesta: '',
    tipoEstado: ''
  };
  clonModel: any = {
    idPlantillaEncuesta: ''
  };

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private comunesService: ComunesService,
              private modalService: NgbModal) {
    console.log('CrudPlantillaEncuestaComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaEncuestaComponent: verificacion sesion');

    if (!isLoggedIn) {
      console.log('CrudPlantillaEncuestaComponent: usuario no autenticado, redireccionando al login.');
      this.router.navigate(['/login']);
    } else {
      this.gridPlantillasEncuestaList.messages.emptyMessage = "No hay registros disponibles.";
      this.initGridColumns();
      this.initListEstadosPlantillasEncuestas();
      this.initListTiposPlantillasEncuestas();
      this.loadPlantillasEncuestas();
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  initListTiposPlantillasEncuestas() {
    this.spinner.show();
    this.comunesService.listTipoPlantillasEncuestas().subscribe(
      (result: TiposPlantillasEncuestasModel[]) => {
        this.spinner.hide();
        this.listCmbTipoEncuesta = result;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info(
          //   "No se encontraron Tipos de Plantillas de Encuestas.",
          //   GLOBALS.TOASTER.TITLE_INFO
          // );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  initListEstadosPlantillasEncuestas() {
    this.spinner.show();
    this.comunesService.listEstadosPlantillasEncuestas().subscribe(
      (result: EstadosPlantillasEncuestasModel[]) => {
        this.spinner.hide();
        this.listCmbEstadoPlantillaEncuesta = result;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info(
          //   "No se encontraron Estados de Plantillas de Encuestas.",
          //   GLOBALS.TOASTER.TITLE_INFO
          // );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  initGridColumns(): void {
    this.plantillasEncuestasListColumns = [
      {name: 'Nombre Plantilla Encuesta', prop: 'tituloPlantilla'},
      {name: 'Tipo Plantilla Encuesta', prop: 'nombreTipoPlantilla'},
      {name: 'Estado Plantilla Encuesta', prop: 'nombreEstadoPlantillaEncuesta'},
      {
        cellTemplate: this.vistaPreviaRowTpl,
        headerTemplate: this.vistaPreviaHdrTpl,
        name: 'Vista Previa',
        prop: 'idPlantillaEncuesta'
      },
      {
        cellTemplate: this.opcionesRowTpl,
        headerTemplate: this.opcionesHdrTpl,
        name: 'Opciones',
        prop: 'idPlantillaEncuesta'
      }
    ];
  }

  loadPlantillasEncuestas(): void {
    console.log('CrudPlantillaEncuestaComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.plantillaEncuestaService.list().subscribe(
      (result: PlantillasEncuestasListModel[]) => {
        this.plantillasEncuestasListRows = result;
        this.plantillasEncuestasListRowsTemp = result;
        this.listCmbPlantillasEncuestas = result;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  gridFilter(event: any, tipoOrigen: string): void {
    switch (tipoOrigen) {
      case 'NOMBRE_PLANTILLA': {
        if (event !== undefined) {
          this.filtersModel.nombrePlantilla = event.target.value.toString().toLowerCase();
          break;
        }
        else {
          this.filtersModel.nombrePlantilla = '';
        }
      }
      case 'TIPO_ESTADO': {
        if (event !== undefined) {
          this.filtersModel.tipoEstado = event.nombreEstadoPlantillaEncuesta.toLowerCase();
          break;
        }
        else {
          this.filtersModel.tipoEstado = '';
        }
      }
      case 'TIPO_ENCUESTA': {
        if (event !== undefined) {
          this.filtersModel.tipoEncuesta = event.nombreTipoEncuesta.toLowerCase();
          break;
        }
        else {
          this.filtersModel.tipoEncuesta = '';
        }
      }
    }

    if (this.filtersModel.nombrePlantilla !== '' ||
      this.filtersModel.tipoEstado !== '' ||
      this.filtersModel.tipoPlantilla !== '') {

      //filtrado de data
      const temp = this.plantillasEncuestasListRowsTemp.filter(d => {
        return (d.tituloPlantilla.toLowerCase().indexOf(this.filtersModel.nombrePlantilla) !== -1
          && (this.filtersModel.tipoEstado ? d.nombreEstadoPlantillaEncuesta.toLowerCase() === this.filtersModel.tipoEstado : true)
          && d.nombreTipoPlantilla.toLowerCase().indexOf(this.filtersModel.tipoEncuesta) !== -1);
      });

      this.plantillasEncuestasListRows = temp;
      //this.gridCursosList.offset = 0;

      if (this.plantillasEncuestasListRows.length == 0) {
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    } else {
      this.plantillasEncuestasListRows = this.plantillasEncuestasListRowsTemp;
    }
  }

  openModalDeletePlantilla(refModalDeletePlantilla): void {
    this.modalRef = this.modalService.open(refModalDeletePlantilla);
  }

  openModalClonPlantilla(refModalClonPlantilla): void {
    this.modalRef = this.modalService.open(refModalClonPlantilla);
  }

  clonarPlantilla(): void {
    this.modalRef.close();
    this.router.navigate(['/crud-plantilla-encuesta-clon/' + this.clonModel.idPlantillaEncuesta]);
  }

  // deletePlantillaEncuesta
  deletePlantillaEncuesta(row: any): void {
    this.spinner.show();
    this.plantillaEncuestaService.get(row.idPlantillaEncuesta).subscribe(
      (result: PlantillasEncuestasModel) => {
        result.eliminado = !result.eliminado;
        this.plantillaEncuestaService.update(result).subscribe(
          (resultUpdate) => {
            this.modalRef.close();
            this.spinner.hide();
            if (result.eliminado)
              this.toastr.success('Plantilla desactivada con éxito.', GLOBALS.TOASTER.TITLE_OK);
            else
              this.toastr.success('Plantilla activada con éxito.', GLOBALS.TOASTER.TITLE_OK);
            console.log('CrudPlantillaEncuestaComponent: Plantilla de correo eliminada');
            this.initGridColumns();
            this.loadPlantillasEncuestas();
          }, error => {
            if (error.status === 404) {
              this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          })
      }, error => {
        this.spinner.hide();

        if (error.status === 404) {
          this.toastr.info('No se encontraro la plantilla de encuesta.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }
}
