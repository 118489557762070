import { Injectable } from '@angular/core';
import {Callback, CognitoUtilService} from "./cognito.service";

@Injectable({
  providedIn: 'root'
})
export class UserParameterService {

  constructor(private cognitoUtil: CognitoUtilService) {}

  getParameters(callback: Callback) {
    let cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        console.log(session);
        if (err){
          console.log("UserParametersService: No se pudo recuperar el usuario");
        } else {
          cognitoUser.getUserAttributes((err, result) => {
            if (err) {
              console.log("UserParametersService: error en getParameters: " + JSON.stringify(err));
            } else {
              callback.callbackWithParam(result);
            }
          });
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }
}
