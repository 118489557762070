import {Deserializable} from "./deserializable.interface";

export class EncuestaRespuestaModel implements Deserializable<EncuestaRespuestaModel> {
  public nombreEncuesta: string;
  public curso: string;
  public fechaEnvio: string;
  public fechaLimiteRespuesta: string;
  public instruccionesOnline: string;
  public preguntas: string;
  public tema: string;
  public identificadorEncuesta: string;
  public textoDespedida: string;
  public permiteMasRespuestas: boolean;
  public estadoServicio: boolean;
  public logo: string;
  public estadoEncuesta: number;
  public eliminado: boolean;

  constructor(encuesta?: any) {
    this.identificadorEncuesta = encuesta && encuesta.identificadorEncuesta || null;
    this.tema = encuesta && encuesta.tema || null;
    this.nombreEncuesta = encuesta && encuesta.nombreEncuesta || null;
    this.curso = encuesta && encuesta.curso || null;
    this.fechaEnvio = encuesta && encuesta.fechaEnvio || null;
    this.fechaLimiteRespuesta = encuesta && encuesta.fechaLimiteRespuesta || null;
    this.instruccionesOnline = encuesta && encuesta.instruccionesOnline || null;
    this.preguntas = encuesta && encuesta.preguntas || null;
    this.textoDespedida = encuesta && encuesta.textoDespedida || null;
    this.logo = encuesta && encuesta.logo || null;
    this.estadoEncuesta = encuesta && encuesta.estadoEncuesta || null;
    if (encuesta) {
      this.permiteMasRespuestas = encuesta.permiteMasRespuestas;
      this.estadoServicio = encuesta.estadoServicio;
      this.eliminado = encuesta.eliminado;
    }
    else {
      this.permiteMasRespuestas = null;
      this.estadoServicio = null;
      this.eliminado = null;
    }

  }

  deserialize(input: any): EncuestaRespuestaModel {
    Object.assign(this, input);
    return this;
  }
}
