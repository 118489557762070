import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private http: HttpClient) { }

  desencryptUUID(uuid): Observable<any> {
    return this.http
      .get<any>(`${environment.API_DOMAINS.UTIL}/desencriptar/${uuid}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  obtenerFechaSistema(): Observable<any> {
    return this.http
      .get<any>(`${environment.API_DOMAINS.UTIL}/obtenerFechaActualSistema`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  sendEmailFinalizar(idEncuesta, idParticipante): Observable<any> {
    return this.http
      .get<any>(`${environment.API_DOMAINS.EMAIL}/finalizarEncuesta/${idEncuesta}/${idParticipante}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
