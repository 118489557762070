import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms'
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {routing, appRoutingProvider} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {AngularWebStorageModule} from 'angular-web-storage';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import {ColorPickerModule} from 'ngx-color-picker';
import {DndModule} from 'ng2-dnd';
import {BarRatingModule} from 'ngx-bar-rating';
import {ClipboardModule} from 'ngx-clipboard';
import {NgxEditorModule} from 'ngx-editor';
import {OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl} from 'ng-pick-datetime';
import {ChartModule} from '@kiwigrid/ngx-highcharts';
import {HighchartsStatic} from '@kiwigrid/ngx-highcharts/dist/HighchartsService';

import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {LoginComponent} from './components/login/login.component';
import {RutValidator} from './directives/rutvalidator.directive';
import {CrudPlantillaCorreoComponent} from './components/crud-plantilla-correo/crud-plantilla-correo.component';
import {CrudPlantillaCorreoAddComponent} from './components/crud-plantilla-correo-add/crud-plantilla-correo-add.component';
import {CrudPlantillaCorreoEditComponent} from './components/crud-plantilla-correo-edit/crud-plantilla-correo-edit.component';
import {CrudPlantillaCorreoClonComponent} from './components/crud-plantilla-correo-clon/crud-plantilla-correo-clon.component';
import {CrudPlantillaEncuestaComponent} from './components/crud-plantilla-encuesta/crud-plantilla-encuesta.component';
import {CrudPlantillaEncuestaAddComponent} from './components/crud-plantilla-encuesta-add/crud-plantilla-encuesta-add.component';
import {CrudPlantillaEncuestaClonComponent} from './components/crud-plantilla-encuesta-clon/crud-plantilla-encuesta-clon.component';
import {CrudPlantillaEncuestaEditComponent} from './components/crud-plantilla-encuesta-edit/crud-plantilla-encuesta-edit.component';
import {CrudPlantillaEncuestaPreviewComponent} from './components/crud-plantilla-encuesta-preview/crud-plantilla-encuesta-preview.component';
import {CrudEncuestaComponent} from './components/crud-encuesta/crud-encuesta.component';
import {CrudEncuestaAddComponent} from './components/crud-encuesta-add/crud-encuesta-add.component';
import {CrudEncuestaEditComponent} from './components/crud-encuesta-edit/crud-encuesta-edit.component';

import {DefaultIntl} from './shared/globals.shared';
import {CrudEncuestaPreviewComponent} from './components/crud-encuesta-preview/crud-encuesta-preview.component';
import {EncuestaComponent} from './components/encuesta/encuesta.component';
import {RespuestasEncuestaComponent} from './components/respuestas-encuesta/respuestas-encuesta.component';
import {SafePipe} from './pipes/safe.pipe';
import {OnlyNumberDirective} from './directives/onlynumber.directive';

import {AuthInterceptor} from "./interceptors/auth.interceptor";
import { AuthComponent } from './components/auth/auth.component';

declare var require: any;

export function highchartsFactory() {
  var hc = require('highcharts');
  var hcm = require('highcharts/highcharts-more');
  hcm(hc);
  return hc;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RutValidator,
    CrudPlantillaCorreoComponent,
    CrudPlantillaCorreoAddComponent,
    CrudPlantillaCorreoEditComponent,
    CrudPlantillaCorreoClonComponent,
    CrudPlantillaEncuestaComponent,
    CrudPlantillaEncuestaAddComponent,
    CrudPlantillaEncuestaClonComponent,
    CrudPlantillaEncuestaEditComponent,
    CrudPlantillaEncuestaPreviewComponent,
    CrudEncuestaComponent,
    CrudEncuestaAddComponent,
    CrudEncuestaEditComponent,
    CrudEncuestaPreviewComponent,
    EncuestaComponent,
    RespuestasEncuestaComponent,
    SafePipe,
    OnlyNumberDirective,
    AuthComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    routing,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    NgxDatatableModule,
    NgSelectModule,
    NgxMyDatePickerModule.forRoot(),
    AngularWebStorageModule,
    ColorPickerModule,
    DndModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BarRatingModule,
    ClipboardModule,
    NgxEditorModule,
    ChartModule

  ],
  providers: [appRoutingProvider, {provide: OwlDateTimeIntl, useClass: DefaultIntl},
    {
      provide: HighchartsStatic,
      useFactory: highchartsFactory
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor, multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
