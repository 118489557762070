import {Deserializable} from "./deserializable.interface";

export class EncuestaPreviewModel implements Deserializable<EncuestaPreviewModel> {
  public nombreEncuesta: string;
  public curso: string;
  public fechaEnvio: string;
  public fechaLimiteRespuesta: string;
  public instruccionesOnline: string;
  public preguntas: string;
  public tema: string;
  public identificadorEncuesta: string;


  constructor(encuesta?: any) {
    this.identificadorEncuesta = encuesta && encuesta.identificadorEncuesta || null;
    this.tema = encuesta && encuesta.tema || null;
    this.nombreEncuesta = encuesta && encuesta.nombreEncuesta || null;
    this.curso = encuesta && encuesta.curso || null;
    this.fechaEnvio = encuesta && encuesta.fechaEnvio || null;
    this.fechaLimiteRespuesta = encuesta && encuesta.fechaLimiteRespuesta || null;
    this.instruccionesOnline = encuesta && encuesta.instruccionesOnline || null;
    this.preguntas = encuesta && encuesta.preguntas || null;

  }

  deserialize(input: any): EncuestaPreviewModel {
    Object.assign(this, input);
    return this;
  }
}

