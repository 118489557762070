import {Deserializable} from "./deserializable.interface";

export class TiposPlantillasEncuestasModel implements Deserializable<TiposPlantillasEncuestasModel> {
  public idTipoEncuesta: number;
  public nombreTipoEncuesta: string;
  public eliminado: boolean;

  constructor(objJson?: any) {
    this.idTipoEncuesta = objJson.idTipoEncuesta;
    this.nombreTipoEncuesta = objJson.nombreTipoEncuesta;
    this.eliminado = objJson.eliminado;
  }

  deserialize(input: any): TiposPlantillasEncuestasModel {
    Object.assign(this, input);
    return this;
  }
}
