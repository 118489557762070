import {Deserializable} from "./deserializable.interface";

export class EncuestaResultadoPreguntasModel implements Deserializable<EncuestaResultadoPreguntasModel> {
  public numeroPregunta: number;
  public tituloPregunta: string;
  public tipoPregunta: string;
  public alternativas: EncuestaResultadoPreguntasModel[];
  public cantidadParticipantes: number;
  public options: object;
  public timestamp: number;

  constructor(encuesta?: any) {
    this.numeroPregunta = encuesta && encuesta.numeroPregunta || null;
    this.tituloPregunta = encuesta && encuesta.tituloPregunta || null;
    this.tipoPregunta = encuesta && encuesta.tipoPregunta || null;
    this.alternativas = encuesta && encuesta.alternativas || null;
    this.cantidadParticipantes = encuesta && encuesta.cantidadParticipantes || 0;
    this.options = encuesta && encuesta.options || null;
    this.timestamp = encuesta && encuesta.timestamp || null;
  }

  deserialize(input: any): EncuestaResultadoPreguntasModel {
    Object.assign(this, input);
    return this;
  }

}


