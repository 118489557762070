<app-header></app-header>
<div class="container-fluid margin-bottom-30"
     style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
  <section>
    <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
      <div class="container-fluid">
        <div class="row">
          <div class="col margin-top-20">
            <i>Bienvenido</i>
          </div>
          <div class="col text-right margin-top-20">
            Encuestas / Creación Plantillas de Encuestas
          </div>
        </div>
      </div>
      <div class="row-fluid">
        <div class="col-xl-12 col-12">
          <h1 class="margin-top-20">Plantillas de Encuestas</h1>
        </div>
      </div>
      <div class="col-xl-12 col-12 padding-inside">
        <div class="col-xl-12 col-12 card">
          <div class="col-xl-6 mx-auto col-12 margin-top-20">
            <div class="row">
              <h2>Filtros</h2>
            </div>
            <div class="row mx-auto">
              <div class="form-group col-xl-12 col-12">
                <label>
                  Nombre Plantilla de Encuesta:
                </label>
                <input type="text" class="form-control" placeholder="Ingrese"
                       (keyup)="gridFilter($event, 'NOMBRE_PLANTILLA')" [(ngModel)]="filtersModel.nombrePlantilla">
                <img src="assets/img/buscar.png" alt="Buscar" style="margin:-30px 10px 0px 0px;" class="float-right">
              </div>
            </div>
            <div class="row mx-auto">
              <div class="form-group col-xl-6 col-12">
                <label>
                  Tipo Plantilla de Encuestas:
                </label>
                <ng-select id="tipoEncuesta" name="tipoEncuesta"
                           [items]="listCmbTipoEncuesta"
                           bindLabel="nombreTipoEncuesta"
                           bindValue="nombreTipoEncuesta"
                           [(ngModel)]="filtersModel.tipoEncuesta"
                           (change)="gridFilter($event, 'TIPO_ENCUESTA')">
                </ng-select>
              </div>
              <div class="form-group col-xl-6 col-12">
                <label>
                  Estado Plantilla de Encuesta:
                </label>
                <ng-select id="estadoEncuesta" name="estadoEncuesta"
                           [items]="listCmbEstadoPlantillaEncuesta"
                           bindLabel="nombreEstadoPlantillaEncuesta"
                           bindValue="nombreEstadoPlantillaEncuesta"
                           [(ngModel)]="filtersModel.tipoEstado"
                           (change)="gridFilter($event, 'TIPO_ESTADO')">
                </ng-select>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-xl-12 col-12">
              <div class="dropdown">
                <button type="button" class="btn btn-primary dropdown-toggle float-right margin-right-30"
                        data-toggle="dropdown">
                  Agregar Nueva Plantilla de Encuesta
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" [routerLink]="['/crud-plantilla-encuesta-add']">Crear una <b>Nueva</b></a>
                  <a class="dropdown-item" data-toggle="modal" (click)="openModalClonPlantilla(refModalClonPlantilla)">Crear
                    <b>a partir de otra</b></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-xl-offset-3 col-12 margin-top-20 mx-auto">
            <div class="row">
              <h2>Listado Plantillas de Encuestas</h2>
            </div>
            <div class="row-fluid mx-auto">
              <ngx-datatable
                #gridPlantillasEncuestaList
                class="bootstrap table table-hover table-striped"
                [rows]="plantillasEncuestasListRows"
                [columns]="plantillasEncuestasListColumns"
                [rowHeight]="'auto'"
                [sortType]="'multi'"
                [limit]="10"
                [columnMode]="'force'"
                [headerHeight]="50"
                [footerHeight]="50">
              </ngx-datatable>
              <ng-template #vistaPreviaHeaderTpl let-column="column">Vista Previa</ng-template>
              <ng-template #vistaPreviaRowTpl let-row="row" let-value="value">
                <a href="javascript:;" [routerLink]="['/crud-plantilla-encuesta-preview/' + value]"><img
                  src="assets/img/vista-previa-online.png"></a>
              </ng-template>
              <ng-template #opcionesHeaderTpl let-column="column">Opciones</ng-template>
              <ng-template #opcionesRowTpl let-row="row" let-value="value">
                <a href="javascript:;" [routerLink]="['/crud-plantilla-encuesta-edit/' + value]"><img
                  src="assets/img/editar.png"></a>
                <a *ngIf="!row.eliminado" href="#" data-toggle="modal" data-target="#modal-eliminar-documento"
                   data-placement="bottom" tooltip="Haga Click para desactivar">
                  <img src="assets/img/eliminar.png" (click)="openModalDeletePlantilla(refModalDeletePlantilla)"></a>
                <a *ngIf="row.eliminado" href="#" data-toggle="modal" data-target="#modal-eliminar-documento"
                   data-placement="bottom">
                  <img src="assets/img/check.png" (click)="openModalDeletePlantilla(refModalDeletePlantilla)"></a>
                <ng-template #refModalDeletePlantilla let-c="close" let-d="dismiss">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 *ngIf="!row.eliminado" class="modal-title">Desactivar Plantilla de Encuesta</h5>
                      <h5 *ngIf="row.eliminado" class="modal-title">Activar Plantilla de Encuesta</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body col-xl-12 col-12">
                      <p *ngIf="!row.eliminado">¿Está seguro de desactivar esta plantilla de encuesta?</p>
                      <p *ngIf="row.eliminado">¿Está seguro de activar esta plantilla de encuesta?</p>
                      <b>{{row.tituloPlantilla}}</b>
                    </div>
                    <div class="modal-footer">
                      <button *ngIf="!row.eliminado" type="button" class="btn btn-danger" (click)="deletePlantillaEncuesta(row)">Desactivar
                      </button>
                      <button *ngIf="row.eliminado" type="button" class="btn btn-primary" (click)="deletePlantillaEncuesta(row)">Activar
                      </button>
                      <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
                    </div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>

<ng-template #refModalClonPlantilla let-c="close" let-d="dismiss">
  <div class="modal-content">
    <form #formClonPlantilla="ngForm" (ngSubmit)="clonarPlantilla();" novalidate>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Clonar Plantilla de Encuesta</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Seleccione la Plantilla de Encuesta que desea Clonar:</p>
      </div>
      <div class="form-group col-xl-12 col-12">
        <label>Seleccione</label>
        <ng-select class="form-control" id="nombreplantilla" name="nombreplantilla"
                   [items]="listCmbPlantillasEncuestas"
                   bindLabel="tituloPlantilla"
                   bindValue="idPlantillaEncuesta"
                   [(ngModel)]="clonModel.idPlantillaEncuesta" #nombreplantilla="ngModel" required>
        </ng-select>
        <span
          *ngIf="nombreplantilla.invalid && (nombreplantilla.dirty || nombreplantilla.touched )"
          class="form-error">
                    <span *ngIf="formClonPlantilla.hasError('required', 'nombreplantilla')">Debe seleccionar una Plantilla.</span>
                </span>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!formClonPlantilla.form.valid">Clonar</button>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
      </div>
    </form>
  </div>
</ng-template>
