import {Component, OnInit} from '@angular/core';
import {Router, NavigationStart, Event} from '@angular/router';

import {UserLoginService} from './services/user-login.service';
import {CognitoUtilService, LoggedInCallback} from './services/cognito.service';
import {AwsUtilService} from './services/aws-util.service';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, LoggedInCallback {
  currentUrl: string;

  constructor(private router: Router,
              private userLoginService: UserLoginService,
              private cognitoService: CognitoUtilService,
              private awsUtil: AwsUtilService) {
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log(`AppComponent: el usuario esta autenticado: ${isLoggedIn}`);
      let mythis = this;
      this.cognitoService.getIdToken({
        callback() {
        },
        callbackWithParam(token: any) {
          console.log('AppComponent: reinvocando initAwsService en callback');
          mythis.awsUtil.initAwsService(null, isLoggedIn, token);
        }
      });

  }
}
