import {OwlDateTimeIntl} from 'ng-pick-datetime';

export class Utils{
	static randomUuid(){
		return Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5);
	}
}


export const GLOBALS = {
	API_URL : 'https://nn2iwjfl98.execute-api.us-east-1.amazonaws.com/dev',
	TOASTER : {
		TITLE_OK : 'Operación exitosa',
		TITLE_INFO : 'Información',
		TITLE_ERROR: 'Error',
		MSG_ERROR_DEFAULT: 'Ocurrió un error interno',
		OPTIONS : {
			closeButton : true
		}
	},
	DATE_PICKER_OPTIONS : {
    dateFormat: 'dd-mm-yyyy',
    dayLabels:	{su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab'},
    monthLabels : { 1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic' },
    todayBtnTxt : 'Hoy'
  },
  URL_ENCUESTA: 'http://encuestas.0s.cl/responder/',
  CONFIG_EDITOR : {
	  // Link de documentación https://sibiraj-s.github.io/ngx-editor/additional-documentation/configuration.html
    "editable": true,
    "spellcheck": true,
    "height": "auto",
    "minHeight": "0",
    "width": "auto",
    "minWidth": "0",
    "translate": "yes",
    "enableToolbar": true,
    "showToolbar": true,
    "placeholder": "Ingrese aquí",
    "imageEndPoint": "https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/documentos/getUrlToLoad",
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough"],
      ["fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      //["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      //["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      ["link", "image"]
    ]
  }
};

// here is the default text string
export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel = 'Agregar a second';
  /** A label for the down second button (used by screen readers).  */
  downSecondLabel = 'Minus a second';
  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel = 'Add a minute';
  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel = 'Minus a minute';
  /** A label for the up hour button (used by screen readers).  */
  upHourLabel = 'Add a hour';
  /** A label for the down hour button (used by screen readers).  */
  downHourLabel = 'Minus a hour';
  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel = 'Previous month';
  /** A label for the next month button (used by screen readers). */
  nextMonthLabel = 'Next month';
  /** A label for the previous year button (used by screen readers). */
  prevYearLabel = 'Previous year';
  /** A label for the next year button (used by screen readers). */
  nextYearLabel = 'Next year';
  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel = 'Previous 21 years';
  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel = 'Next 21 years';
  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel = 'Change to month view';
  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel = 'Choose month and year';
  /** A label for the cancel button */
  cancelBtnLabel = 'Cancelar';
  /** A label for the set button */
  setBtnLabel = 'Seleccionar';
  /** A label for the range 'from' in picker info */
  rangeFromLabel = 'From';
  /** A label for the range 'to' in picker info */
  rangeToLabel = 'To';
  /** A label for the hour12 button (AM) */
  hour12AMLabel = 'AM';
  /** A label for the hour12 button (PM) */
  hour12PMLabel = 'PM';
};
