<app-header></app-header>
<section>
  <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
    <div class="container-fluid">
      <div class="row">
        <div class="col margin-top-20">
          <i>Bienvenido</i>
        </div>
        <div class="col text-right margin-top-20">
          Encuestas / Plantillas / Editar Plantilla de Encuesta
        </div>
      </div>
    </div>
    <div class="row-fluid">
      <div class="col-xl-12 col-12">
        <h1 class="margin-top-20">Editar Plantilla de Encuesta</h1>
      </div>
    </div>
    <div class="col-xl-12 col-12 padding-inside">
      <div class="col-xl-12 col-12 card">
        <div class="col-xl-12 mx-auto col-12 margin-top-20">
          <form #formPlantilla="ngForm" novalidate>
            <div class="row-fluid mx-auto">
              <div class="col-xl-12 col-12 mx-auto">
                <div class="padding-5 card col-xl-10 col-12 mx-auto">
                  <div class="col-xl-8 col-lg-10 col-12 mx-auto">
                    <div class="form-row mx-auto">
                      <div class="form-group col-xl-8 col-12">
                        <label>
                          <span class="obligatorio">*</span>Título Plantilla de Encuesta:</label>
                        <input type="text" id="tituloPlantilla" name="tituloPlantilla" placeholder="Ingrese"
                               class="form-control"
                               [(ngModel)]="modelEncuesta.tituloPlantilla" #tituloPlantilla="ngModel"
                               [ngClass]="{ 'is-invalid': formPlantilla.submitted && tituloPlantilla.invalid }"
                               required>
                        <div *ngIf="formPlantilla.submitted && tituloPlantilla.invalid"
                             style="color: #dc3545;">
                          <div *ngIf="tituloPlantilla.errors.required">Debe ingresar el nombre de la plantilla.</div>
                        </div>
                      </div>
                      <div class="form-group col-xl-4 col-12">
                        <label>Tipo de Plantilla de Encuesta:</label>
                        <ng-select id="tipoEncuesta" name="tipoEncuesta"
                                   [items]="listCmbTipoEncuesta"
                                   bindLabel="nombreTipoEncuesta"
                                   bindValue="idTipoEncuesta"
                                   [(ngModel)]="modelEncuesta.idTipoEncuesta"
                                   [disabled]="true">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-10 col-12 mx-auto">
                    <div class="form-row mx-auto">
                      <div class="form-group col-xl-12 col-12 mx-auto">
                        <label>Instrucciones para responder prueba <b>Online</b>:</label>
                        <textarea class="form-control" placeholder="Ingrese" id="instruccionesOnline"
                                  name="instruccionesOnline"
                                  [(ngModel)]="modelEncuesta.instruccionesOnline" #instruccionesOnline="ngModel">
                    </textarea>
                        <!--<div *ngIf="formPlantilla.submitted && instruccionesOnline.invalid"-->
                             <!--style="color: #dc3545;">-->
                          <!--<div *ngIf="instruccionesOnline.errors.required">Debe ingresar las instrucciones.</div>-->
                        <!--</div>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-10 col-12 mx-auto">
                    <div class="form-row mx-auto">
                      <div class="col-xl-5 col-lg-6 col-12 seleccion-tema">
                        <label>Seleccione Tema:</label>
                        <input class="form-control" [style.background]="modelEncuesta.tema"
                               [colorPicker]="modelEncuesta.tema"
                               (colorPickerChange)="temaSelected($event)" [cpPosition]="'left'">
                      </div>
                      <div class="form-group col-xl-7 col-lg-6 col-12">
                        <label data-toggle="tooltip"
                               data-title="El Logotipo Seleccionado se utilizará en la Plantilla de Encuesta y Plantilla de Correo">Cargar
                          Logotipo:</label>
                        <input type="file" class="form-control" id="logo" name="logo" (change)="cargarArchivo($event)">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Componenete de draganddrop -->
            <div class="row-fluid mx-auto">
              <div class="col-xl-12  col-xs-12 form-row mx-auto">
                <div class="col-xl-12 mx-auto col-xs-12 form-row margin-top-20">
                  <ol data-draggable="target" class="col-xl-5 col-xs-12 margin-top-20 card">
                    <h2>Banco de Componentes</h2>
                    <p>Para añadir elementos, arrástrelos hasta la sección Creación de Plantillas.</p>
                    <li>
                      <div class="form-row">
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'text'"
                             [dragEnabled]="true">
                          <h2>
                        <span class="badge badge-success float-left bold" data-toggle="tooltip"
                              title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Campo de Texto</span>
                          </h2>
                        </div>
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'textarea'"
                             [dragEnabled]="true">
                          <h2>
                        <span class="badge badge-success float-left bold" data-toggle="tooltip"
                              title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Respuesta Abierta</span>
                          </h2>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-row">
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'select'"
                             [dragEnabled]="true">
                          <h2>
                        <span class="badge badge-success float-left bold" data-toggle="tooltip"
                              title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Lista Desplegable</span>
                          </h2>
                        </div>
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'matriz'"
                             [dragEnabled]="true">
                          <h2>
                        <span class="badge badge-success float-left bold" data-toggle="tooltip"
                              title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Matriz</span>
                          </h2></div>
                      </div>
                    </li>
                    <li>
                      <div class="form-row">
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'checkbox'"
                             [dragEnabled]="true">
                          <h2>
                          <span class="badge badge-success float-left bold" data-toggle="tooltip"
                                title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Opción Múltiple</span>
                          </h2>
                        </div>
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'radio'"
                             [dragEnabled]="true">
                           <span class="badge badge-success float-left bold" data-toggle="tooltip"
                                 title="Añadir Componente" data-placement="bottom">+</span>
                          <span>Opción Única</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form-row">
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'stars'"
                             [dragEnabled]="true">
                          <h2>
                        <span class="badge badge-success float-left bold" data-toggle="tooltip"
                              title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Valoración con Estrellas</span>
                          </h2>
                        </div>
                        <div class="form-group col-sm-6 col-xs-12" dnd-draggable [dragData]="'title'"
                             [dragEnabled]="true">
                          <h2>
            <span class="badge badge-success float-left bold" data-toggle="tooltip"
                  title="Añadir Componente" data-placement="bottom">+</span>
                            <span>Título</span>
                          </h2>
                        </div>
                      </div>
                    </li>
                  </ol>

                  <ol data-draggable="target" class="col-xl-7 col-xs-12 margin-top-20 card">
                    <div class="col-xl-12 col-xs-12">
                      <h2>Editor de Plantilla</h2>
                      <p>Arrastre hasta acá los elementos para crear preguntas. Para eliminar una pregunta presiona el
                        botón rojo:</p>
                      <div class="col-xl-12 col-12 card" dnd-droppable
                           (onDropSuccess)="transferDataSuccess($event, indexPagina)"
                           *ngFor="let pag of estructuraPreguntas, let indexPagina = index">
                        <div class="col-xl-12 col-12 text-center" data-toggle="collapse" data-parent="#accordion"
                             [attr.href]="'#pagina' + indexPagina">
                          <div class="mx-auto">
                            <h3 style="border-bottom:1px solid #ccc;">Página {{pag.pagina}}: <img
                              src="assets/img/eliminar.png" [hidden]="estructuraPreguntas.length === 1"
                              data-toggle="tooltip"
                              data-title="Eliminar página" (click)="deletePage(indexPagina)"></h3>
                          </div>
                        </div>
                        <div [attr.id]="'pagina' + indexPagina" class="collapse show">
                          <li style="min-height: 300px;">
                            <div class="form-group col-xl-12 col-xs-12 margin-bottom-30"
                                 *ngFor="let data of pag.preguntas, let indexPregunta = index">
                              <h2>
                              <span class="badge badge-danger float-left bold" data-toggle="tooltip"
                                    title="Quitar Componente" data-placement="bottom"
                                    (click)="deleteItem(indexPagina, indexPregunta)">-</span>
                              </h2>
                              <p class="margin-left-30" [attr.for]="data" *ngIf="data.tipo !== 'title'"><span class="obligatorio" *ngIf="data.requerido">*</span>{{data.nombre}}
                                <a href="#"
                                   class="btn btn-primary margin-left-15"
                                   data-toggle="modal"
                                   data-target="#modal-seleccion-multiple"
                                   (click)="showEditItem(indexPagina, indexPregunta)">Editar</a>
                                <a [hidden]="indexPregunta === pag.preguntas.length - 1"
                                   (click)="downItem(indexPagina, indexPregunta)">
                                  <i class="material-icons">
                                    arrow_downward
                                  </i>
                                </a>
                                <a [hidden]="indexPregunta === 0"
                                   (click)="upItem(indexPagina, indexPregunta)">
                                  <i class="material-icons">
                                    arrow_upward
                                  </i>
                                </a>
                              </p>
                              <p class="margin-left-30" [attr.for]="data" *ngIf="data.tipo === 'title'">
                                <a href="#"
                                   class="btn btn-primary margin-left-15"
                                   data-toggle="modal"
                                   data-target="#modal-seleccion-multiple"
                                   (click)="showEditItem(indexPagina, indexPregunta)">Editar</a>
                                <a [hidden]="indexPregunta === pag.preguntas.length - 1"
                                   (click)="downItem(indexPagina, indexPregunta)">
                                  <i class="material-icons">
                                    arrow_downward
                                  </i>
                                </a>
                                <a [hidden]="indexPregunta === 0"
                                   (click)="upItem(indexPagina, indexPregunta)">
                                  <i class="material-icons">
                                    arrow_upward
                                  </i>
                                </a>
                              </p>
                              <div [ngSwitch]="data.tipo">
                                <div class="form-group col-xl-12 col-xs-12">
                                  <input *ngSwitchCase="'text'" class="form-control"
                                         [id]="data.key"
                                         [type]="data.tipo">

                                  <textarea *ngSwitchCase="'textarea'" class="form-control"
                                            [id]="data.key"></textarea>

                                  <input *ngSwitchCase="'number'"
                                         [id]="data.key"
                                         [type]="data.tipo">
                                </div>
                                <div *ngSwitchCase="'radio'">
                                  <label *ngFor="let option of data.options">
                                    <input
                                      type="radio"
                                      [name]="data.key"
                                      [value]="option.label"> {{option.label}}
                                  </label>
                                </div>
                                <div *ngSwitchCase="'checkbox'">
                                  <label *ngFor="let option of data.options">
                                    <input
                                      type="checkbox"
                                      [name]="data.key"
                                      [value]="option.label"> {{option.label}}
                                  </label>
                                </div>
                                <div *ngSwitchCase="'select'">
                                  <select class="form-control">
                                    <option *ngFor="let option of data.options" [value]="option.value">
                                      {{ option.label }}
                                    </option>
                                  </select>
                                </div>
                                <div *ngSwitchCase="'stars'">
                                  <bar-rating name="rating" [max]="data.valor" [rate]="data.valor"
                                              [readOnly]="true"></bar-rating>
                                </div>
                                <div *ngSwitchCase="'matriz'">
                                  <table class="table table-sm">
                                    <tr>
                                      <td>Preguntas</td>
                                      <td *ngFor="let alternativa of data.alternativas">
                                        {{alternativa.label}}
                                      </td>
                                    </tr>
                                    <tr *ngFor="let pregunta of data.preguntas, let indexPregunta = index">
                                      <td>
                                        {{pregunta.titulo}}
                                      </td>
                                      <td *ngFor="let alternativa of data.alternativas">
                                        <input *ngIf="data.tipoCampo === 'checkbox'" type="checkbox"
                                               name="{{indexPregunta}}"
                                               [value]="alternativa.value">
                                        <input *ngIf="data.tipoCampo === 'radio'" type="radio" name="{{indexPregunta}}"
                                               [value]="alternativa.value">
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                                <div *ngSwitchCase="'title'">
                                  <h2>{{data.nombre}}</h2>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div>
                      <div class="form-row mx-auto margin-top-20 text-center">
                        <a class="btn btn-primary btn-sm mx-auto" (click)="addPage()">Agregar Página</a>
                      </div>
                    </div>
                  </ol>
                  <div class="col-xl-12 col-12 text-right float-right margin-bottom-30">
                    <a href="mantenedor-de-encuestas.html" [routerLink]="['/crud-plantilla-encuesta']"
                       class="btn btn-gris float-right text-right margin-left-15">
                      Volver a Mantenedor de Encuestas
                    </a>
                    <button class="btn btn-gris float-right text-right margin-left-15"
                            data-toggle="tooltip"
                            data-title="Guardar y activa la plantilla de encuesta."
                            (click)="saveEditActivePlantilla(formPlantilla);" *ngIf="modelEncuesta.estado == 2"
                    >Guardar y Activar Encuesta
                    </button>
                    <button class="btn btn-primary float-right text-right"
                            (click)="saveEditPlantilla(formPlantilla)">Guardar Encuesta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- modal template abierta component -->
<ng-template #modalTextareaComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear campo de respuesta abierta</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formText="ngForm" novalidate>
    <div class="modal-body">
      <div clas="form-group row">
        <label>Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span *ngIf="formText.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formText.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formText.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template text component -->
<ng-template #modalTextComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear campo de texto</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formText="ngForm" novalidate>
    <div class="modal-body">
      <div clas="form-group row">
        <label>Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span *ngIf="formText.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formText.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formText.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template select component -->
<ng-template #modalSelectComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 *ngIf="isEdit" class="modal-title">Editar lista desplegable</h4>
    <h4 *ngIf="!isEdit" class="modal-title">Crear lista desplegable</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formSelect="ngForm">
    <div class="modal-body">
      <div clas="form-group row">
        <label>Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" name="nombre" [(ngModel)]="modeloTexto.nombre" #nombre="ngModel"
               required>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span
          *ngIf="formSelect.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-group row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
      <div clas="form-group row">
        <table class="table table-sm">
          <tr *ngFor="let option of modeloTexto.options, let indexOptions = index">
            <td><input type="text" placeholder="Ingrese una opción de respuesta" id="label"
                       name="label_{{indexOptions}}"
                       class="form-control" [value]="option.label"
                       [(ngModel)]="option.label" ngModel #label="ngModel"
                       (blur)="updateValueAlternative($event, indexOptions)"
                       required/>
              <span
                *ngIf="label.invalid && (label.dirty || label.touched )"
                class="form-error">
            <span
              *ngIf="formSelect.hasError('required','label_' + indexOptions)">Debe ingresar una opción de respuesta.</span>
          </span></td>
            <!--<td><input type="text" name="optionsValue0" class="input-sm" /></td>-->
            <td>
              <i class="material-icons" (click)="addAlternative()">
                add_circle_outline
              </i>
              <i class="material-icons" [hidden]="(modeloTexto.options.length === 1 && indexOptions === 0)"
                 (click)="removeAlternative(indexOptions)">
                remove_circle_outline
              </i>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formSelect.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formSelect.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template radio component -->
<ng-template #modalRadioComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear opción única</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formRadio="ngForm">
    <div class="modal-body">
      <div clas="form-group row">
        <label for="label">Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" name="nombre" required [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required/>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span
          *ngIf="formRadio.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
      <div clas="form-group row">
        <table class="table table-sm">
          <tr *ngFor="let option of modeloTexto.options, let indexOptions = index">
            <td><input type="text" placeholder="Ingrese una opción de respuesta" id="label"
                       name="label_{{indexOptions}}"
                       class="form-control" [value]="option.label"
                       [(ngModel)]="option.label" ngModel #label="ngModel"
                       (blur)="updateValueAlternative($event, indexOptions)"
                       required/>
              <span
                *ngIf="label.invalid && (label.dirty || label.touched )"
                class="form-error">
                <span
                  *ngIf="formSelect.hasError('required','label_' + indexOptions)">Debe ingresar una opción de respuesta.</span>
              </span></td>
            <!--<td><input type="text" name="optionsValue0" class="input-sm" /></td>-->
            <td>
              <i class="material-icons" (click)="addAlternative()">
                add_circle_outline
              </i>
              <i class="material-icons" [hidden]="(modeloTexto.options.length === 1 && indexOptions === 0)"
                 (click)="removeAlternative(indexOptions)">
                remove_circle_outline
              </i>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formRadio.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formRadio.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template check component -->
<ng-template #modalCheckComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear opción múltiple</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formCheck="ngForm">
    <div class="modal-body">
      <div clas="form-group row">
        <label for="label">Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input class="form-control" type="text" name="nombre" required [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required/>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span
          *ngIf="formCheck.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
      <div clas="form-group row">
        <table class="table table-sm">
          <tr *ngFor="let option of modeloTexto.options, let indexOptions = index">
            <td><input type="text" placeholder="Ingrese una opción de respuesta" id="label"
                       name="label_{{indexOptions}}"
                       class="form-control" [value]="option.label"
                       [(ngModel)]="option.label" ngModel #label="ngModel"
                       (blur)="updateValueAlternative($event, indexOptions)"
                       required/>
              <span
                *ngIf="label.invalid && (label.dirty || label.touched )"
                class="form-error">
                <span
                  *ngIf="formSelect.hasError('required','label_' + indexOptions)">Debe ingresar una opción de respuesta.</span>
              </span></td>
            <!--<td><input type="text" name="optionsValue0" class="input-sm" /></td>-->
            <td>
              <i class="material-icons" (click)="addAlternative()">
                add_circle_outline
              </i>
              <i class="material-icons" [hidden]="(modeloTexto.options.length === 1 && indexOptions === 0)"
                 (click)="removeAlternative(indexOptions)">
                remove_circle_outline
              </i>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formCheck.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formCheck.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template number component -->
<ng-template #modalNumberComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear campo numérico</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formNumber="ngForm">
    <div class="modal-body">
      <div clas="form-group row">
        <label for="nombre">Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" name="nombre" required [(ngModel)]="modeloTexto.nombre"/>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span
          *ngIf="formNumber.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-group row">
        <label>Valor inicial del campo</label>
        <input type="number" name="value" [(ngModel)]="modeloTexto.valor"/>
      </div>
      <div clas="form-group row">
        <div class="form-check">
          <label class="form-check-label">Requerido</label>
          <input type="checkbox" class="form-check-intput" [checked]="modeloTexto.requerido">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formNumber.form.valid">Guardar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template stars component -->
<ng-template #modalStarsComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear campo de Valoración con Estrellas</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formText="ngForm" novalidate>
    <div class="modal-body">
      <div clas="form-group row">
        <label>Título de la pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span *ngIf="formText.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
      <div class="form-group row ">
        <label>Seleccione la cantidad (<span style="color:red;">*</span>)</label>
        <select class="form-control col-lg-4" [(ngModel)]="modeloTexto.valor"
                id="cantidadEstrellas" name="cantidadEstrellas" #valor="ngModel" required>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
        <span
          *ngIf="valor.invalid && (valor.dirty || valor.touched )"
          class="form-error">
        <span *ngIf="formText.hasError('required', 'cantidadEstrellas')">Debe seleccionar la cantidad.</span>
        </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12 mx-auto" style="margin-left:-25px">
          <bar-rating name="rating" [rate]="modeloTexto.valor" [max]="modeloTexto.valor" [readOnly]="true"></bar-rating>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formText.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formText.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template matiz component -->
<ng-template #modalMatrizComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Campo de Matriz</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formMatriz="ngForm" novalidate>
    <div class="modal-body">
      <div clas="form-group row">
        <label>Título agrupador pregunta (<span style="color:red;">*</span>)</label>
        <input type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="modeloTexto.nombre"
               #nombre="ngModel" required>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span *ngIf="formMatriz.hasError('required', 'nombre')">Debe ingresar el título de la pregunta.</span>
      </span>
      </div>
      <div clas="form-row">
        <div class="form-group col-lg-12 col-xs-12">
          <label class="checkbox-inline col-lg-12 col-xs-12">
            <input type="checkbox" class="form-check-intput" name="requerido" [(ngModel)]="modeloTexto.requerido"
                   [checked]="modeloTexto.requerido">
            <span>¿Esta Pregunta es Obligatoria?</span>
          </label>
        </div>
      </div>
      <div class="form-group">
        <input type="radio" id="radio" name="tipoCampo" [value]="'radio'" [(ngModel)]="modeloTexto.tipoCampo"
               checked="checked"/>
        <label for="radio">Radio</label>
        &nbsp;
        <input type="radio" id="checkbox" name="tipoCampo" [value]="'checkbox'" [(ngModel)]="modeloTexto.tipoCampo"/>
        <label for="checkbox">Casilla Verificación</label>
      </div>
      <div class="card padding-5">
        <p>Preguntas</p>
        <div class="form-row" *ngFor="let pregunta of modeloTexto.preguntas, let indexPregunta = index">
          <div class="form-group col-xl-10 col-12">
            <input type="text" name="" class="form-control" [value]="pregunta.titulo"
                   placeholder="Ingrese título de la pregunta"
                   (blur)="updateValueQuestionMatriz($event, indexPregunta)">
          </div>
          <div class="form-group col-xs-1">
            <i class="material-icons" (click)="addQuestionMatriz()">
              add_circle_outline
            </i>
            <i class="material-icons" [hidden]="(modeloTexto.preguntas.length === 1 && indexPregunta === 0)"
               (click)="removeQuestionMatriz(indexPregunta)">
              remove_circle_outline
            </i>
          </div>
        </div>
      </div>
      <div class="card padding-5">
        <p>Alternativas</p>
        <div class="form-row" *ngFor="let alternativa of modeloTexto.alternativas, let indexAlternativa = index">
          <div class="form-group col-xl-10 col-12">
            <input type="text" name="" class="form-control" [value]="alternativa.label"
                   placeholder="Ingresa la alternativa" (blur)="updateValueAlternativeMatriz($event, indexAlternativa)">
          </div>
          <div class="form-group col-xs-1">
            <i class="material-icons" (click)="addAlternativeMatriz()">
              add_circle_outline
            </i>
            <i class="material-icons" [hidden]="(modeloTexto.alternativas.length === 1 && indexAlternativa === 0)"
               (click)="removeAlternativeMatriz(indexAlternativa)">
              remove_circle_outline
            </i>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formMatriz.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formMatriz.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<!-- modal template title component -->
<ng-template #modalTitleComponent let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Crear título</h4>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form #formTitle="ngForm" novalidate>
    <div class="modal-body">
      <div clas="form-group row">
        <label>Ingrese título(<span style="color:red;">*</span>)</label>
        <textarea type="text" class="form-control" id="nombre" name="nombre" [(ngModel)]="modeloTexto.nombre"
                  #nombre="ngModel" required></textarea>
        <span
          *ngIf="nombre.invalid && (nombre.dirty || nombre.touched )"
          class="form-error">
        <span *ngIf="formTitle.hasError('required', 'nombre')">Debe ingresar el título.</span>
      </span>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" (click)="addItem()" [disabled]="!formTitle.form.valid"
              *ngIf="!isEdit">Guardar
      </button>
      <button type="submit" class="btn btn-primary" (click)="editItem()" [disabled]="!formTitle.form.valid"
              *ngIf="isEdit">Editar
      </button>
      <button type="button" class="btn btn-default" (click)="d()">Cancelar</button>
    </div>
  </form>
</ng-template>
<app-footer></app-footer>
