import {Deserializable} from "./deserializable.interface";

export class PlantillasEncuestasListModel implements Deserializable<PlantillasEncuestasListModel> {
  public idPlantillaEncuesta: string;
  public tituloPlantilla: string;
  public nombreTipoPlantilla: string;
  public nombreEstadoPlantillaEncuesta: string;
  public eliminado:boolean;
  public preguntas: string;


  constructor(plantillaEncuestaJson: any) {
    this.idPlantillaEncuesta = plantillaEncuestaJson.idPlantillaEncuesta;
    this.tituloPlantilla = plantillaEncuestaJson.tituloPlantilla;
    this.nombreTipoPlantilla = plantillaEncuestaJson.nombreTipoPlantilla;
    this.nombreEstadoPlantillaEncuesta = plantillaEncuestaJson.nombreEstadoPlantillaEncuesta;
    this.eliminado = plantillaEncuestaJson.eliminado;
    this.preguntas = plantillaEncuestaJson.preguntas;
  }

  deserialize(input: any): PlantillasEncuestasListModel {
    Object.assign(this, input);
    return this;
  }
}
