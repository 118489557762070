<footer class="footer" style="background:url('assets/img/footer.png');background-size:cover;">
  <div class="row col-xl-12 mx-auto">

    <div class="col-md-4 col-sm-12 col-12">
      <h2>Sistema de Gestión de Capacitación v{{version}} 2018
        <br/><b>OTIC SOFOFA</b>
      </h2>
      <h3 class="margin-top--10">Todos los Derechos Reservados</h3>
    </div>
    <div class="col-md-4 col-sm-12 col-12">
      <h2>Consultas e Informaciones:
      </h2>
      <h3 class="margin-top--10"><a href="tel:+56212345678" class="text-white">(+562) 2336 28 00 </a></h3>
      <h3 class="margin-top--10"><a href="mailto:plataformadeayuda@oticsofofa.cl" target="_blank" class="text-white">plataformadeayuda@oticsofofa.cl</a></h3>
    </div>
    <div class="col-md-4 col-sm-12 col-12">
      <img src="assets/img/otic-sofofa.png" alt="Otic-Sofofa" title="Otic-Sofofa" class="float-right margin-top-10" style="height:60px;width:auto;">
    </div>
  </div>
  <div class="row col-xl-12 mx-auto">
    <div class="col-md-12 col-sm-12 col-12 margin-top-20 max-auto">
      <p>Casa Matriz: Agustinas 1357. Pisos 11 y 12 - Santiago. Teléfono/Fax: (+562) 2336 28 00.
      Agencias Regionales: Iquique - Antofagasta - Calama - Copiapó - Viña del Mar - Concepción - Temuco - Puerto Montt - Punta Arenas</p>
    </div>
  </div>
</footer>
