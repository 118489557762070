//angular
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";

//utils
import {Observable} from "rxjs";
import 'rxjs/add/operator/do';

//services
import {UserLoginService} from "../services/user-login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = JSON.parse(localStorage.getItem('idToken'));

    let request = req;
    if (idToken) {
      request = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${idToken._value}`)
      });
      console.log('AuthInterceptor: authorization agregado a peticion.')
    }

    return next.handle(request).do(event => {
      if (event instanceof HttpResponse) {
        console.log('---> status:', event.status);
        console.log('---> filter:', request.params.get('filter'));
      }
    });
  }

}
