<app-header></app-header>
<div class="container-fluid margin-bottom-30" style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
    <section>
        <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col margin-top-20">
                        <i>Bienvenido</i>
                    </div>
                    <div class="col text-right margin-top-20">
                        Encuestas / Servicio de Encuestas de Efectividad
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-xl-12 col-12">
                    <h1 class="margin-top-20">Servicio de Encuestas de Efectividad</h1>
                </div>
            </div>
            <div class="col-xl-12 col-12 padding-inside">
                <div class="col-xl-12 col-12 card">

                    <div class="col-xl-8 col-lg-10 col-12 mx-auto margin-top-10">
                        <div class="form-row mx-auto">
                            <div class=" form-group col-xl-12">
                                Buscar Encuestas de Efectividad:
                            </div>
                        </div>
                        <div class="form-row mx-auto">
                            <div class="col-xl-4 col-lg-4 col-12">
                                <input type="text" [(ngModel)]="filtersModel.codigoEncuesta" (keyup)="gridFilter($event, 'CODIGO_ENCUESTA')" class="form-control" placeholder="Ingrese código">
                            </div>
                            <div class="col-xl-6 col-lg-6 col-12">

                                <!--<ng-select id="filtroEncuesta" name="filtroEncuesta" [items]="encuestaListRowsTemp"-->
                                <!--bindLabel="nombreEncuesta"-->
                                <!--bindValue="nombreEncuesta"-->
                                <!--[(ngModel)]="filtersModel.nombreEncuesta"-->
                                <!--(change)="gridFilter($event, 'NOMBRE_ENCUESTA')">-->
                                <!--</ng-select>-->
                                <input type="text" [(ngModel)]="filtersModel.nombreEncuesta" (keyup)="gridFilter($event, 'NOMBRE_ENCUESTA')" class="form-control" placeholder="Ingrese nombre">
                            </div>
                            <div class="col-xl-2 col-lg-2 col-12">
                                <a data-toggle="collapse" href="#verFiltros" class="btn btn-primary">Ver Más
                  Filtros</a>
                            </div>
                        </div>
                    </div>
                    <div id="verFiltros" class="collapse">
                        <div class="col-xl-6 mx-auto col-12 margin-top-20">
                            <div class="row mx-auto">
                                <div class="form-group col-xl-3 col-12">
                                    <label>
                    Tipo de Encuesta:
                  </label>
                                    <ng-select id="tipoEncuesta" name="tipoEncuesta" [items]="listCmbTipoEncuesta" bindLabel="nombreTipoEncuesta" bindValue="nombreTipoEncuesta" [(ngModel)]="filtersModel.tipoEncuesta" (change)="gridFilter($event, 'TIPO_ENCUESTA')">
                                    </ng-select>
                                </div>
                                <div class="form-group col-xl-6 col-12">
                                    <label>
                    Encuesta:
                  </label>
                                    <ng-select id="tipoPlantilla" name="tipoPlantilla" [items]="listCmbPlantillasEncuestas" bindLabel="tituloPlantilla" bindValue="tituloPlantilla" [(ngModel)]="filtersModel.tituloPlantilla" (change)="gridFilter($event, 'TITULO_PLANTILLA')">
                                    </ng-select>
                                </div>
                                <div class="form-group col-xl-3 col-12">
                                    <label>
                    Empresa:
                  </label>
                                    <ng-select id="nombreEmpresa" name="nombreEmpresa" [items]="dummyEmpresa" bindLabel="nombreEmpresa" bindValue="nombreEmpresa" [(ngModel)]="filtersModel.nombreEmpresa" (change)="gridFilter($event, 'NOMBRE_EMPRESA')">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="row mx-auto">

                                <div class="form-group col-xl-6 col-12">
                                    <label>
                    Curso:
                  </label>
                                    <ng-select id="nombreCurso" name="nombreCurso" [items]="dummyCurso" bindLabel="nombreCurso" bindValue="nombreCurso" [(ngModel)]="filtersModel.nombreCurso" (change)="gridFilter($event, 'NOMBRE_CURSO')">
                                    </ng-select>
                                </div>
                                <div class="form-group col-xl-3 col-12">
                                    <label>
                    Etapa Curso:
                  </label>
                                    <ng-select id="etapaCurso" name="etapaCurso" [items]="listCmbEtapas" bindLabel="nombreDetalle" bindValue="nombreDetalle" [(ngModel)]="filtersModel.nombreEtapaCurso" (change)="gridFilter($event, 'ETAPA_CURSO')">
                                    </ng-select>
                                </div>
                                <div class="form-group col-xl-3 col-12">
                                    <label>
                    Estado Encuesta de Ef.:
                  </label>
                                    <ng-select id="estadosEncuesta" name="estadosEncuesta" [items]="listCmbEstados" bindLabel="nombreEstadoEncuesta" bindValue="nombreEstadoEncuesta" [(ngModel)]="filtersModel.nombreEstado" (change)="gridFilter($event, 'NOMBRE_ESTADO')">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-fluid">
                        <div class="col-xl-12 col-lg-12 col-12">

                            <a [routerLink]="['/crud-encuesta-add']" class="btn btn-primary float-right margin-top-20" style="color:white;">
                Agregar Nueva Encuesta de Efectividad
              </a>

                        </div>
                    </div>


                    <div class="form-row">
                        <div class="col-xl-12 col-lg-12 col-12 mx-auto margin-top-20 table-responsive">
                            <h2>Listado de Encuestas de Efectividad</h2>
                            <p>Haga clic en una Encuesta de Efectividad para expandir y ver más detalles y opciones:</p>
                            <div class="row-fluid mx-auto">
                                <ngx-datatable #table class="bootstrap table table-hover table-striped" [columnMode]="'force'" [limit]="10" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [rows]='encuestaListRows' (page)="onPage($event)">
                                    <!-- Row Detail Template -->
                                    <ngx-datatable-row-detail [rowHeight]="120" #myDetailRow (toggle)="onDetailToggle($event)">
                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                                            <div class=" card padding-5">
                                                <div class="form-row">
                                                    <div class="col-xl-12 col-12">
                                                        <h2>Detalle Encuesta de Efectividad</h2>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="col-xl-12 col-12">
                                                        Nombre Curso: <b>{{row.curso.nombreCurso}}</b>
                                                        <span class="margin-left-15">Etapa Curso: <b>{{row.curso.etapaCurso}}</b></span>
                                                        <span class="margin-left-15">Ver Encuestas:
                            <a href="javascript:;" [routerLink]="['/crud-encuesta-preview/' + row.idEncuesta]"
                               target="_blank">
                              <img src="assets/img/vista-previa-online.png" data-toggle="tooltip"
                                   data-title="Vista previa Digital">
                            </a></span>
                                                        <span class="margin-left-15">Opciones:
                              <a [routerLink]="['/crud-encuesta-edit/' + row.idEncuesta]">
                                <img src="assets/img/editar.png" data-target="tooltip" data-title="Editar Proceso">
                              </a>
                              <a>
                                <img
                                  *ngIf="!row.eliminado && (!row.estadoServicio || (row.estadoServicio && row.estadoEncuesta == 'Por Enviar'))"
                                  src="assets/img/eliminar.png" data-toggle="tooltip"
                                  data-title="Desactivar Encuesta"
                                  (click)="openModal (refModalDeleteEncuesta)">
                                <img
                                  *ngIf="row.eliminado && (!row.estadoServicio || (row.estadoServicio && row.estadoEncuesta == 'Por Enviar'))"
                                  src="assets/img/check.png" data-toggle="tooltip"
                                  data-title="Activar Encuesta"
                                  (click)="openModal(refModalDeleteEncuesta)">
                              </a>
                              <a [routerLink]="['/respuestas-encuesta/' + row.idEncuesta]" data-toggle="tooltip"
                                 data-title="Ver Respuestas, hay Encuestas Pendientes" data-placement="bottom">
                                <img src="assets/img/encuestas-pendientes.png"
                                     alt="Ver Respuestas, hay Encuestas Pendientes">
                              </a>
                              <button class="btn btn-primary margin-left-15" ngxClipboard [cbContent]="row.urlEncuesta">Copiar URL Encuesta</button>
                              <button class="btn btn-primary margin-left-15" (click)="exportarReporteGeneral(row.idEncuesta)" >Descargar Reporte</button>
                              <!--<a href="reportes-encuesta.html" class="btn btn-primary margin-left-15">Reportes</a>-->
                              <!--<a href="#" class="btn btn-primary margin-left-15" data-toggle="modal"-->
                              <!--data-target="#recordatorio-manual">Recordatorio Manual</a>-->
                            </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-template #refModalDeleteEncuesta let-c="close" let-d="dismiss">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 *ngIf="!row.eliminado" class="modal-title">Desactivar Encuesta</h5>
                                                        <h5 *ngIf="row.eliminado" class="modal-title">Activar Encuesta</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                                    </div>
                                                    <div class="modal-body col-xl-12 col-12">
                                                        <p *ngIf="!row.eliminado">¿Está seguro de desactivar esta encuesta?</p>
                                                        <p *ngIf="row.eliminado">¿Está seguro de activar esta encuesta?</p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" *ngIf="!row.eliminado" class="btn btn-danger" (click)="deletePlantillaEncuesta(row)">Desactivar
                            </button>
                                                        <button type="button" *ngIf="row.eliminado" class="btn btn-primary" (click)="deletePlantillaEncuesta(row)">Activar
                            </button>
                                                        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar
                            </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </ngx-datatable-row-detail>
                                    <!-- Column Templates -->
                                    <ngx-datatable-column [width]="10" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
                                        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                                            <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)">
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Código Encuesta de Efectividad" width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.codigoEncuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Nombre Encuesta de Efectividad" width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.nombreEncuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Tipo Encuesta Ef." width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.tipoEncuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Plantilla Encuesta" width="200">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.nombrePlantillaEncuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Empresa" width="200">
                                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                            {{row.empresa.razonSocial}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Fecha Envío" width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.fechaEnvio}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Fecha Límite Rptas." width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.fechaLimiteRespuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Estado Servicio Encuesta" width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div *ngIf="row.estadoServicio && !row.eliminado">
                                                <span class="badge badge-primary">Activa</span>
                                            </div>
                                            <div *ngIf="!row.estadoServicio && !row.eliminado">
                                                <span class="badge badge-secondary">En Borrador</span>
                                                <button class="btn btn-primary btn-sm" (click)="openModal(refModelActivarEncuesta)">Activar
                        </button>
                                            </div>
                                            <div *ngIf="row.eliminado">
                                                <span class="badge badge-secondary">Inactiva</span>
                                            </div>
                                            <ng-template #refModelActivarEncuesta let-c="close" let-d="dismiss">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title">Activar Encuesta</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
                              <span aria-hidden="true">&times;</span>
                            </button>
                                                    </div>
                                                    <div class="modal-body col-xl-12 col-12">
                                                        <p>¿Esta seguro de cambiar el estado de esta Encuesta de Efectividad?</p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-primary" (click)="activarPlantillaEncuesta(row)">
                              Activar
                            </button>
                                                        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar
                            </button>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Estado Encuestas y Rptas." width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.estadoEncuesta}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Encuestas Respondidas" width="80">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <span class="badge badge-secondary" *ngIf="row.totalParticipantesRespondidos == 0">{{row.totalParticipantesRespondidos}}/{{row.totalParticipantes}}</span>
                                            <span class="badge badge-warning" *ngIf="row.totalParticipantesRespondidos > 0 && row.totalParticipantesRespondidos < row.totalParticipantes">{{row.totalParticipantesRespondidos}}/{{row.totalParticipantes}}</span>
                                            <span class="badge badge-success" *ngIf="row.totalParticipantesRespondidos == row.totalParticipantes">{{row.totalParticipantesRespondidos}}/{{row.totalParticipantes}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </section>

</div>
<app-footer></app-footer>