//Angular
import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {forkJoin} from 'rxjs'
import {map} from "rxjs/operators";

//Utils
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {GLOBALS} from "../../shared/globals.shared";
import * as cloneDeep from 'lodash/cloneDeep';

//Models
import {EncuestaResultadoCabeceraModel} from "../../models/encuesta-resultado-cabecera.model";
import {EncuestaResultadoParticipantesModel} from "../../models/encuesta-resultado-participantes.model";
import {EncuestaResultadoPreguntasModel} from "../../models/encuesta-resultado-preguntas.model";
import {EncuestaReportePdfModel} from "../../models/encuesta-reporte-pdf.model";

//Services
import {EncuestasResultadosService} from "../../services/encuestas-resultados.service";
import {UtilService} from "../../services/util.service";
import {ReportesService} from "../../services/reportes.service";
import {UserLoginService} from "../../services/user-login.service";
import {LoggedInCallback} from "../../services/cognito.service";


@Component({
  selector: 'app-respuestas-encuesta',
  templateUrl: './respuestas-encuesta.component.html',
  styleUrls: ['./respuestas-encuesta.component.css']
})
export class RespuestasEncuestaComponent implements OnInit, OnDestroy, LoggedInCallback {
  modelRespuestaCabecera: EncuestaResultadoCabeceraModel = new EncuestaResultadoCabeceraModel();
  modelEncuestaReportePdf: EncuestaReportePdfModel = new EncuestaReportePdfModel();
  modelRespuestaParticipantes: EncuestaResultadoParticipantesModel [];
  modelRespuestaPreguntas: EncuestaResultadoPreguntasModel[];
  modelRespuestaPreguntasPdf: EncuestaResultadoPreguntasModel[];
  respuestaParticipanteColumns: any;
  pathParams: any;
  timeout: any = 0;
  options: object;
  isActiveParticipantes: boolean = false;
  isActivePreguntas: boolean = false;
  timestamp: number;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private encuestasResultadosService: EncuestasResultadosService,
              private utilService: UtilService,
              private reportesService: ReportesService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private userLoginService: UserLoginService) {
    console.log('RespuestaEncuestaComponent: constructor');
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaEncuestaPreviewComponent: verificacion sesion');
    if (!isLoggedIn) {
      console.log('CrudPlantillaEncuestaPreviewComponent: usuario no autenticado, redireccionando al login.');
      this.router.navigate(['/login']);
    } else {
      this.timestamp = new Date().getTime();
      this.route.params.subscribe(params => this.pathParams = params);
      if (Number.isNaN(this.pathParams.id)) {
        this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/crud-encuesta']);
      }
      this.initResultado(this.pathParams.id);
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  /**
   * Entra al ciclo de destruccuón y detiene el timeout para obtener el tiempo restantes del servicio de encuesta
   */
  ngOnDestroy() {
    clearTimeout(this.timeout);
    this.timeout = 0;
  }


  onPage(event) {
    console.log('paged!', event);
  }

  /**
   * Obtiene la información de la encuenta
   */
  initResultado(idEncuesta) {
    this.spinner.show();
    this.encuestasResultadosService.getCabecera(idEncuesta)
      .subscribe((resultado: EncuestaResultadoCabeceraModel) => {
        this.modelRespuestaCabecera = resultado;
        this.modelRespuestaCabecera.fechaEnvio = moment.unix(parseInt(resultado.fechaEnvio)).format('DD/MM/YYYY HH:mm');
        this.modelRespuestaCabecera.fechaLimiteRespuesta = resultado.fechaLimiteRespuesta ? moment.unix(parseInt(resultado.fechaLimiteRespuesta)).format('DD/MM/YYYY HH:mm') : '';
        this.initObtenerFechaSistema();
        this.spinner.hide();
      })
  }

  /**
   * Obtiene las fecha actual del sistema cada 1 segundo.
   */
  initObtenerFechaSistema() {
    this.utilService.obtenerFechaSistema()
      .subscribe((fecha) => {
        let start_date = moment(moment(fecha).format('DD/MM/YYYY HH:mm:ss'), 'DD-MM-YYYY HH:mm:ss');
        let end_date = moment(this.modelRespuestaCabecera.fechaLimiteRespuesta, 'DD-MM-YYYY HH:mm:ss');
        let duration = moment.duration(end_date.diff(start_date));
        //this.spinner.hide();
        if (duration.days() > 0 || duration.hours() > 0 || duration.minutes() > 0 || duration.seconds() > 0) {
          this.modelRespuestaCabecera.tiempoRestante = duration.days() + 'd ' + duration.hours() + 'h ' + duration.minutes() + ' m ' + duration.seconds() + 's';
          this.timeout = setTimeout(() => {
            this.timeout = 0;
            this.initObtenerFechaSistema()
          }, 1000);
        } else {
          this.modelRespuestaCabecera.tiempoRestante = '0d 0h 0m 0s';
        }
      });
  }

  /**
   * Obtiene el valor en porcentaje para aplicar en el estilo
   * @param tipo
   */
  getPorcentajeEstilo(tipo) {
    let valor = 0;
    switch (tipo) {
      case 'respondidas':
        valor = (this.modelRespuestaCabecera.respondidas * 100 / this.modelRespuestaCabecera.cantidadParticipantes);
        break;
      case 'incompletas':
        valor = (this.modelRespuestaCabecera.incompletas * 100 / this.modelRespuestaCabecera.cantidadParticipantes);
        break;
      case 'norespondidas':
        valor = (this.modelRespuestaCabecera.norespondidas * 100 / this.modelRespuestaCabecera.cantidadParticipantes);
        break;
    }
    return valor;
  }

  /**
   * Pinta los graficos por pregunta dependendiendo de la cantidad de alternativas y su cantidad de respuestas.
   * @param preguntas
   */
  paintChartPreguntas(preguntas) {
    for (let i = 0; i < preguntas.length; i++) {
      preguntas[i].timestamp = this.timestamp;
      if (preguntas[i].tipoPregunta !== 'matriz') {
        preguntas[i]['options'] = {
          title: {text: preguntas[i].tituloPregunta},
          series: [{
            name: 'Respuestas',
            data: [],
          }],
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          chart: {
            plotShadow: false,
            type: 'pie',
            width: '250',
            height: '260'
          }
        };
        preguntas[i].alternativas.forEach((item) => {
          preguntas[i].options.series[0].data.push({
            name: item.label,
            y: item.count
          })
        });
      }
      else {
        for (let j = 0; j < preguntas[i].alternativas.length; j++) {
          preguntas[i].alternativas[j]['options'] = {
            title: {text: preguntas[i].alternativas[j].titulo},
            series: [{
              name: 'Respuestas',
              data: []
            }],
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: false
                },
                showInLegend: true
              }
            },
            chart: {
              plotShadow: false,
              type: 'pie',
              width: '220',
              height: '210'
            }
          };
          preguntas[i].alternativas[j].alternativas.forEach((item) => {
            preguntas[i].alternativas[j].options.series[0].data.push({
              name: item.label,
              y: item.count
            })
          });
        }
      }
    }
    return preguntas;
  }

  /**
   * Pinta los graficos por pregunta dependendiendo de la cantidad de alternativas y su cantidad de respuestas para las imagenes del reporte en PDF.
   * @param preguntas
   */
  paintChartPreguntasPdf(preguntas) {
    for (let i = 0; i < preguntas.length; i++) {
      preguntas[i].timestamp = this.timestamp;
      if (preguntas[i].tipoPregunta !== 'matriz') {
        preguntas[i]['options'] = {
          title: {text: preguntas[i].tituloPregunta},
          xAxis: {
            type: 'category'
          },
          yAxis: {
            min: 0,
            title: {
              text: '',
              align: 'high'
            },
            labels: {
              overflow: 'justify'
            }
          },
          series: [{
            name: 'Respuestas',
            data: [],
          }],
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          chart: {
            plotShadow: false,
            type: 'bar',
            // type: 'column',
            width: '350',
            height: '260'
          },
          legend: {
            layout: 'horizontal',
            shadow: false
          }
        };
        preguntas[i].alternativas.forEach((item) => {
          preguntas[i].options.series.push({
            name: item.label,
            data: [{
              name: item.label,
              y: item.count
            }],
          });
        });
        console.log(preguntas[i].options.series);
      }
      else {
        preguntas[i]['options'] = {
          title: {text: preguntas[i].tituloPregunta},
          xAxis: {
            categories: []
          },
          series: [],
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          chart: {
            //plotShadow: false,
            // type: 'bar',
            renderTo: 'container',
            type: 'column',
            width: '350',
            height: '260'
          }
          ,
          yAxis: {
            min: 0,
            title: {
              text: '',
              align: 'high'
            },
            labels: {
              overflow: 'justify'
            }
          }
        };
        let row = 0;
        for (let j = 0; j < preguntas[i].alternativas.length; j++) {
          preguntas[i].options.xAxis.categories.push(preguntas[i].alternativas[j].titulo);
          row = 0;
          if (preguntas[i].options.series.length === 0) {
            preguntas[i].alternativas[j].alternativas.forEach((item) => {
              preguntas[i].options.series.push({
                name: item.label,
                data: [item.count]
              });
            });
          } else {
            debugger;
            preguntas[i].alternativas[j].alternativas.forEach((item) => {
              preguntas[i].options.series[row].data.push(item.count);
              row++;
            });
          }
        }
        console.log(preguntas[i].options);
      }
    }

    return preguntas;
  }

  /**
   * Pinta la cantidad de respuestas de los participantes
   */
  paintChartParticipantes() {
    this.options = {
      title: {text: 'Encuestas'},
      series: [{
        name: 'Encuestas',
        data: [{
          name: 'Respondidas',
          color: '#3cd83c',
          y: this.modelRespuestaCabecera.respondidas
        }, {
          name: 'Respondida Parcial',
          color: '#ffc107',
          y: this.modelRespuestaCabecera.incompletas
        }, {
          name: 'No Respondidas',
          color: '#dc3545',
          y: this.modelRespuestaCabecera.norespondidas
        }],
      }],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      chart: {
        plotShadow: false,
        type: 'pie',
        width: '250',
        height: '260'
      }
    };
  }

  /**
   * Exporta excel con el listado de participantes
   */
  exportarParticipantes() {
    this.spinner.show();
    this.reportesService.exportarParticipantes(this.pathParams.id)
      .subscribe((result) => {
        const link = document.createElement('a');
        // Add the element to the DOM
        link.setAttribute("type", "hidden"); // make it hidden if needed
        link.download = result.metadata.filename;
        link.href = 'data:' + result.metadata.mimeType + ';base64,' + result.b64;
        document.body.appendChild(link);
        link.click();
        link.remove();

        this.spinner.hide();
      })
  }

  /**
   * Muestra el acordeon de los participantes cargando desde el servicio
   */
  resumenParticipantes() {
    console.log('resumen participantes');
    this.isActiveParticipantes = !this.isActiveParticipantes;
    if (this.isActiveParticipantes) {
      this.spinner.show();
      this.encuestasResultadosService.getParticipantes(this.pathParams.id)
        .subscribe((participantes: EncuestaResultadoParticipantesModel[]) => {
          this.modelRespuestaParticipantes = participantes;
          this.paintChartParticipantes();
          this.spinner.hide();
        })
    }
  }

  /**
   * Muestra el acordeon de las preguntas cargando desde el servicio
   */
  resumenPreguntas() {
    this.isActivePreguntas = !this.isActivePreguntas;
    if (this.isActivePreguntas) {
      this.spinner.show();
      this.encuestasResultadosService.getPreguntas(this.pathParams.id)
        .subscribe((preguntas: EncuestaResultadoPreguntasModel[]) => {
          this.modelRespuestaPreguntasPdf = this.paintChartPreguntasPdf(cloneDeep(preguntas));
          this.modelRespuestaPreguntas = this.paintChartPreguntas(cloneDeep(preguntas));
          this.modelEncuestaReportePdf.informacionEncuesta = this.modelRespuestaCabecera;
          this.modelEncuestaReportePdf.informacionRespuestas = this.modelRespuestaPreguntas;
          this.spinner.hide();
        });
    }
  }

  /**
   * Exporta el documento PDF
   */
  exportar() {
    this.spinner.show();
    const arrayPromesas = [];

    const respuestasParaGraficos = this.modelRespuestaPreguntasPdf;

    respuestasParaGraficos.forEach((item) => {
      const model = {
        numeroPregunta: item.numeroPregunta,
        tipoPregunta: item.tipoPregunta,
        timestamp: this.timestamp,
        options: item.options
      };
      arrayPromesas.push(this.reportesService.generarGraficos(model));
    });

    forkJoin(arrayPromesas)
      .subscribe(() => {
        this.reportesService.exportarPdfPreguntas(this.modelEncuestaReportePdf)
          .subscribe((result) => {
            const link = document.createElement('a');
            // Add the element to the DOM
            link.setAttribute('type', 'hidden'); // make it hidden if needed
            link.download = result.fileName;
            link.href = result.urlDownload;
            document.body.appendChild(link);
            link.click();
            link.remove();

            this.spinner.hide();
          }, error => {
            this.spinner.hide();
          })
      }, error1 => {
        this.spinner.hide();
      });
  }


  exportarPpt() {
    this.spinner.show();
    this.reportesService.exportarReportePpt(this.modelEncuestaReportePdf)
      .subscribe((result) => {
        const link = document.createElement('a');
        // Add the element to the DOM
        link.setAttribute("type", "hidden"); // make it hidden if needed
        link.download = result.fileName;
        link.href = result.urlDownload;
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.spinner.hide();
      })
  }
}
