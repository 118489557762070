import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";

import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {

  constructor(private http: HttpClient) {
  }

  requestUrlToLoadFile(file: any): Observable<string> {
    return new Observable(observer => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.addEventListener('loadend', function (e) {
        fetch(`${environment.API_DOMAINS.DOCUMENTOS}/getUrlToLoad`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type
          })
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            return fetch(json.uploadURL, {
              method: "PUT",
              body: new Blob([reader.result], {type: file.type})
            })
          })
          .then(function (json) {
            observer.next(environment.S3_URL + environment.S3_PREFIX_UPLOAD_IMAGE + file.name);
            observer.complete();
          })
          .catch(function (err) {
            observer.error(err);
            observer.complete();
          });

      });
    });
  }
}
