import { Injectable } from '@angular/core';

import { Callback, CognitoUtilService } from './cognito.service';
import * as AWS from 'aws-sdk/global';

@Injectable({
  providedIn: 'root'
})
export class AwsUtilService {
  public static firstLogin: boolean = false;
  public static runningInit: boolean = false;

  constructor(private cognitoUtil: CognitoUtilService) {
    AWS.config.region = CognitoUtilService.REGION;
  }

  /**
   * Este metodo debe ser invocado para inicializar las credenciales globales de aws
   */
  initAwsService(callback: Callback, isLoggedIn: boolean, idToken : string){
    //Necesitamos asegurarnos de no entrar en un loop infinito corriendo initAwsService()... si ya se ejecutó
    if (AwsUtilService.runningInit){
      console.log('AwsUtilService: Abortando ejecución de initAwsService... ya se ejecutó');

      //en vez de abortar aca, es mejor poner un timer
      if (callback != null){
        callback.callback();
        callback.callbackWithParam(null);
      }

      return;
    }

    console.log('AwsUtilService: Corriendo initAwsService()');
    AwsUtilService.runningInit = true;

    let mythis = this;

    //chequeo si el usuario ya está autenticado
    if (isLoggedIn){
    mythis.setupAWS(isLoggedIn, callback, idToken);
    }
  }

  /**
   * Setup de los parametros globales AWS
   *
   * @param isLoggedIn
   * @param callback
   */
   setupAWS(isLoggedIn: boolean, callback: Callback, idToken: string): void {
     console.log('AwsUtilService: en setupAWS()');

     if (isLoggedIn){
       console.log('AwsUtilService: el Usuario está logeado');

       this.addCognitoCredentials(idToken);

       console.log('AwsUtilService: Recuperando el id token');
     }else{
       console.log('AwsUtilService: El usuario no está logeado');
     }

     if (callback != null){
       callback.callback();
       callback.callbackWithParam(null);
     }

     AwsUtilService.runningInit = false;
   }

   addCognitoCredentials(idTokenJwt: string): void {
     let creds = this.cognitoUtil.buildCognitoCreds(idTokenJwt);

     AWS.config.credentials = creds;

     creds.get(err => {
       if (!err){
         if (AwsUtilService.firstLogin){
           AwsUtilService.firstLogin = false;
         }
       }
     });
   }

   static getCognitoParametersForIdConsolidation(idTokenJwt: string): {}{
     console.log('AwsUtilService: Corriendo getCognitoParametersForIdConsolidation()');

     let url = 'cognito-idp.' + CognitoUtilService.REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtilService.USER_POOL_ID;
     let logins: Array<string> = [];
     logins[url] = idTokenJwt;

     let params = {
       IdentityPoolId: CognitoUtilService.IDENTITY_POOL_ID,
       Logins : logins
     };

     return params;
   }
}
