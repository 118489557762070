import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {EncuestaResultadoPreguntasModel} from "../models/encuesta-resultado-preguntas.model";

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

  constructor(private http: HttpClient) {
  }

  exportarParticipantes(idEncuesta): Observable<any> {
    return this.http
      .get<any>(`${environment.API_DOMAINS.REPORTES}/participantes/${idEncuesta}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  exportarReporteGeneral(idEncuesta): Observable<any> {
    return this.http
      .get<any>(`${environment.API_DOMAINS.REPORTES}/general/${idEncuesta}`)
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  exportarReportePpt(resultado): Observable<any> {
    const body = JSON.stringify(resultado);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.REPORTES}/ppt`, body, {headers: headers});
  }

  exportarPdfPreguntas(resultado): Observable<any> {
    const body = JSON.stringify(resultado);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.REPORTES}/preguntas`, body, {headers: headers});
    // return this.http
    //   .get<any>(`${environment.API_DOMAINS.REPORTES}/preguntas`)
    //   .pipe(
    //     map((result: any) => {
    //       return result;
    //     })
    //   );
  }

  generarGraficos(resultado): Observable<any> {
    const body = JSON.stringify(resultado);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.REPORTES}/graficos`, body, {headers: headers});
  }
}
