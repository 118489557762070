import {Deserializable} from "./deserializable.interface";


export class RespuestaModel implements Deserializable<RespuestaModel> {
  public requerido: boolean;
  public numeroPregunta: number;
  public respuesta: any[];
  constructor(respuesta?: any) {
    this.requerido = respuesta && respuesta.requerido || null;
    this.numeroPregunta = respuesta && respuesta.numeroPregunta || null;
    this.respuesta = respuesta && respuesta.respuesta || null;
  }

  deserialize(input: any): RespuestaModel {
    Object.assign(this, input);
    return this;
  }
}
