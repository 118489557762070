<app-header></app-header>
<div class="container-fluid margin-bottom-30" style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
    <section>
        <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col margin-top-20">
                        <i>Bienvenido</i>
                    </div>
                    <div class="col text-right margin-top-20">
                        Encuestas / Servicio de Encuestas de Efectividad / Nueva Encuesta de Efectividad
                    </div>
                </div>
            </div>

            <div class="row-fluid">
                <div class="col-xl-10 col-12">
                    <h1 class="margin-top-20">Nueva Encuesta de Efectividad</h1>
                </div>
            </div>

            <div class="col-xl-12 col-12 padding-inside">
                <div class="col-xl-12 col-12 card-ok">
                    <form name="form" #formEncuesta="ngForm" novalidate>
                        <div class="row row-fluid mx-auto">
                            <ul class="nav nav-tabs mx-auto row">
                                <li class="nav-item cursos-opcion">
                                    <a class="nav-link active" data-toggle="tab" href="#informacion-general">
                                        <img src="assets/img/encuestas-informacion-general.svg" data-toggle="tooltip" title="Ver Información General de la Encuesta de Efectividad" data-placement="bottom">
                                    </a>
                                </li>
                                <li class="nav-item cursos-opcion">
                                    <a class="nav-link" data-toggle="tab" href="#participantes">
                                        <img src="assets/img/encuestas-participantes.svg" data-toggle="tooltip" title="Ver Participantes de la Encuesta de Efectividad" data-placement="bottom">
                                    </a>
                                </li>
                                <li class="nav-item cursos-opcion">
                                    <a class="nav-link" data-toggle="tab" href="#plantillas-correo">
                                        <img src="assets/img/encuestas-plantillas-de-correo.svg" data-toggle="tooltip" title="Ver Plantillasde Correos para la Encuesta de Efectividad" data-placement="bottom">
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content">


                            <div class="tab-pane active container card" id="informacion-general">
                                <div class="col-xl-12 mx-auto col-12">
                                    <div class="row-fluid">
                                        <h2>Nombre Encuesta</h2>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="form-group col-xl-12 col-12">
                                            <label>
                        <span class="obligatorio">*</span>Nombre Encuesta de Efectividad:
                      </label>
                                            <input type="text" class="form-control" placeholder="Ingrese" id="nombreEncuesta" name="nombreEncuesta" [(ngModel)]="modelEncuesta.nombreEncuesta" #nombreEncuesta="ngModel" [ngClass]="{ 'is-invalid': formEncuesta.submitted && nombreEncuesta.invalid }"
                                                required>
                                            <div *ngIf="formEncuesta.submitted && nombreEncuesta.invalid" class="invalid-feedback">
                                                <div *ngIf="nombreEncuesta.errors.required">Debe ingresar el nombre de la encuesta</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="form-group col-xl-4 col-12">
                                            <label>
                        <span class="obligatorio">*</span>Código Encuesta de Efectividad:
                      </label>
                                            <input type="text" class="form-control" placeholder="Ingrese" id="codigoEncuesta" name="codigoEncuesta" [(ngModel)]="modelEncuesta.codigoEncuesta" #codigoEncuesta="ngModel" [ngClass]="{ 'is-invalid': formEncuesta.submitted && codigoEncuesta.invalid }"
                                                required>
                                            <div *ngIf="formEncuesta.submitted && codigoEncuesta.invalid" class="invalid-feedback">
                                                <div *ngIf="codigoEncuesta.errors.required">Debe ingresar el código de la encuesta</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row-fluid">
                                        <h2 class="margin-top-20">Plantilla de Encuesta</h2>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="form-group col-xl-8 col-10">
                                            <label>
                        <span class="obligatorio">*</span>Nombre Plantilla de Encuesta:
                      </label>
                                            <ng-select class="form-control" id="idPlanillaEncuesta" name="idPlanillaEncuesta" [items]="listCmbPlantillasEncuestas" bindLabel="tituloPlantilla" bindValue="idPlantillaEncuesta" [(ngModel)]="modelEncuesta.idPlanillaEncuesta" #idPlanillaEncuesta="ngModel"
                                                [ngStyle]="{'border-color' : (formEncuesta.submitted && idPlanillaEncuesta.invalid) ? '#dc3545' : 'white'}" required (change)="selectPlantillaEncuesta($event)">
                                            </ng-select>
                                            <div *ngIf="formEncuesta.submitted && idPlanillaEncuesta.invalid" style="color: #dc3545;">
                                                <div *ngIf="idPlanillaEncuesta.errors.required">Debe seleccionar un tipo de plantilla</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-2 col-2">
                                            <a *ngIf="modelEncuesta.idPlanillaEncuesta" [routerLink]="['/crud-plantilla-encuesta-preview/' + modelEncuesta.idPlanillaEncuesta]" target="_blank">
                                                <img src="assets/img/ver.png" alt="Ver Encuesta" data-toggle="tooltip" data-title="Ver Encuesta" style="margin-top:35px;">
                                            </a>
                                        </div>
                                    </div>

                                    <div class="row-fluid">
                                        <h2 class="margin-top-20">Empresa y Curso</h2>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="col-xl-4 col-lg-12 col-12 form-group">
                                            <label>
                        <span class="obligatorio">*</span>Empresa:
                      </label>
                                            <ng-select class="form-control" id="empresaModel" name="empresaModel" [items]="dummyEmpresa" bindLabel="razonSocial" bindValue="razonSocial" [(ngModel)]="empresaModel" #empresa="ngModel" [ngStyle]="{'border-color' : (formEncuesta.submitted && empresa.invalid) ? '#dc3545' : 'white'}"
                                                (change)="selectEmpresa($event)" required>
                                            </ng-select>
                                            <div *ngIf="formEncuesta.submitted && empresa.invalid" style="color: #dc3545;">
                                                <div *ngIf="empresa.errors.required">Debe seleccionar una empresa</div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-12 col-12 form-group">
                                            <label>
                        <span class="obligatorio">*</span>Curso:
                      </label>
                                            <ng-select class="form-control" id="cursoModel" name="cursoModel" [disabled]="!empresaSeleccionada" [items]="dummyCurso" bindLabel="nombreCurso" bindValue="nombreCurso" [(ngModel)]="cursoModel" #curso="ngModel" [ngStyle]="{'border-color' : (formEncuesta.submitted && curso.invalid) ? '#dc3545' : 'white'}"
                                                (change)="selectCurso($event)" required>
                                            </ng-select>
                                            <div *ngIf="formEncuesta.submitted && curso.invalid" style="color: #dc3545;">
                                                <div *ngIf="curso.errors.required">Debe seleccionar un curso.</div>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-4 col-6">
                                            <label>
                        <span class="obligatorio">*</span>Etapa Curso:
                      </label>
                                            <ng-select class="form-control" id="etapaCurso" name="etapaCurso" [items]="listCmbEtapas" bindLabel="nombreDetalle" bindValue="nombreDetalle" [(ngModel)]="etapaCursoModel" [ngStyle]="{'border-color' : (formEncuesta.submitted && etapaCurso.invalid) ? '#dc3545' : 'white'}"
                                                (change)="selectEtapa($event)" #etapaCurso="ngModel" required>
                                            </ng-select>
                                            <div *ngIf="formEncuesta.submitted && etapaCurso.invalid" style="color: #dc3545;">
                                                <div *ngIf="etapaCurso.errors.required">Debe seleccionar una etapa de curso.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-ok padding-5">
                                        <div class="form-row" *ngIf="modelEncuesta.curso != undefined">
                                            <div class="col-xl-6 col-6">
                                                <p>Curso: <b>{{modelEncuesta.curso.nombreCurso}}</b></p>
                                            </div>
                                            <div class="col-xl-3 col-6">
                                                <p>
                                                    Tipo Curso: <b>{{modelEncuesta.curso.tipoCurso}}</b>
                                                </p>
                                            </div>
                                            <div class="col-xl-3 col-6">
                                                <p>
                                                    Empresa: <b>{{modelEncuesta.empresa.nombreEmpresa}}</b>
                                                </p>
                                            </div>
                                            <div class="col-xl-3 col-6">
                                                <p>Fecha Inicio Ej: <b>{{modelEncuesta.curso.fechaInicio}}</b>
                                                </p>
                                            </div>
                                            <div class="col-xl-3 col-6">
                                                <p>Fecha Fin Ej: <b>{{modelEncuesta.curso.fechaFin}}</b>
                                                </p>
                                            </div>
                                            <!--<div class="col-xl-3 col-6">-->
                                            <!--<p>-->
                                            <!--Quitar:-->
                                            <!--<a href="#">-->
                                            <!--<img src="assets/img/eliminar.png" data-target="tooltip"-->
                                            <!--data-title="Quitar Curso">-->
                                            <!--</a>-->
                                            <!--</p>-->
                                            <!--</div>-->
                                        </div>
                                    </div>
                                    <div class="row-fluid">
                                        <h2 class="margin-top-20">Respuestas de la Encuesta</h2>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="form-group col-xl-12 col-6">
                                            <div class="form-check" style="margin-top: 35px;">
                                                <input type="checkbox" class="form-check-input" [(ngModel)]="modelEncuesta.permiteMasRespuestas" id="permiteMasRespuestas" name="permiteMasRespuestas">
                                                <label class="form-check-label" for="permiteMasRespuestas">
                                                <span data-toggle="tooltip"
                                                      data-title="Si marca esta casilla, los usuarios podrán ingresar a la encuesta y responderla todas las veces que lo deseen, hasta que se acabe el plazo, en el caso que lo haya seleccionado.">Permitir Responder más de una vez</span>
                        </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end row-->
                                    <div class="row-fluid">
                                        <h2 class="margin-top-20">Pantalla de despedida</h2>
                                    </div>
                                    <div class="row mx-auto">
                                        <div class="form-group col-xl-12 col-12">
                                            <label data-toggle="tooltip" data-title="Este texto será visualizado por el usuario en una pantalla de despedida cuando envíe las respuestas de la Encuesta.">
                        <span class="obligatorio">*</span>Ingrese texto de despedida</label>
                                            <app-ngx-editor class="form-control" [config]="config" [(ngModel)]="modelEncuesta.textoDespedida" id="textoDespedida" name="textoDespedida" #textoDespedida="ngModel" required [ngStyle]="{'border-color' : (formEncuesta.submitted && textoDespedida.invalid) ? '#dc3545' : 'white'}"></app-ngx-editor>
                                            <div *ngIf="formEncuesta.submitted && textoDespedida.invalid" style="color: #dc3545;">
                                                <div *ngIf="textoDespedida.errors.required">Debe ingresar texto de despedida.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end row-->

                                </div>
                            </div>
                            <!--edn tabpane-->
                            <!--participantes-->
                            <div class="tab-pane container card" id="participantes">
                                <div class="col-xl-12 mx-auto col-12">
                                    <div class="row-fluid mx-auto">
                                        <div class="row-fluid">
                                            <h2>Participantes</h2>
                                        </div>
                                    </div>
                                    <div class="form-row mx-auto">
                                        <div class="col-xl-12 col-12 text-right">
                                            <a class="btn btn-primary float-right">
                        Actualizar Participantes
                      </a>
                                        </div>
                                    </div>
                                    <div class="row-fluid mx-auto">

                                        <div class="form-row">
                                            <div class="col-xl-12 col-12">
                                                <p>Seleccione el <b>tipo de indentificador</b> que utilizará para aquellos participantes que utilizarán el <b>Recopilador URL:</b></p>
                                            </div>
                                            <div class="col-xl-4 col-6">
                                                <select class="form-control" [(ngModel)]="modelEncuesta.identificadorEncuesta" id="identificadorEncuesta" name="identificadorEncuesta" #identificadorEncuesta="ngModel" [ngStyle]="{'border-color' : (formEncuesta.submitted && identificadorEncuesta.invalid) ? '#dc3545' : 'white'}"
                                                    required>
                          <option value="id">ID</option>
                          <option value="rut">RUT</option>
                          <option value="otro"> Otro Identificador</option>
                        </select>
                                                <div *ngIf="formEncuesta.submitted && identificadorEncuesta.invalid" style="color: #dc3545;">
                                                    <div *ngIf="identificadorEncuesta.errors.required">Debe seleccionar un recopilador.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row mx-auto">
                                            <h2 class="margin-top-20">Listado de Participantes</h2>
                                            <div class="col-xl-12 col-12">
                                                <p>Marque los participantes que recibirán la encuesta. Seleccione el tipo de recopilador que requiera para cada participante e ingrese el identificador:</p>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-xl-6 col-6">
                                                <label class="checkbox-inline col-lg-12 col-xs-12">
                          <input type="checkbox" [checked]="todos" id="todos" name="todos" [(ngModel)]="todos"
                                 (change)="seleccionarTodos($event)">
                          Seleccionar Todos los Participantes
                        </label>
                                            </div>
                                            <div class="col-xl-6 col-6 text-right" *ngIf="!modelEncuesta.estadoServicio || modelEncuesta.eliminado">
                                                <div class="btn btn-primary" (click)="openModalParticipante(refModalAddParticipante)">Agregar Participante
                                                </div>
                                            </div>
                                        </div>

                                        <ngx-datatable #myTable class="bootstrap table table-hover table-striped" [columnMode]="'flex'" [limit]="10" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [rows]='dummyParticipantes' (page)="onPage($event)">
                                            <ngx-datatable-column name="Seleccionar" [flexGrow]="0.3">
                                                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                    <input type="checkbox" [checked]="row.recibeEncuesta" id="recibeEncuesta" name="recibeEncuesta" (blur)="updateValue($event, 'recibeEncuesta', rowIndex)">
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Nombre Participante" [flexGrow]="0.7">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.nombreParticipante}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Recopilador" [flexGrow]="0.3">
                                                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                    <select name="recopilador" id="recopilador" class="form-control" (change)="updateValue($event, 'recopilador', rowIndex)">
                            <option value="url" [selected]="row.recopilador == 'url'">URL</option>
                            <option value="email" [selected]="row.recopilador == 'email'">EMAIL</option>
                          </select>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Identificador" [flexGrow]="0.7">
                                                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                    <input *ngIf="modelEncuesta.identificadorEncuesta != 'rut' && row.recopilador != 'email'" type="text" class="form-control col-lg-12 col-xs-12" [value]="row.identificador" id="identificador" name="identificador" (blur)="updateValue($event, 'identificador', rowIndex)">
                                                    <input *ngIf="row.recopilador == 'email'" type="text" class="form-control col-lg-12 col-xs-12" [value]="row.email" id="email" name="identificador" (blur)="updateValue($event, 'email', rowIndex)">
                                                    <input *ngIf="modelEncuesta.identificadorEncuesta == 'rut' && row.recopilador != 'email'" type="text" class="form-control col-lg-12 col-xs-12" [value]="row.rutParticipante" id="rut" name="identificador" [disabled]="true">
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Contraseña" [flexGrow]="0.5">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <input *ngIf="row.recopilador != 'email'" type="text" class="form-control col-lg-12 col-xs-12" [disabled]="true" [value]="row.contrasena" id="contrasena" name="contrasena">
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Recordar" [flexGrow]="0.3">
                                                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                    <input type="checkbox" [checked]="row.recordar" id="recordar" name="recordar" (blur)="updateValue($event, 'recordar', rowIndex)">
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Quitar" [flexGrow]="0.3" *ngIf="!modelEncuesta.estadoServicio || modelEncuesta.eliminado">
                                                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                                    <a href="#" data-toggle="modal" data-target="#modal-eliminar-documento" data-placement="bottom">
                                                        <img src="assets/img/eliminar.png" (click)="openModalDeleteParticipante(refModalDeleteParticipante)"></a>

                                                    <ng-template #refModalDeleteParticipante let-c="close()" let-d="dismiss">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="eliminarInsumo">Quitar Participante</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                                                            </div>
                                                            <div class="modal-body col-xl-12 col-12">
                                                                <p>¿Está seguro de quitar al participante <b>{{row.nombreParticipante}}</b>?</p>

                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-danger" (click)="quitarParticipante(row, rowIndex)">Eliminar
                                </button>
                                                                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c">
                                  Cancelar
                                </button>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </ng-template>
                                            </ngx-datatable-column>
                                        </ngx-datatable>
                                        <!--end table-->

                                        <div class="form-row">
                                            <div class="col-xl-6 col-12">
                                                URL Encuesta
                                            </div>
                                        </div>
                                        <div class="form-row justify-content-start align-items-center">
                                            <div class="col-xl-3 col-12">
                                                {{url}}
                                            </div>
                                            <div class="col-xl-3 col-12">
                                                <input type="text" class="form-control" [(ngModel)]="modelEncuesta.urlEncuesta" value="ejemplo-encuesta-1" id="urlEncuesta" name="urlEncuesta" #urlEncuesta="ngModel" [ngClass]="{ 'is-invalid': formEncuesta.submitted && urlEncuesta.invalid }" required
                                                    (keydown.space)="$event.preventDefault()">
                                                <div *ngIf="formEncuesta.submitted && urlEncuesta.invalid" style="color: #dc3545;">
                                                    <div *ngIf="urlEncuesta.errors.required">Debe ingresar una url para la encuesta.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row-fluid mx-auto text-right" [hidden]="true">
                                        <a class="btn btn-primary">Exportar</a>
                                    </div>
                                </div>
                            </div>
                            <!--end participantes-->
                            <!--plantillas de correo-->
                            <div class="tab-pane container card" id="plantillas-correo">
                                <div class="col-xl-12 mx-auto col-12">


                                    <div class="row-fluid mx-auto">
                                        <h2>Plantillas de Correo</h2>
                                        <p>Seleccione las plantillas de Correo para cada tipo de Notificación que requiera
                                        </p>
                                        <div class="col-xl-12 col-12 card-ok padding-5">
                                            <h2>Plantilla de Invitación</h2>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Seleccione Plantilla de Correo:</label>
                                                    <ng-select class="form-control" id="plantillaInvitacion" name="plantillaInvitacion" [items]="listCmbPlantillasCorreosInvitacion" bindLabel="nombrePlantillaCorreo" bindValue="idPlantillaCorreo" [(ngModel)]="plantillaInvitacionModel" #plantillaInvitacion="ngModel"
                                                        [ngStyle]="{'border-color' : (formEncuesta.submitted && plantillaInvitacion.invalid) ? '#dc3545' : 'white'}" required (change)="selectPlantillaCorreo($event, 'INVITACION')">
                                                    </ng-select>
                                                    <div *ngIf="formEncuesta.submitted && plantillaInvitacion.invalid" style="color: #dc3545;">
                                                        <div *ngIf="plantillaInvitacion.errors.required">Debe seleccionar una plantilla de invitación.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Mensaje:</label>
                                                    <!--<input type="text" class="form-control" placeholder="Ingrese"-->
                                                    <!--[(ngModel)]="modelEmails.invitacion.mensaje" id="invitacionMensaje"-->
                                                    <!--name="invitacionMensajes">-->
                                                    <app-ngx-editor [config]="config" [(ngModel)]="modelEmails.invitacion.mensaje" name="modelEmails.invitacion.mensaje"></app-ngx-editor>
                                                </div>
                                                <div class="form-group col-xl-6 col-12" *ngIf="modelEmails.invitacion.idPlantillaCorreo != null">
                                                    <ng-container *ngTemplateOutlet="templateEmailInvitacion"></ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-12 col-12 card-ok padding-5 margin-top-10">
                                            <h2>Plantilla de Recordatorio</h2>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Seleccione Plantilla de Correo:</label>
                                                    <ng-select class="form-control" id="plantillaRecordatorio" name="plantillaRecordatorio" [items]="listCmbPlantillasCorreosRecordatorio" bindLabel="nombrePlantillaCorreo" bindValue="idPlantillaCorreo" [(ngModel)]="plantillaRecordatorioModel" #plantillaRecordatorio="ngModel"
                                                        [ngStyle]="{'border-color' : (formEncuesta.submitted && plantillaRecordatorio.invalid) ? '#dc3545' : 'white'}" required (change)="selectPlantillaCorreo($event, 'RECORDATORIO')">
                                                    </ng-select>
                                                    <div *ngIf="formEncuesta.submitted && plantillaRecordatorio.invalid" style="color: #dc3545;">
                                                        <div *ngIf="plantillaRecordatorio.errors.required">Debe seleccionar una plantilla de recordatorio.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Mensaje:</label>
                                                    <app-ngx-editor [config]="config" [(ngModel)]="modelEmails.recordatorio.mensaje" name="modelEmails.recordatorio.mensaje"></app-ngx-editor>
                                                </div>
                                                <div class="form-group col-xl-12 col-12" *ngIf="modelEmails.recordatorio.idPlantillaCorreo != null">
                                                    <ng-container *ngTemplateOutlet="templateEmailRecordatorio"></ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-12 col-12 card-ok padding-5 margin-top-10">
                                            <h2>Plantilla de Cierre</h2>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Seleccione Plantilla de Correo:</label>
                                                    <ng-select class="form-control" id="plantillaCierre" name="plantillaCierre" [items]="listCmbPlantillasCorreosCierre" bindLabel="nombrePlantillaCorreo" bindValue="idPlantillaCorreo" [(ngModel)]="plantillaCierreModel" #plantillaCierre="ngModel" [ngStyle]="{'border-color' : (formEncuesta.submitted && plantillaCierre.invalid) ? '#dc3545' : 'white'}"
                                                        required (change)="selectPlantillaCorreo($event, 'CIERRE')">
                                                    </ng-select>
                                                    <div *ngIf="formEncuesta.submitted && plantillaCierre.invalid" style="color: #dc3545;">
                                                        <div *ngIf="plantillaCierre.errors.required">Debe seleccionar una plantilla de cierre.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Mensaje:</label>
                                                    <app-ngx-editor [config]="config" [(ngModel)]="modelEmails.cierre.mensaje" name="modelEmails.cierre.mensaje"></app-ngx-editor>
                                                </div>

                                                <div class="form-group col-xl-12 col-12" *ngIf="modelEmails.cierre.idPlantillaCorreo != null">
                                                    <ng-container *ngTemplateOutlet="templateEmailCierre"></ng-container>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-xl-12 col-12 card-ok padding-5 margin-top-10">
                                            <h2>Plantilla Confirmación de envío Respuestas</h2>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Seleccione Plantilla de Correo:</label>
                                                    <ng-select class="form-control" id="plantillaEnvioRpta" name="plantillaEnvioRpta" [items]="listCmbPlantillasCorreosConfirmacion" bindLabel="nombrePlantillaCorreo" bindValue="idPlantillaCorreo" [(ngModel)]="plantillaEnvioRptaModel" #plantillaEnvioRpta="ngModel"
                                                        [ngStyle]="{'border-color' : (formEncuesta.submitted && plantillaEnvioRpta.invalid) ? '#dc3545' : 'white'}" required (change)="selectPlantillaCorreo($event, 'ENVIORPTA')">
                                                    </ng-select>
                                                    <div *ngIf="formEncuesta.submitted && plantillaEnvioRpta.invalid" style="color: #dc3545;">
                                                        <div *ngIf="plantillaEnvioRpta.errors.required">Debe seleccionar una plantilla de envío respuesta.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row-fluid">
                                                <div class="form-group col-xl-6 col-12">
                                                    <label>Mensaje:</label>
                                                    <!--<input type="text" class="form-control" placeholder="Ingrese"-->
                                                    <!--[(ngModel)]="modelEmails.envioRpta.mensaje"-->
                                                    <!--id="envioRptaMensaje" name="envioRptaMensaje">-->
                                                    <app-ngx-editor [config]="config" [(ngModel)]="modelEmails.envioRpta.mensaje" name="modelEmails.envioRpta.mensaje"></app-ngx-editor>
                                                </div>
                                                <div class="form-group col-xl-12 col-12" *ngIf="modelEmails.envioRpta.idPlantillaCorreo != null">
                                                    <ng-container *ngTemplateOutlet="templateEmailEnvioRpta"></ng-container>
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                            <!--end plantillas de correo-->
                            <div class="col-xl-8 col-12 mx-auto text-right">
                                <button class="btn btn-primary  margin-left-15" (click)="saveEncuesta(formEncuesta)" *ngIf="!modelEncuesta.estadoServicio || modelEncuesta.eliminado">
                  Editar
                </button>
                                <button [routerLink]="['/crud-encuesta']" class="btn btn-gris  margin-left-15">
                  Volver a Listado de Encuestas de Efectividad
                </button>
                            </div>

                        </div>
                    </form>
                    <!--end tab-content-->
                </div>
            </div>
        </div>
    </section>
</div>
<app-footer></app-footer>

<ng-template #templateFechaEnvio let-c="close" let-d="dismiss">
    <div class="modal-content">
        <form name="form" #formFechaEnvio="ngForm" novalidate (submit)="guardarFechaEnvio(formFechaEnvio)">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Temporalidad y Recordatorios</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row mx-auto">
                    <div class="form-group col-xl-12 col-12">
                        <label>
              <span class="obligatorio">*</span>Seleccione la Fecha Envío de Encuesta:
            </label>
                        <input class="form-control" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" id="fechaEnvio" name="fechaEnvio" placeholder="Seleccionar" [(ngModel)]='dateStart' #fechaEnvio="ngModel" [ngClass]="{ 'is-invalid': formFechaEnvio.submitted && fechaEnvio.invalid }"
                            required>
                        <owl-date-time #dt1></owl-date-time>
                        <div *ngIf="formFechaEnvio.submitted && fechaEnvio.invalid" style="color: #dc3545;">
                            <div *ngIf="fechaEnvio.errors.required">Debe seleccionar una fecha.</div>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto">
                    <div class="form-group col-xl-6 col-6">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" [checked]="modelEncuesta.fechaMaxRpta" [(ngModel)]="modelEncuesta.fechaMaxRpta" name="fechaMaxRpta" id="fechaMaxRpta" (change)="checkSelectedDateLimit($event)">
                            <label class="form-check-label" for="exampleCheck1">Fecha Máxima Rpta.
              </label>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto">
                    <div class="form-group col-xl-6 col-6">
                        <label>
              <span class="obligatorio">*</span>Fecha Límite Rpta.:
            </label>
                        <input class="form-control" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="Seleccionar" [(ngModel)]='dateLimit' id="fechaLimiteRespuesta" name="fechaLimiteRespuesta" #fechaLimiteRespuesta="ngModel" [disabled]="!modelEncuesta.fechaMaxRpta"
                            [ngClass]="{ 'is-invalid': formFechaEnvio.submitted && fechaLimiteRespuesta.invalid }" required>
                        <owl-date-time #dt2></owl-date-time>
                        <div *ngIf="formFechaEnvio.submitted && fechaLimiteRespuesta.invalid" style="color: #dc3545;">
                            <div *ngIf="fechaLimiteRespuesta.errors.required">Debe seleccionar una fecha.</div>
                        </div>

                    </div>
                    <div class="form-group col-xl-6 col-6">
                        <label>
              Recordar a partir de:
            </label>
                        <input type="text" class="form-control" [(ngModel)]="modelEncuesta.recordatorio" placeholder="Ingrese los días" id="recordatorio" name="recordatorio" [disabled]="!modelEncuesta.fechaMaxRpta" onlyNumber>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Guardar</button>
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #templateEmailInvitacion>
    <!doctype html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
    </head>

    <body style="height:auto;width:550px;font-family:'Arial, sans-serif';background: #ffffff !important;">
        <table [ngStyle]="{'background':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">

            <body style="background-color: #FFFFFF !important;">
                <tr>
                    <td [ngStyle]="{'height':'10px','width':'100%','background-color': modelEmails.invitacion.tema}"></td>
                </tr>
                <tr *ngIf="modelEmails.invitacion.logo !== null">
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;background: #ffffff;">
                        <span style="font-size:25px;color:blue;"><img [src]="modelEmails.invitacion.logo"
                                                        style="height:40px;width:auto;text-align:center;"></span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <span style="font-size:25px;color:blue;">{{modelEmails.invitacion.encabezado}}</span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:auto;padding:10px 5px;">
                        <div [innerHTML]="modelEmails.invitacion.mensaje | safe"></div>
                    </td>
                </tr>
                <tr>
                    <td [ngStyle]="{'width':'550px','height':'auto','padding':'10px 5px','color':'#fff','text-align':'center', 'background':modelEmails.invitacion.tema}">
                        OTIC Sofofa 2018, Servicio de Encuestas
                    </td>
                </tr>
            </body>
        </table>
    </body>

    </html>
</ng-template>
<ng-template #templateEmailRecordatorio>
    <!doctype html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
    </head>

    <body style="height:auto;width:550px;font-family:'Arial, sans-serif';background: #ffffff !important;">
        <table [ngStyle]="{'background':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">

            <body style="background-color: #FFFFFF !important;">
                <tr>
                    <td [ngStyle]="{'height':'10px','width':'100%','background-color': modelEmails.recordatorio.tema}"></td>
                </tr>
                <tr *ngIf="modelEmails.recordatorio.logo !== null">
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;background: #ffffff;">
                        <span style="font-size:25px;color:blue;"><img [src]="modelEmails.recordatorio.logo"
                                                      style="height:40px;width:auto;text-align:center;"></span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <span style="font-size:25px;color:blue;">{{modelEmails.recordatorio.encabezado}}</span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:auto;padding:10px 5px;">
                        <div [innerHTML]="modelEmails.recordatorio.mensaje | safe"></div>
                    </td>
                </tr>
                <tr>
                    <td [ngStyle]="{'width':'550px','height':'auto','padding':'10px 5px','color':'#fff','text-align':'center', 'background':modelEmails.recordatorio.tema}">
                        OTIC Sofofa 2018, Servicio de Encuestas
                    </td>
                </tr>
            </body>
        </table>
    </body>

    </html>
</ng-template>
<ng-template #templateEmailCierre>
    <!doctype html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
    </head>

    <body style="height:auto;width:550px;font-family:'Arial, sans-serif';background: #ffffff !important;">
        <table [ngStyle]="{'background':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">

            <body style="background-color: #FFFFFF !important;">
                <tr>
                    <td [ngStyle]="{'height':'10px','width':'100%','background-color': modelEmails.cierre.tema}"></td>
                </tr>
                <tr *ngIf=" modelEmails.cierre.logo !== null">
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;background: #ffffff;">
                        <span style="font-size:25px;color:blue;"><img [src]="modelEmails.cierre.logo"
                                                      style="height:40px;width:auto;text-align:center;"></span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <span style="font-size:25px;color:blue;">{{modelEmails.cierre.encabezado}}</span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:auto;padding:10px 5px;">
                        <div [innerHTML]="modelEmails.cierre.mensaje | safe"></div>
                    </td>
                </tr>
                <tr>
                    <td [ngStyle]="{'width':'550px','height':'auto','padding':'10px 5px','color':'#fff','text-align':'center', 'background':modelEmails.cierre.tema}">
                        OTIC Sofofa 2018, Servicio de Encuestas
                    </td>
                </tr>
            </body>
        </table>
    </body>

    </html>
</ng-template>
<ng-template #templateEmailEnvioRpta>
    <!doctype html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
    </head>

    <body style="height:auto;width:550px;font-family:'Arial, sans-serif';background: #ffffff !important;">
        <table [ngStyle]="{'background':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">

            <body style="background-color: #FFFFFF !important;">
                <tr>
                    <td [ngStyle]="{'height':'10px','width':'100%','background-color': modelEmails.envioRpta.tema}"></td>
                </tr>
                <tr *ngIf="modelEmails.envioRpta.logo !== null">
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;background: #ffffff;">
                        <span style="font-size:25px;color:blue;"><img [src]="modelEmails.envioRpta.logo"
                                                      style="height:40px;width:auto;text-align:center;"></span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <span style="font-size:25px;color:blue;">{{modelEmails.envioRpta.encabezado}}</span>
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:auto;padding:10px 5px;">
                        <div [innerHTML]="modelEmails.envioRpta.mensaje | safe"></div>
                    </td>
                </tr>
                <tr>
                    <td [ngStyle]="{'width':'550px','height':'auto','padding':'10px 5px','color':'#fff','text-align':'center', 'background':modelEmails.envioRpta.tema}">
                        OTIC Sofofa 2018, Servicio de Encuestas
                    </td>
                </tr>
            </body>
        </table>
    </body>

    </html>
</ng-template>

<ng-template #refModalAddParticipante let-c="close" let-d="dismiss">
    <div class="modal-content">
        <form name="form" #formAddParticipante="ngForm" novalidate (submit)="agregarParticipante(formAddParticipante)">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Agregar Participante</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c()">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class="row mx-auto">
                    <div class="form-group col-xl-12 col-12">
                        <label>
              <span class="obligatorio">*</span>Rut:
            </label>
                        <input type="text" id="rutParticipante" name="rutParticipante" class="form-control" placeholder="Ingrese" [(ngModel)]="modelAddParticipante.rutParticipante" required rutvalidator #rutParticipante="ngModel">
                        <div *ngIf="formAddParticipante.submitted && rutParticipante.invalid" style="color: #dc3545;">
                            <div *ngIf="rutParticipante.errors.required">Debe ingresar rut del participante.</div>
                            <div *ngIf="!rutParticipante.errors.rutvalidator.valid">Rut ingresado es incorrecto.</div>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto">
                    <div class="form-group col-xl-12 col-12">
                        <label>
              <span class="obligatorio">*</span>Nombre Completo:
            </label>
                        <input type="text" name="nombreParticipante" id="nombreParticipante" class="form-control" placeholder="Ingrese" [(ngModel)]="modelAddParticipante.nombreParticipante" required #nombreParticipante="ngModel">
                        <div *ngIf="formAddParticipante.submitted && nombreParticipante.invalid" style="color: #dc3545;">
                            <div *ngIf="nombreParticipante.errors.required">Debe ingresar el nombre del participante.</div>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto">
                    <div class="form-group col-xl-12 col-12">
                        <label>
              <span class="obligatorio">*</span>Email:
            </label>
                        <input type="text" name="email" id="email" class="form-control" placeholder="Ingrese" [(ngModel)]="modelAddParticipante.email" email required #email="ngModel">
                        <div *ngIf="formAddParticipante.submitted && email.invalid" style="color: #dc3545;">
                            <div *ngIf="email.errors.required">Debe ingresar el correo del participante.</div>
                            <div *ngIf="email.errors.email">Correo ingresado no es valido.</div>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto">
                    <div class="form-group col-xl-12 col-12">
                        <label>
              Identificador:
            </label>
                        <input type="text" name="identificador" class="form-control" placeholder="Ingrese" [(ngModel)]="modelAddParticipante.identificador">
                    </div>
                </div>
            </div>
            <div class="form-group col-xl-12 col-12">
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Agregar</button>
                <button type="button" class="btn btn-default" data-dismiss="modal" (click)="c()">Cancelar</button>
            </div>
        </form>
    </div>
</ng-template>