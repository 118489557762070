import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";

import {environment} from "../../environments/environment";

import {EncuestaParticipanteModel} from "../models/encuesta-participante.model";
import {PlantillasEncuestasModel} from "../models/plantillas-encuestas.model";
import {ParticipantesModel} from "../models/participantes.model";

@Injectable({
  providedIn: 'root'
})
export class EncuestasParticipantesService {

  constructor(private http:HttpClient) { }

  save(encuestaParticipante: EncuestaParticipanteModel): Observable<any> {
    const body= JSON.stringify(encuestaParticipante);
    let headers= {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.ENCUESTAS_PARTICIPANTES}/encuestaParticipante`, body, {headers: headers});
  }

  get(idEncuesta, idParticipante): Observable<EncuestaParticipanteModel> {
    return this.http
      .get<EncuestaParticipanteModel>(`${environment.API_DOMAINS.ENCUESTAS_PARTICIPANTES}/encuestaParticipante/${idEncuesta}/${idParticipante}`)
      .pipe(
        map((result: any) => {
          return new EncuestaParticipanteModel(result)
        })
      );
  }

  validIdentifier(idEncuesta, identificador, contrasena,  tipo): Observable<ParticipantesModel> {
    return this.http
      .get<ParticipantesModel>(`${environment.API_DOMAINS.ENCUESTAS_PARTICIPANTES}/validarIdentificador/${idEncuesta}/${identificador}/${contrasena}/${tipo}`)
      .pipe(
        map((result: any) => {
          return new ParticipantesModel(result)
        })
      );
  }


}
