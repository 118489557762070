import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from 'angular-web-storage';

import {UserLoginService} from '../../services/user-login.service';
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private  router: Router,
              private spinner: NgxSpinnerService,
              private localStorageService: LocalStorageService,
              private userLoginService: UserLoginService) {
  }

  ngOnInit() {
    const params = new URLSearchParams(this.router.url.split('#')[1]);
    const access_token = params.get('access_token');
    const id_token = params.get('id_token');
    let user_data = null;
    const validar = params.has('val') ? true : false;
    if (params.has('ud')) {
      user_data = JSON.parse(atob(params.get('ud')));
    }
    if (validar) {
      this.userLoginService.validateToken({access_token: access_token, id_token: id_token})
        .subscribe((result) => {
          console.log(result);
          this.setCookies(id_token, access_token, result, user_data);
          this.router.navigate(['/empresaList']);
        }, error1 => {
          console.log(error1);
          this.router.navigate(['/loginOtic']);
        });
    } else {
      this.setCookies(id_token, access_token, null, user_data);
    }
  }

  setCookies(idToken, accessToken, infoUserCognito, infoUser) {
    let username = infoUserCognito ? infoUserCognito['cognito:username'] : infoUser.rut;
    const keyPrefix = `CognitoIdentityServiceProvider.${environment.CLIENT_ID}`;

    const idTokenKey = `${keyPrefix}.${username}.idToken`;
    const accessTokenKey = `${keyPrefix}.${username}.accessToken`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;

    localStorage.setItem(idTokenKey, idToken);
    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(lastUserKey, username);
    /*  this.localStorageService.set('userData', {
        nombre: infoUser.name,
        email: infoUser.email
      });*/
    if (infoUser) {
      this.localStorageService.set('userData', {
          rut: infoUser.rut,
          nombre: infoUser.nombre,
          empresas: infoUser.empresas,
          rutEmpresa: null,
          roles: infoUser.roles,
          email: infoUser.email,
          rutHolding: infoUser.rutHolding,
          now: +new Date,
          realUser: null
        }
        , 0, 's');
    }

    this.localStorageService.set('idToken', idToken);
    this.localStorageService.set('accessToken', accessToken);
    this.router.navigate(['/empresaList']);
  }

}
