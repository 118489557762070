import {Deserializable} from "./deserializable.interface";

export class ParametroComunModel implements Deserializable<ParametroComunModel>{
	public idDetalle: number;
	public nombreDetalle: string;

	constructor(cursoJson: any){
		this.idDetalle = cursoJson.idDetalle;
		this.nombreDetalle = cursoJson.nombreDetalle;
	}

	deserialize(input:any): ParametroComunModel {
		Object.assign(this, input);

		return this;
	}
}
