import {Deserializable} from "./deserializable.interface";

export class PlantillasCorreosModel implements Deserializable<PlantillasCorreosModel> {
  public idPlantillaCorreo: string;
  public nombrePlantillaCorreo: string;
  public idTipoPlantillaCorreo: string;
  public encabezado: string;
  public asunto: string;
  public tema: string;
  public logo: string;
  public mensaje: string;
  public template: string;
  public fechaCreacion: string;
  public eliminado: boolean;


  constructor(plantillaCorreoJson?: any) {

    this.idPlantillaCorreo = plantillaCorreoJson && plantillaCorreoJson.idPlantillaCorreo || null;
    this.nombrePlantillaCorreo = plantillaCorreoJson && plantillaCorreoJson.nombrePlantillaCorreo || null;
    this.idTipoPlantillaCorreo = plantillaCorreoJson && plantillaCorreoJson.idTipoPlantillaCorreo || null;
    this.encabezado = plantillaCorreoJson && plantillaCorreoJson.encabezado || null;
    this.asunto = plantillaCorreoJson && plantillaCorreoJson.asunto || null;
    this.tema = plantillaCorreoJson && plantillaCorreoJson.tema || null;
    this.logo = plantillaCorreoJson && plantillaCorreoJson.logo || null;
    this.mensaje = plantillaCorreoJson && plantillaCorreoJson.mensaje || null;
    this.template = plantillaCorreoJson && plantillaCorreoJson.template || null;
    this.fechaCreacion = plantillaCorreoJson && plantillaCorreoJson.fechaCreacion || null;
    if (plantillaCorreoJson) {
      this.eliminado = plantillaCorreoJson.eliminado;
    } else {
      this.eliminado = null;
    }
  }

  deserialize(input: any): PlantillasCorreosModel {
    Object.assign(this, input);

    return this;
  }
}
