import {Deserializable} from "./deserializable.interface";

export class CatalogoContenidoCursoListModel implements Deserializable<CatalogoContenidoCursoListModel>{
	public cursosId: number;
	public cursosNombre: string;
	public cursosCodigoSence: number;
	public parametrosNombreTipoCurso: string;
	public actividadesNombre: string;
	public informacionesNombreOtec: string;
	public informacionesCupo: number;
	public informacionesHorasTotales: number;
	public parametrosNombreRegion: string;
	public area: string;
	public especialidad: string;
	public parametrosNombreEstadoCursos: string;
	public cursosEstado: string;

	constructor(objJson?: any){
		this.cursosId = objJson && objJson.cursosId || null;
		this.cursosNombre = objJson && objJson.cursosNombre || null;
		this.cursosCodigoSence = objJson && objJson.cursosCodigoSence || null;
		this.parametrosNombreTipoCurso = objJson && objJson.parametrosNombreTipoCurso || null;
		this.actividadesNombre = objJson && objJson.actividadesNombre || null;
		this.informacionesNombreOtec = objJson && objJson.informacionesNombreOtec || null;
		this.informacionesCupo = objJson && objJson.informacionesCupo || null;
		this.informacionesHorasTotales = objJson && objJson.informacionesHorasTotales || null;
		this.parametrosNombreRegion = objJson && objJson.parametrosNombreRegion || null;
		this.area = objJson && objJson.area || null;
		this.especialidad = objJson && objJson.especialidad || null;
		this.parametrosNombreEstadoCursos = objJson && objJson.parametrosNombreEstadoCursos || null;
		if (objJson) {
			this.cursosEstado = objJson.cursosEstado;
		  } else {
			this.cursosEstado = null;
		  }
	}

	deserialize(input:any): CatalogoContenidoCursoListModel {
		Object.assign(this, input);
		return this;
	}
      
}
