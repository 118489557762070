// Angular
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

//Util
import * as moment from 'moment';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {SimpleCrypt} from 'ngx-simple-crypt';

//Service
import {PlantillaEncuestaService} from "../../services/plantilla-encuesta.service";
import {ExternoService} from "../../services/externo.service";
import {UserLoginService} from "../../services/user-login.service";
import {EncuestaService} from "../../services/encuesta.service";
import {ComunesService} from "../../services/comunes.service";
import {PlantillaCorreoService} from "../../services/plantilla-correo.service";
import {EncuestasParticipantesService} from "../../services/encuestas-participantes.service";
import {UtilService} from "../../services/util.service";

//Shared
import {GLOBALS} from "../../shared/globals.shared";
import {environment} from "../../../environments/environment";

//Models
import {EncuestaParticipanteModel} from "../../models/encuesta-participante.model";
import {RespuestaModel} from "../../models/respuesta.model";
import {EncuestaRespuestaModel} from "../../models/encuesta-respuesta.model";
import {ParticipantesModel} from "../../models/participantes.model";


@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.css']
})
export class EncuestaComponent implements OnInit {
  @ViewChild('refModalIndentificador') modalIdentificadorOtros;
  @ViewChild('refModalRut') modalIdentificadorRut;
  @ViewChild('refModalInformacion') modalInformación;
  private modalREf: NgbModalRef;


  form: FormGroup;
  acceso: any = {
    identificador: '',
    contrasena: ''
  };
  encuestaIniciada: boolean = false;
  encuestaRespondida: boolean = false;
  objectUUID: any = {
    idEncuesta: null,
    idParticipante: null
  };
  textoInformativo: string;

  pathParams: any;
  structureAsnwer: EncuestaParticipanteModel = new EncuestaParticipanteModel();
  answers: RespuestaModel = new RespuestaModel();
  modelEncuesta: EncuestaRespuestaModel = new EncuestaRespuestaModel();
  modelPreguntas: any[];
  itemsByPage: any[];
  pager: any = {
    pages: [],
    totalPages: '',
    currentPage: 1
  };

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private plantillaCorreoService: PlantillaCorreoService,
              private comunesService: ComunesService,
              private externoService: ExternoService,
              private encuestaService: EncuestaService,
              private fb: FormBuilder,
              private encuestaParticipanteService: EncuestasParticipantesService,
              private modalService: NgbModal,
              private utilService: UtilService) {
  }

  ngOnInit() {
    console.log(moment().unix().toString());
    this.form = new FormGroup({});
    this.route.params.subscribe(params => this.pathParams = params);
    if (Number.isNaN(this.pathParams.id)) {
      this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
      //this.router.navigate(['/crud-encuesta']);
    }
    if (this.pathParams.type === undefined) {
      this.getUuidByUrl(this.pathParams.id);
    } else {
      this.decodeUUIDEmail(this.pathParams.id);
    }
  }

  /**
   * Obtiene el uuid según la url corta
   * @param url
   */
  getUuidByUrl(url) {
    this.spinner.show();
    this.encuestaService.getUUID(url)
      .subscribe((result) => {
        this.spinner.hide();
        this.objectUUID = this.decodeUUID(result);
        this.structureAsnwer.idEncuesta = this.objectUUID.idEncuesta;
        this.structureAsnwer.respuestas = [];
        this.loadEncuesta(this.structureAsnwer.idEncuesta);
      }, error1 => {
        this.spinner.hide();
      })
  }

  /**
   * Carga la información de la encuesta por el id
   * @param id
   */
  loadEncuesta(id) {
    this.spinner.show();
    this.encuestaService.getEncuestaResponder(id)
      .subscribe((result: EncuestaRespuestaModel) => {
          console.log('EncuestaComponent: plantilla de encuesta obtenida');
          this.modelEncuesta = result;
          this.modelEncuesta.fechaEnvio = moment.unix(parseInt(result.fechaEnvio)).format('DD/MM/YYYY HH:mm');
          this.modelEncuesta.fechaLimiteRespuesta = this.modelEncuesta.fechaLimiteRespuesta != null ? moment.unix(parseInt(result.fechaLimiteRespuesta)).format('DD/MM/YYYY HH:mm') : null;
          this.modelPreguntas = JSON.parse(result.preguntas);
          this.setCountPage();
          this.spinner.hide();

          if (this.modelEncuesta.eliminado) {
            setTimeout(() => {
              this.textoInformativo = 'Esta encuesta no se encuentra activada para ser respondida.';
              this.modalREf = this.modalService.open(this.modalInformación, {backdrop: 'static'});
            });
          } else if (this.modelEncuesta.estadoEncuesta == 1 && this.modelEncuesta.estadoServicio) {
            setTimeout(() => {
              this.textoInformativo = 'Usted podrá responder está encuesta a partir del ' + this.modelEncuesta.fechaEnvio;
              this.modalREf = this.modalService.open(this.modalInformación, {backdrop: 'static'});
            })
          } else if (this.modelEncuesta.estadoEncuesta == 3) {
            setTimeout(() => {
              this.textoInformativo = 'Esta encuesta ha finalizado, gracias por participar.';
              this.modalREf = this.modalService.open(this.modalInformación, {backdrop: 'static'});
            });
          } else {
            if (!this.structureAsnwer.idParticipante) {
              setTimeout(() => {
                if (this.modelEncuesta.identificadorEncuesta === 'rut' && this.modelEncuesta.estadoServicio) {
                  this.modalREf = this.modalService.open(this.modalIdentificadorRut, {
                    centered: true,
                    backdrop: 'static'
                  });
                } else if (this.modelEncuesta.estadoServicio) {
                  this.modalREf = this.modalService.open(this.modalIdentificadorOtros, {
                    centered: true,
                    backdrop: 'static'
                  });
                }
              })
            } else {
              setTimeout(() => {
                this.obtenerEncuestaParticipante();
              });
            }
          }
        },
        error => {
          this.spinner.hide();
          if (error.status === 404) {
            this.toastr.info('No se encontro la encuesta.', GLOBALS.TOASTER.TITLE_INFO);
          } else {
            console.log(<any>error);
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        })
  }

  /**
   * Obtiene la cantidad de páginas
   */
  setCountPage() {
    this.modelPreguntas.map(item => {
      this.pager.pages.push(item.pagina)
    });
    this.pager.totalPages = this.pager.pages.length;
    this.setItemsByPage(this.pager.currentPage);
  }

  /**
   * Asigna las preguntas por página.
   * @param {number} page
   */
  setItemsByPage(page: number) {
    this.itemsByPage = this.modelPreguntas.filter(d => {
      return d.pagina === page;
    })[0].preguntas;
    if (this.structureAsnwer.respuestas.length > 0) {
      for (let i = 0; i < this.itemsByPage.length; i++) {
        let valor = this.itemsByPage[i].numeroPregunta;
        let filter = this.structureAsnwer.respuestas.filter(function (item) {
          return item.numeroPregunta == valor;
        })[0];
        if (filter) {
          if (this.itemsByPage[i].tipo == 'checkbox' || this.itemsByPage[i].tipo == 'radio') {
            this.itemsByPage[i].options = filter.respuesta;
          } else if (this.itemsByPage[i].tipo == 'matriz') {
            this.itemsByPage[i].estructura = filter.respuesta;
          } else {
            this.itemsByPage[i].value = filter.respuesta;
          }
        } else {
          this.itemsByPage[i].value = '';
        }
      }
    } else {
      for (let i = 0; i < this.itemsByPage.length; i++) {
        this.itemsByPage[i].value = '';
      }
    }

    const formGroup = {};
    this.itemsByPage = Object.keys(this.itemsByPage)
      .map(prop => {
        return Object.assign({}, {key: prop}, this.itemsByPage[prop]);
      });
    //seteo del form
    for (let prop of Object.keys(this.itemsByPage)) {
      formGroup[prop] = new FormControl(this.itemsByPage[prop].value || '', this.mapValidators(this.itemsByPage[prop].requerido));
    }
    this.form = new FormGroup(formGroup);
  }

  private mapValidators(validators) {
    const formValidators = [];
    if (validators) {
      formValidators.push(Validators.required);
    }
    return formValidators;
  }

  /**
   * Cambio de página
   * @param {number} page
   */
  changePage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager.currentPage = page;
    this.setItemsByPage(this.pager.currentPage);
  }

  /**
   * Guarda las respuesta del participante y se marca como respondida
   */
  save() {
    if (this.validarRespuestaObligatorias()) {
      this.spinner.show();
      this.structureAsnwer.encuestaRespondida = true;
      this.structureAsnwer.fechaRespuesta = moment().unix().toString();
      this.encuestaParticipanteService.save(this.structureAsnwer)
        .subscribe((result) => {
          this.utilService.sendEmailFinalizar(this.structureAsnwer.idEncuesta, this.structureAsnwer.idParticipante)
            .subscribe((email) => {
              this.spinner.hide();
              this.toastr.success('Encuesta respondida correctamente');
              this.encuestaRespondida = true;
            }, error => {
              this.spinner.hide();
              if (error.status === 404) {
                this.toastr.info(
                  "Ocurrio un error en el servicio.",
                  GLOBALS.TOASTER.TITLE_INFO
                );
              } else {
                console.log(<any>error);
                this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
              }
            })
        }, error => {
          this.spinner.hide();
          if (error.status === 404) {
            this.toastr.info(
              "Ocurrio un error en el servicio.",
              GLOBALS.TOASTER.TITLE_INFO
            );
          } else {
            console.log(<any>error);
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        });
    } else {
      this.toastr.warning('Existen preguntas requeridas que no han sido respondidas.')
    }

  }

  /**
   * Asigna la respuesta a la pregunta.
   * @param numberQuestion
   * @param event
   */
  setAnswer(question, event, index, indexAlt) {
    this.answers = new RespuestaModel();
    this.answers.numeroPregunta = question.numeroPregunta;
    this.answers.requerido = question.requerido;
    this.answers.respuesta = [];
    switch (question.tipo) {
      case 'select' :
        this.answers.respuesta = event.label;
        break;
      case 'text':
        this.answers.respuesta = event.target.value;
        break;
      case 'textarea':
        this.answers.respuesta = event.target.value;
        break;
      case 'stars':
        if (typeof event !== "string")
          this.answers.respuesta = event;
        else
          this.answers.respuesta = question.value;
        break;
      case 'checkbox':
        question.options[index].value = event.target.checked;
        this.answers.respuesta = question.options;
        break;
      case 'radio':
        for (let i = 0; i < question.options.length; i++) {
          question.options[i].value = false;
        }
        question.options[index].value = event.target.checked;
        this.answers.respuesta = question.options;
        break;
      case 'matriz':
        if (question.tipoCampo == 'radio') {
          for (let i = 0; i < question.estructura[index].alternativas.length; i++) {
            question.estructura[index].alternativas[i].value = false;
          }
        }
        question.estructura[index].alternativas[indexAlt].value = event.target.checked;
        this.answers.respuesta = question.estructura;
        break;
    }
    let value = this.structureAsnwer.respuestas.filter(function (d) {
      return d.numeroPregunta == question.numeroPregunta;
    })[0];
    if (!value) {
      this.structureAsnwer.respuestas.push(this.answers);
    } else {
      let index = this.structureAsnwer.respuestas.indexOf(value);
      this.structureAsnwer.respuestas[index] = this.answers;
    }
    if (this.structureAsnwer.idParticipante && question.tipo == 'stars' && typeof event !== "string"){
      this.saveConstantly();
    }
    else if (this.structureAsnwer.idParticipante && question.tipo != 'stars')
      this.saveConstantly();
  }

  /**
   * Valida si el identificador ingresado corresponde a la encuesta
   */
  validarIdentificador() {
    this.spinner.show();
    this.encuestaParticipanteService.validIdentifier(this.structureAsnwer.idEncuesta, this.acceso.identificador, this.acceso.contrasena, this.modelEncuesta.identificadorEncuesta)
      .subscribe((result) => {
        if (result.recopilador == 'email') {
          this.toastr.warning(
            "Usted está habilitado para responder la encuesta mediante correo electrónico."
          );
          this.spinner.hide();
        } else {
          this.structureAsnwer.idParticipante = result.idParticipante;
          this.obtenerEncuestaParticipante();
          this.modalREf.close();
        }
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          if (this.modelEncuesta.identificadorEncuesta == 'rut')
            this.toastr.warning(
              "El rut y/o contraseña ingresada no corresponde."
            );
          else
            this.toastr.warning(
              "El indentificador y/o contraseña ingresada no corresponde."
            );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });

  }

  /**
   * Obtiene las respuestas del parcitipantes
   */
  obtenerEncuestaParticipante() {
    this.spinner.show();
    this.encuestaParticipanteService.get(this.structureAsnwer.idEncuesta, this.structureAsnwer.idParticipante)
      .subscribe((result) => {
        this.structureAsnwer = result;
        this.structureAsnwer.respuestas = result.respuestas ? JSON.parse(result.respuestas) : [];

        if (!this.modelEncuesta.permiteMasRespuestas && this.structureAsnwer.encuestaRespondida)
          this.encuestaRespondida = true;
        else
          this.setItemsByPage(1);


        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.saveConstantly();
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  /**
   * Guarda cada uno de las respuesta a medida que van siendo respondidas
   * Dejando la repsuesta como incompleta
   */
  saveConstantly() {
    if (this.modelEncuesta.estadoServicio) {
      this.encuestaParticipanteService.save(this.structureAsnwer)
        .subscribe((result) => {
        }, error => {
          console.log(<any>error);
        });
    }
  }

  /**
   * Valida si las respuestas respondidas ha ingresado las requeridas
   */
  validarRespuestaObligatorias() {
    let valid = true;
    let requeridos = [];
    for (let i = 0; i < this.modelPreguntas.length; i++) {
      for (let j = 0; j < this.modelPreguntas[i].preguntas.length; j++) {
        if (this.modelPreguntas[i].preguntas[j].requerido)
          requeridos.push(this.modelPreguntas[i].preguntas[j]);
      }
    }
    for (let i = 0; i < requeridos.length; i++) {
      let respondida = this.structureAsnwer.respuestas.filter((item) => {
        return item.numeroPregunta == requeridos[i].numeroPregunta
      });
      if (respondida.length) {
        valid = respondida[0].respuesta === "" ? false : true;
      }
      else {
        valid = false;
      }
      if (!valid)
        i = requeridos.length;
    }
    return valid;
  }

  /**
   * Docodifica el hash uuid con la información de la encuesta y participante
   * @param uuid
   * @returns {Object}
   */
  decodeUUID(uuid) {
    return SimpleCrypt.decodeDefault(environment.CRYPT_KEY, uuid);
  }

  /**
   * Decodifica el uuid que es enviado al participante por email
   * @param uuid
   */
  decodeUUIDEmail(uuid) {
    this.spinner.show();
    this.utilService.desencryptUUID(uuid)
      .subscribe((result) => {
        this.spinner.hide();
        this.structureAsnwer.idEncuesta = result.idEncuesta;
        this.structureAsnwer.idParticipante = result.idParticipante;
        this.structureAsnwer.respuestas = [];
        this.loadEncuesta(this.structureAsnwer.idEncuesta);
      }, error1 => {
        this.spinner.hide();
        console.log(error1);
      })

  }

  /**
   * Se comienza a responder la encuesta.
   */
  startSurvey(){
    this.encuestaIniciada = true;
  }
}
