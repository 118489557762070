import {Deserializable} from "./deserializable.interface";

export class EstadosPlantillasEncuestasModel implements Deserializable<EstadosPlantillasEncuestasModel> {
  public idEstadoPlantillaEncuesta: number;
  public nombreEstadoPlantillaEncuesta: string;
  public eliminado: boolean;

  constructor(objJson?: any) {
    this.idEstadoPlantillaEncuesta = objJson.idEstadoPlantillaEncuesta;
    this.nombreEstadoPlantillaEncuesta = objJson.nombreEstadoPlantillaEncuesta;
    this.eliminado = objJson.eliminado;
  }

  deserialize(input: any): EstadosPlantillasEncuestasModel {
    Object.assign(this, input);
    return this;
  }
}
