// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  VERSION : require('../../package.json').version,

  LINK_BASE_URL_RESPONDER: 'http://encuestas.0s.cl/responder/',
  CRYPT_KEY: 's0f0f8_3nc',

  //COGNITO
  REGION: 'us-east-1',
  IDENTITY_POOL_ID: '',
  COGNITO_IDP_ENDPOINT : '',
  USER_POOL_ID: 'us-east-1_FKBbyRPjB',
  CLIENT_ID: '6lb4rvvdfg0nsjf0empdduf6ks',
  cognito_identity_endpoint : '',
  sts_endpoint : '',

  API_DOMAINS: {
    // PLANTILLAS_CORREOS : 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/plantillasCorreos',
    // PLANTILLAS_ENCUESTAS : 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/plantillasEncuestas',
    // COMUNES: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/comunes',
    // ENCUESTAS: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/encuestas',
    // ENCUESTAS_PARTICIPANTES: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/encuestasParticipantes',
    // ENCUESTAS_RESULTADOS: 'https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/encuestas/resultado',
    // EMAIL: 'https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/email',
    // DOCUMENTOS: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/documentos',
    // REPORTES: ' https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/reportes',
    // UTIL: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/util',
    EXT_EMPRESAS: 'https://139fmbjjnf.execute-api.us-east-1.amazonaws.com/dev/usuariosEmpresas',
    // EXT_GESTION_CURSO: 'https://5eno2jxlqg.execute-api.us-east-1.amazonaws.com/dev/gestionCursos',
    EXT_CATALOGO_CONTENIDOS: 'https://ho08wyzgye.execute-api.us-east-1.amazonaws.com/dev/catalogoContenidos',
    // EXT_COMUNES: 'https://u0f7ep7c46.execute-api.us-east-1.amazonaws.com/dev/comunes'

    PLANTILLAS_CORREOS : 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/plantillasCorreos',
    PLANTILLAS_ENCUESTAS : 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/plantillasEncuestas',
    COMUNES: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/comunes',
    ENCUESTAS: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/encuestas',
    ENCUESTAS_PARTICIPANTES: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/encuestasParticipantes',
    ENCUESTAS_RESULTADOS: 'https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/encuestas/resultado',
    EMAIL: 'https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/email',
    DOCUMENTOS: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/documentos',
    REPORTES: ' https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/reportes',
    USUARIOS: ' https://77x4ng04z9.execute-api.us-east-1.amazonaws.com/dev/usuarios',
    UTIL: 'https://nzhujc9uia.execute-api.us-east-1.amazonaws.com/dev/util',
    // EXT_EMPRESAS: 'https://139fmbjjnf.execute-api.us-east-1.amazonaws.com/dev/usuariosEmpresas',
    // EXT_GESTION_CURSO: 'https://5eno2jxlqg.execute-api.us-east-1.amazonaws.com/dev/gestionCursos',
    // EXT_CATALOGO_CONTENIDOS: 'https://ho08wyzgye.execute-api.us-east-1.amazonaws.com/dev/catalogoContenidos',
    EXT_COMUNES: 'https://2mogaj1atl.execute-api.us-east-1.amazonaws.com/dev/comunes'
  },
  S3_URL: 'https://s3.amazonaws.com/encuestas.0s.cl/',
  S3_PREFIX_UPLOAD_IMAGE: 'UPLOAD_IMAGES/',

  URL_SIGNOUT: 'https://sistemasotic.0s.cl/logout',
  URL_AUTH: 'https://encuestas.0s.cl/auth'


};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
