import {Injectable} from "@angular/core";

import * as AWS from "aws-sdk/global";
import * as STS from "aws-sdk/clients/sts";

import {environment} from "../../environments/environment";

import {CognitoCallback, CognitoUtilService, LoggedInCallback} from "./cognito.service";
import {AuthenticationDetails, CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";

import {LocalStorageService} from "angular-web-storage";
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {
    console.log('En authenticateUser onSuccess callback');
    console.log(session);

    AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

    let clientParams: any = {};
    if (environment.sts_endpoint) {
      clientParams.endpoint = environment.sts_endpoint;
    }

    this.localStorageService.set('idToken', session.getIdToken().getJwtToken());

    let sts = new STS(clientParams);
    sts.getCallerIdentity(function (err, data) {
      console.log('UserLoginService: credenciales AWS exitosamente seteadas');
      callback.cognitoCallback(null, session);
    });
  }

  private onLoginError = (callback: CognitoCallback, err) => {
    callback.cognitoCallback(err.message, null);
  }

  constructor(public cognitoUtil: CognitoUtilService, private localStorageService: LocalStorageService, private http: HttpClient) {
  }

  authenticate(username: string, password: string, callback: CognitoCallback) {
    console.log('UserLoginService: iniciando autenticacion');

    let authenticationData = {
      Username: username,
      Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);

    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    console.log('UserLoginService: Parametros seteados...Autenticando el usuario');
    let cognitoUser = new CognitoUser(userData);
    console.log('UserLoginService: config es ' + AWS.config);

    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback('El usuario necesita setear su password.', null),
      onSuccess: result => this.onLoginSuccess(callback, result),
      onFailure: err => this.onLoginError(callback, err),
      mfaRequired: (challengeName, challengeParameters) => {
        callback.handleMFAStep(challengeName, challengeParameters, (confirmationCode: string) => {
          cognitoUser.sendMFACode(confirmationCode, {
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
          });
        });
      }
    });
  }

  forgotPassword(username: string, callback: CognitoCallback) {
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: function () {

      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
    let userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  logout() {
    console.log('UserLoginService: Cerrando sesion');
    this.cognitoUtil.getCurrentUser().signOut();
    window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
  }

  isAuthenticated(callback: LoggedInCallback) {
    const thisLocalStorage = this.localStorageService;
    if (callback == null)
      throw('UserLoginService: Callback en isAuthenticated() no puede ser null');

    let cognitoUser = this.cognitoUtil.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log('UserLoginService: No se pudo recuperar la sesion: ' + err, err.stack);
          callback.isLoggedIn(err, false);
        }
        else {
          callback.isLoggedIn(err, session.isValid());
          /*cognitoUser.refreshSession(session.getRefreshToken(), function (err, data) {
            if (err) {
              console.log('UserLoginService: Resultado de validacion de sesion es: ' + false);
              callback.isLoggedIn(err, false);
            } else {
              thisLocalStorage.set('idToken', session.getIdToken().getJwtToken());
              console.log('UserLoginService: Resultado de validacion de sesion es: ' + session.isValid());
              callback.isLoggedIn(err, session.isValid());
            }
          });*/
        }
      });
    } else {
      console.log('UserLoginService: no se pudo recuperar el CurrentUser');
      callback.isLoggedIn('No se pudo recuperar el CurrentUser', false);
    }
  }

  validateToken(token: any): Observable<any> {
    const body = JSON.stringify(token);
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.USUARIOS}/authCognito`, body, {headers: headers});
  }

}
