import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';


import {EmpresaListModel} from '../models/empresa-list.model';
import {CatalogoContenidoCursoListModel} from '../models/catalogo-contenido-curso-list.model';
import {ParametroComunModel} from '../models/parametro-comun.model';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ExternoService {

  constructor(private http: HttpClient) {
  }

  listEmpresas(): Observable<EmpresaListModel[]> {
    return this.http
      .get<EmpresaListModel[]>(`${environment.API_DOMAINS.EXT_EMPRESAS}/empresa/info/holding/93834000`)
      .pipe(
        map((result: any) => {
          return result.map((plantilla: any) => {
            return new EmpresaListModel(plantilla);
          });
        })
      );
  }

  listCursos(): Observable<CatalogoContenidoCursoListModel[]> {
    return this.http
      .get<CatalogoContenidoCursoListModel[]>(`${environment.API_DOMAINS.EXT_CATALOGO_CONTENIDOS}/curso/listar/93834000`)
      .pipe(
        map((result: any) => {
          return result.map((plantilla: any) => {
            return new CatalogoContenidoCursoListModel(plantilla);
          });
        })
      );
  }

  listParametroComun(tipo: number): Observable<ParametroComunModel[]> {
    return this.http
      .get<ParametroComunModel[]>(`${environment.API_DOMAINS.EXT_COMUNES}/parametrosDetalle/lista/${tipo}`)
      .pipe(
        map((result: any) => {
          return result.map((detalle: any) => {
            return new ParametroComunModel(detalle);
          });
        })
      )
  }
}
