<!--<app-header></app-header>-->
<div class="container-fluid margin-bottom-10"
     style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
  <section>
    <div style=" margin-top: -55px;">
      <div class="col-xl-12 col-12 padding-inside">
        <div class="col-xl-12 col-12" [ngStyle]="{'background': modelEncuesta.tema, 'height': '30px'}">
        </div>
        <div class="col-xl-12 col-12 card" [hidden]=" ((structureAsnwer.idParticipante && modelEncuesta.estadoEncuesta == 1) || (!structureAsnwer.idParticipante && modelEncuesta.estadoEncuesta == 1)) && modelEncuesta.estadoServicio">
          <form [formGroup]="form" novalidate>
            <div class="col-xl-10 mx-auto col-12 margin-top-20">
              <!-- Imagen -->
              <div class="row-fluid text-center mx-auto">
                <img [src]="modelEncuesta.logo" height="100" width="200" alt="">
              </div>
              <!-- Nombre de la encuesta -->
              <div class="row-fluid text-center mx-auto">
                <h1 class="text-center">{{modelEncuesta.nombreEncuesta}}</h1>
              </div>
              <!-- Fechas de la encuesta -->
              <div class="row-fluid text-center mx-auto">
                <h2 class="text-center">Curso: <span style="font-size:22px!important;" class="bold">{{modelEncuesta.curso}}</span>
                </h2>
                <h2 class="text-center" *ngIf="!encuestaRespondida">Fecha Envío Encuestas: <span
                  style="font-size:22px!important;" class="bold">{{modelEncuesta.fechaEnvio}}</span>
                </h2>
                <h2 class="text-center obligatorio"
                    *ngIf="!encuestaRespondida && modelEncuesta.fechaLimiteRespuesta != null">Fecha Límite Respuesta:
                  <span style="font-size:22px!important;"
                        class="bold">{{modelEncuesta.fechaLimiteRespuesta}}</span>
                </h2>
              </div>
              <!-- Instrucciones de la encuesta -->
              <div *ngIf="!encuestaRespondida && !encuestaIniciada" class="row-fluid text-center mx-auto">
                <p>Instrucciones: {{modelEncuesta.instruccionesOnline}}</p>
              </div>

              <div *ngIf="!encuestaRespondida && !encuestaIniciada" class="row-fluid mx-auto">
                <button class="btn btn-primary pull-right" (click)="startSurvey()">Comenzar</button>
              </div>

              <div *ngIf="!encuestaRespondida && encuestaIniciada" class="row-fluid text-center mx-auto margin-top-20 text-center">
                <h2>Preguntas de la Encuesta:</h2>
              </div>
              <div *ngIf="!encuestaRespondida && encuestaIniciada" class="row-fluid mx-auto margin-top-20">
                <div class="tab-content">
                  <div class="form-group col-xl-6 col-12 mx-auto" *ngFor="let pregunta of itemsByPage">
                    <label class="margin-top-20" *ngIf="pregunta.tipo != 'title'">
                      <span class="obligatorio" *ngIf="pregunta.requerido">*</span>{{pregunta.nombre}}:
                    </label>
                    <div [ngSwitch]="pregunta.tipo">
                      <div class="form-group col-xl-12 col-xs-12">
                        <input *ngSwitchCase="'text'" class="form-control"
                               [id]="pregunta.numeroPregunta"
                               [formControlName]="pregunta.key"
                               [type]="pregunta.tipo"
                               (blur)="setAnswer(pregunta, $event, 0, 0)">

                        <textarea *ngSwitchCase="'textarea'" class="form-control"
                                  [id]="pregunta.numeroPregunta"
                                  [formControlName]="pregunta.key"
                                  (blur)="setAnswer(pregunta, $event, 0, 0)"></textarea>

                        <input *ngSwitchCase="'number'"
                               [id]="pregunta.key"
                               [formControlName]="pregunta.key"
                               [type]="pregunta.tipo"
                               (blur)="setAnswer(pregunta, $event, 0, 0)">
                      </div>
                      <div *ngSwitchCase="'radio'">

                        <!--<div *ngFor="let option of pregunta.options , let indice = index">-->
                          <!--<label for="{{pregunta.key}}">-->
                            <!--<input id="{{pregunta.key}}" [value]='option.value' type="radio"-->
                            <!--name="{{pregunta.key}}"-->
                            <!--&gt;-->
                            <!--{{option.label}}-->
                          <!--</label>-->
                        <!--</div>-->

                        <label *ngFor="let option of pregunta.options , let indice = index">
                          <input
                            type="radio"
                            [id]="pregunta.numeroPregunta"
                            name="{{pregunta.key}}"
                            value="{{option.value}}"
                            [checked]="option.value"
                            (change)="setAnswer(pregunta, $event, indice,0)"> {{option.label}}
                        </label>
                      </div>
                      <div *ngSwitchCase="'checkbox'">
                        <label *ngFor="let option of pregunta.options, let indice = index">
                          <input
                            type="checkbox"
                            [formControlName]="pregunta.key"
                            [name]="pregunta.key"
                            [checked]="option.value"
                            [value]="option.value"
                            (change)="setAnswer(pregunta, $event, indice, 0)"> {{option.label}}
                        </label>
                      </div>
                      <div *ngSwitchCase="'select'">
                        <ng-select id="tipoPlantilla" name="tipoPlantilla"
                                   [formControlName]="pregunta.key"
                                   [items]="pregunta.options"
                                   bindLabel="label"
                                   bindValue="label"
                                   (change)="setAnswer(pregunta, $event, 0)">
                        </ng-select>
                      </div>
                      <div *ngSwitchCase="'stars'">
                        <bar-rating name="rating" [max]="pregunta.valor" [(ngModel)]="pregunta.value"
                                    [formControlName]="pregunta.key"
                                    (rateChange)="setAnswer(pregunta, $event, 0)"></bar-rating>
                      </div>
                      <div *ngSwitchCase="'matriz'">
                        <table class="table table-sm">
                          <tr>
                            <td></td>
                            <td *ngFor="let alternativa of pregunta.alternativas">
                              {{alternativa.label}}
                            </td>
                          </tr>
                          <tr *ngFor="let estructura of pregunta.estructura, let indexPregunta = index">
                            <td>
                              {{estructura.titulo}}
                            </td>

                            <td *ngFor="let alternativa of estructura.alternativas , let indexAlternativa = index">
                              <input *ngIf="pregunta.tipoCampo === 'checkbox'" type="checkbox"
                                     name="{{pregunta.numeroPregunta}}_{{indexPregunta}}"
                                     [value]="alternativa.value"
                                     [checked]="alternativa.value"
                                     (change)="setAnswer(pregunta, $event, indexPregunta, indexAlternativa)">
                              <input *ngIf="pregunta.tipoCampo === 'radio'" type="radio"
                                     name="{{pregunta.numeroPregunta}}_{{indexPregunta}}"
                                     [value]="alternativa.value"
                                     [checked]="alternativa.value"
                                     (change)="setAnswer(pregunta, $event, indexPregunta, indexAlternativa)">
                            </td>

                          </tr>
                        </table>
                      </div>
                      <div *ngSwitchCase="'title'">
                        <h2>{{pregunta.nombre}}</h2>
                      </div>
                    </div>
                    <div class="error"
                         *ngIf="form.get(pregunta.key).invalid && (form.get(pregunta.key).dirty || form.get(pregunta.key).touched)">
                      <div *ngIf="form.get(pregunta.key).errors.required">
                        {{ pregunta.nombre }} es requerido.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Mensaje despedida-->
              <div *ngIf="encuestaRespondida" class="row-fluid mx-auto margin-top-20 text-center"
                   style="min-height: 500px;">
                <h1 class="margin-top-20" [innerHTML]="modelEncuesta.textoDespedida"></h1>
              </div>



            </div>
          </form>
          <div *ngIf="!encuestaRespondida && encuestaIniciada" class="row-fluid text-center mx-auto">
            <div class="col-12 mx-auto margin-top-20">
              <!-- pager -->
              <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a class="page-link" (click)="changePage(1)">Primero</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.currentPage - 1)">Anterior</a>
                </li>
                <li *ngFor="let page of pager.pages" class="page-item"
                    [ngClass]="{active:pager.currentPage === page}">
                  <a class="page-link" (click)="changePage(page)">{{page}}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.currentPage + 1)">Siguiente</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.totalPages)">Último</a>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="pager.currentPage === pager.totalPages && !encuestaRespondida && modelEncuesta.estadoServicio && encuestaIniciada"
               class="col-12 text-right">
            <button type="submit" class="btn btn-primary" (click)="save()">Guardar Respuestas</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-footer></app-footer>

<ng-template #refModalIndentificador let-c="close" let-d="dismiss">
  <div class="modal-content">
    <form #formIdentificador="ngForm" (ngSubmit)="validarIdentificador()" novalidate>
      <div class="modal-header">
        <h5 class="modal-title">Identificador</h5>
      </div>
      <div class="modal-body">
        <div class="row-fluid mx-auto margin-top-20">
          <p>Ingrese su identificador:</p>
          <input type="text" id="identificador" name="identificador"
                 class="form-control"
                 [(ngModel)]="acceso.identificador" #otro="ngModel" required>
          <span
            *ngIf="otro.invalid && (otro.dirty || otro.touched )"
            class="form-error">
                          <span *ngIf="formIdentificador.hasError('required', 'identificador')">Debe ingresar su identificador.</span>
                        </span>

        </div>
        <div class="row-fluid mx-auto margin-top-20">
          <p>Ingrese su contraseña:</p>
          <input type="password" id="contrasena" name="contrasena"
                 class="form-control"
                 [(ngModel)]="acceso.contrasena" #contrasena="ngModel" required>
          <span *ngIf="contrasena.invalid && (contrasena.dirty || contrasena.touched )" class="form-error">
          <span *ngIf="formIdentificador.hasError('required', 'contrasena')">Debe ingresar un contraseña.</span>
          </span>
        </div>
      </div>
      <div class="form-group col-xl-12 col-12">
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!formIdentificador.form.valid">Aceptar</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalRut let-c="close" let-d="dismiss">
  <div class="modal-content">
    <form #formIdentificador="ngForm" (ngSubmit)="validarIdentificador()" novalidate>
      <div class="modal-header">
        <h5 class="modal-title">Identificador</h5>
      </div>
      <div class="modal-body">
        <div class="row-fluid mx-auto margin-top-20">
          <p>Ingrese su rut:</p>
          <input type="text" id="rut" name="rut"
                 class="form-control"
                 [(ngModel)]="acceso.identificador" #rut="ngModel" rutvalidator required>
          <span *ngIf="rut.invalid && (rut.dirty || rut.touched )" class="form-error">
          <span *ngIf="formIdentificador.hasError('required', 'rut')">Debe ingresar un RUT.</span>
            <br/>
          <span *ngIf="formIdentificador.hasError('rutvalidator', 'rut')">El RUT ingresado es incorrecto.</span>
        </span>
        </div>
        <div class="row-fluid mx-auto margin-top-20">
          <p>Ingrese su contraseña:</p>
          <input type="password" id="contrasena" name="contrasena"
                 class="form-control"
                 [(ngModel)]="acceso.contrasena" #contrasena="ngModel" required>
          <span *ngIf="contrasena.invalid && (contrasena.dirty || contrasena.touched )" class="form-error">
          <span *ngIf="formIdentificador.hasError('required', 'contrasena')">Debe ingresar un contraseña.</span>
          </span>
        </div>
      </div>
      <div class="form-group col-xl-12 col-12">
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="!formIdentificador.form.valid">Aceptar</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #refModalInformacion let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Información</h5>
    </div>
    <div class="modal-body">
      <h5>{{textoInformativo}}</h5>
    </div>
    <div class="form-group col-xl-12 col-12">
    </div>
  </div>
</ng-template>
