import { Injectable } from '@angular/core';

import { environment } from "../../environments/environment";

import { CognitoUserPool } from "amazon-cognito-identity-js";

import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";

export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;

    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
}

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable({
  providedIn: 'root'
})
export class CognitoUtilService {
  public static REGION = environment.REGION;

  public static IDENTITY_POOL_ID = environment.IDENTITY_POOL_ID;
  public static USER_POOL_ID = environment.USER_POOL_ID;
  public static CLIENT_ID = environment.CLIENT_ID;

  public static POOL_DATA: any = {
    UserPoolId: CognitoUtilService.USER_POOL_ID,
    ClientId: CognitoUtilService.CLIENT_ID
  };

  public cognitoCreds: AWS.CognitoIdentityCredentials;

  getUserPool() {
    if (environment.IDENTITY_POOL_ID) {
        CognitoUtilService.POOL_DATA.endpoint = environment.IDENTITY_POOL_ID;
    }

    return new CognitoUserPool(CognitoUtilService.POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  setCognitoCreds(creds: AWS.CognitoIdentityCredentials) {
    this.cognitoCreds = creds;
  }

  getCognitoCreds() {
    return this.cognitoCreds;
  }

  // This method takes in a raw jwtToken and uses the global AWS config options to build a
  // CognitoIdentityCredentials object and store it for us. It also returns the object to the caller
  // to avoid unnecessary calls to setCognitoCreds.
  buildCognitoCreds(idTokenJwt: string) {
    let url = 'cognito-idp.' + CognitoUtilService.REGION.toLowerCase() + '.amazonaws.com/' + CognitoUtilService.USER_POOL_ID;
    if (environment.IDENTITY_POOL_ID) {
      url = environment.COGNITO_IDP_ENDPOINT + '/' + CognitoUtilService.USER_POOL_ID;
    }

    let logins: CognitoIdentity.LoginsMap = {};
    logins[url] = idTokenJwt;
    let params = {
      IdentityPoolId: CognitoUtilService.IDENTITY_POOL_ID, /* required */
      Logins: logins
    };

    let serviceConfigs = <awsservice.ServiceConfigurationOptions>{};
    if (environment.cognito_identity_endpoint) {
      serviceConfigs.endpoint = environment.cognito_identity_endpoint;
    }

    let creds = new AWS.CognitoIdentityCredentials(params, serviceConfigs);
    this.setCognitoCreds(creds);

    return creds;
  }

  getCognitoIdentity(): string {
    return this.cognitoCreds.identityId;
  }

  getAccessToken(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getAccessToken is null...returning");
    }

    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
              callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    }
    else {
      callback.callbackWithParam(null);
    }
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw('CognitoUtil: callback en getIdToken es null...retornando');
    }

    if (this.getCurrentUser() != null){
      this.getCurrentUser().getSession((err, session) => {
        if (err) {
          console.log('CognitoUtil: No se pudo obtener las credenciales:' + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            console.log('CognitoUtil: Se obtuvo el token sin embargo la sesion es inválida');
          }
        }
      });
    }else{
      callback.callbackWithParam(null);
    }
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw("CognitoUtil: callback in getRefreshToken is null...returning");
    }
    if (this.getCurrentUser() != null)
      this.getCurrentUser().getSession(function (err, session) {
        if (err) {
          console.log("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        }
        else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    else
      callback.callbackWithParam(null);
  }

  refresh(): void {
    this.getCurrentUser().getSession(function (err, session) {
      if (err) {
        console.log("CognitoUtil: Can't set the credentials:" + err);
      }
      else {
        if (session.isValid()) {
          console.log("CognitoUtil: refreshed successfully");
        } else {
          console.log("CognitoUtil: refreshed but session is still not valid");
        }
      }
    });
  }
}
