<app-header></app-header>
<div class="container-fluid margin-bottom-10"
     style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
  <section>
    <div class="container-fluid">
      <div class="row">
        <div class="col margin-top-20">
          <i>Bienvenido</i>
        </div>
        <div class="col text-right margin-top-20">
          Encuestas / Respuestas de la Encuesta
        </div>
      </div>
    </div>

    <div class="row-fluid">
      <div class="col-xl-12 col-12">
        <h1 class="margin-top-20">Respuestas de la Encuesta</h1>
      </div>
    </div>

    <div class="col-xl-12 col-12 padding-inside">
      <div class="col-xl-12 col-12 card">
        <div class="col-xl-8 mx-auto col-12 margin-top-20">
          <div class="row-fluid">
            <div class="col-xl-12 col-12" data-toggle="collapse" href="#resumenEncuesta">
              <h2>Resultados Globales Encuesta
              </h2>
            </div>
          </div>
          <div class="form-row">
            <div class="col-xl-6 col-12">
              <h2 class="margin-left-15">Nombre Encuesta: <b style="font-size:21px;">{{modelRespuestaCabecera.nombreEncuesta}}</b>
              </h2>
            </div>
            <div class="col-xl-6 col-12">
              <h2>Empresa: <b style="font-size:21px;">{{modelRespuestaCabecera.nombreEmpresa}}</b></h2>
            </div>
          </div>

          <div class="row-fluid">
            <div class="col-xl-12 col-12 form-row margin-top-20">
              <div class="form-group col-xl-3 col-md-6 col-12">
                <h3>Estado Encuesta: <p class="bold">{{modelRespuestaCabecera.estadoEncuesta}}</p></h3>
              </div>

              <div class="form-group col-xl-3 col-md-6 col-12">
                <h3>Tiempo Restante para responder: <p id="demo" class="bold">
                  {{modelRespuestaCabecera.tiempoRestante}}</p></h3>
              </div>
              <div class="form-group col-xl-3 col-md-6 col-12">
                <h3>Fecha Envío Invitaciones: <p class="bold"><b>{{modelRespuestaCabecera.fechaEnvio}}</b></p></h3>
              </div>
              <div class="form-group col-xl-3  col-md-6 col-12">
                <h3>Fecha máxima Respuesta: <p class="bold"><b>{{modelRespuestaCabecera.fechaLimiteRespuesta}}</b></p>
                </h3>
              </div>
            </div>
            <div class="col-xl-12 col-12 form-row">

              <div class="form-group col-xl-3 col-md-6 col-12">
                <h3>Preguntas Obligatorias: <p class="bold">
                  {{modelRespuestaCabecera.cantPregObligatorias}}/{{modelRespuestaCabecera.cantTotalPreguntas}}</p></h3>
              </div>
              <div class="form-group col-xl-3 col-md-6 col-12">
                <h3>Cantidad de Participantes: <p class="bold">{{modelRespuestaCabecera.cantidadParticipantes}}</p></h3>
              </div>
            </div>
          </div>

          <div class="row-fluid">
            <div class="col-xl-12 col-12 form-group">
              <label>Respuestas y Estados:</label>
              <div class="progress">
                <div class="progress-bar progress-bar-success" role="progressbar"
                     [ngStyle]="{'width': getPorcentajeEstilo('respondidas') + '%'}"
                     data-toggle="tooltip" data-title="Encuestas Respondidas: {{modelRespuestaCabecera.respondidas}}">
                  R: {{modelRespuestaCabecera.respondidas}}
                </div>
                <div class="progress-bar progress-bar-warning" role="progressbar"
                     [ngStyle]="{'width': getPorcentajeEstilo('incompletas') + '%'}"
                     data-toggle="tooltip"
                     data-title="Encuestas con Respuestas Incompletas: {{modelRespuestaCabecera.incompletas}}">
                  RI: {{modelRespuestaCabecera.incompletas}}
                </div>
                <div class="progress-bar progress-bar-danger" role="progressbar"
                     [ngStyle]="{'width': getPorcentajeEstilo('norespondidas') + '%'}"
                     data-toggle="tooltip"
                     data-title="Encuestas sin Responder: {{modelRespuestaCabecera.norespondidas}}">
                  SR: {{modelRespuestaCabecera.norespondidas}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12 mx-auto col-12 margin-top-20 card">
            <div class="col-xl-12 mx-auto col-12 margin-top-20">
              <div class="row">
                <div class="col-xl-12 col-12" (click)="resumenParticipantes()">
                  <h2>Listado Participantes
                    <span class="float-right text-right">
                      <i class="material-icons">
                        more_vert
                        </i>
                    </span>
                  </h2>
                </div>
              </div>

              <div id="resumenEncuesta" class="collapse" [ngClass]="{'show' : isActiveParticipantes}">
                <div class="form-row">
                  <div class="col-xl-12 col-12 text-right">
                    <button (click)="exportarParticipantes()" class="btn btn-primary float-right">Exportar a Excel
                    </button>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-xl-8 col-md-12 col col-12 table-responsive">
                    <ngx-datatable
                      #table
                      class="bootstrap table table-hover table-striped"
                      [columnMode]="'flex'"
                      [limit]="10"
                      [headerHeight]="50"
                      [footerHeight]="50"
                      [rowHeight]="'auto'"
                      [rows]='modelRespuestaParticipantes'>
                      <ngx-datatable-column name="Nombre Participante" [flexGrow]="0.4">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{row.nombreParticipante}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Recopilador" [flexGrow]="0.3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          <div *ngIf="row.recopilador === 'email'">EMAIL</div>
                          <div *ngIf="row.recopilador === 'url'">URL</div>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Estado Respuesta" [flexGrow]="0.3">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                          {{row.estadoRespuestas}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="% Respuestas" [flexGrow]="0.3">
                        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                          <span
                            *ngIf="row.porcentajeRespuesta === 0 && (modelRespuestaCabecera.cantPregObligatorias > 0 || modelRespuestaCabecera.cantPregObligatorias === 0) && row.estadoRespuestas !== 'Respondida'"
                            class="badge badge-danger">0%</span>
                          <span
                            *ngIf="row.porcentajeRespuesta < 100 && row.porcentajeRespuesta > 0 && modelRespuestaCabecera.cantPregObligatorias > 0"
                            class="badge badge-warning">{{row.porcentajeRespuesta}}%</span>
                          <span
                            *ngIf="row.porcentajeRespuesta === 100 && modelRespuestaCabecera.cantPregObligatorias > 0 && row.estadoRespuestas !== 'Respondida'"
                            class="badge badge-warning">{{row.porcentajeRespuesta}}%</span>
                          <span
                            *ngIf="modelRespuestaCabecera.cantPregObligatorias > 0 && row.estadoRespuestas === 'Respondida'"
                            class="badge badge-success">100%</span>
                          <span
                            *ngIf="modelRespuestaCabecera.cantPregObligatorias === 0 && row.estadoRespuestas === 'Respondida'"
                            class="badge badge-success">100%</span>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                  <div class="col-xl-4 col-md-12 col-12 mx-auto text-center margin-top-10">
                    <chart [options]="options"></chart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--agregar detalle respuestas y respuestas globales, como 2 tabs-->

          <div class="col-xl-12 mx-auto col-12 margin-top-20 card">
            <div class="col-xl-12 mx-auto col-12 margin-top-20">
              <div class="row">
                <div class="col-xl-12 col-12" (click)="resumenPreguntas()">
                  <h2>Detalle Respuestas
                    <span class="float-right text-right">
                      <i class="material-icons">
                        more_vert
                        </i>
                    </span>
                  </h2>
                </div>
              </div>
              <div id="detalleRespuestas" class="collapse" [ngClass]="{'show' : isActivePreguntas}">
                <div class="col-xl-12 col-12 form-group">
                  <label>Cantidad Encuestas Respondidas:</label>

                  <button class="btn btn-primary" (click)="exportar()">Exportar</button>
                  <button class="btn btn-primary" (click)="exportarPpt()">Exportar PPT</button>

                  <div class="col-xl-12 col-12 card" *ngFor="let preguntas of modelRespuestaPreguntas">
                    <div class="col-xl-12 col-12 padding-5">
                      <div class="form-row">
                        <div class="col-12"
                             [ngClass]="{ 'col-xl-12': preguntas.tipoPregunta === 'text' || preguntas.tipoPregunta === 'textarea', 'col-xl-6': preguntas.tipoPregunta !== 'text' || preguntas.tipoPregunta !== 'textarea' }">
                          <h3>Pregunta {{preguntas.numeroPregunta}}: <span
                            class="bold margin-left-15">({{preguntas.cantidadParticipantes}} Respuestas)</span></h3>
                          <p>{{preguntas.tituloPregunta}}</p>
                          <div
                            *ngIf="preguntas.tipoPregunta === 'radio' || preguntas.tipoPregunta === 'checkbox' || preguntas.tipoPregunta === 'select' || preguntas.tipoPregunta === 'stars'">
                            <p *ngFor="let alternativa of preguntas.alternativas">
                              {{alternativa.label}}
                              <small class="bold">({{alternativa.count}} Participantes)</small>
                            </p>
                          </div>
                          <div *ngIf="preguntas.tipoPregunta === 'text' || preguntas.tipoPregunta === 'textarea'">
                            <ngx-datatable
                              class="bootstrap table table-hover table-striped"
                              [columnMode]="'flex'"
                              [limit]="10"
                              [headerHeight]="50"
                              [footerHeight]="50"
                              [rowHeight]="'auto'"
                              [rows]='preguntas.alternativas'>
                              <ngx-datatable-column name="Respuestas" [flexGrow]="1.0">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                  {{row.label}}
                                </ng-template>
                              </ngx-datatable-column>
                            </ngx-datatable>
                          </div>
                          <div *ngIf="preguntas.tipoPregunta === 'matriz'">
                            <div *ngFor="let alternativas of preguntas.alternativas">
                              <h3>{{alternativas.titulo}}:</h3>
                              <p *ngFor="let alternativa of alternativas.alternativas">
                                {{alternativa.label}}
                                <small class="bold">({{alternativa.count}} Participantes)</small>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-12">
                          <chart [options]="preguntas.options"
                                 *ngIf="preguntas.tipoPregunta !== 'text' && preguntas.tipoPregunta !== 'textarea' && preguntas.tipoPregunta !== 'matriz'"></chart>
                          <div *ngIf="preguntas.tipoPregunta === 'matriz'">
                            <div *ngFor="let alternativas of preguntas.alternativas">
                              <chart [options]="alternativas.options"></chart>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-xl-12 col-12">
              <a [routerLink]="['/crud-encuesta']" class="btn btn-gris float-right text-right margin-top-20">Volver </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
