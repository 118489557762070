import {Deserializable} from "./deserializable.interface";

export class EncuestaResultadoParticipantesModel implements Deserializable<EncuestaResultadoParticipantesModel> {
  public nombreParticipante: string;
  public recopilador: string;
  public estadoRespuestas: string;
  public porcentajeRespuesta: number;

  constructor(encuesta?: any) {
    this.nombreParticipante = encuesta && encuesta.nombreParticipante || null;
    this.recopilador = encuesta && encuesta.recopilador || null;
    this.estadoRespuestas = encuesta && encuesta.estadoRespuestas || null;
    this.porcentajeRespuesta = encuesta && encuesta.porcentajeRespuesta || 0;
  }

  deserialize(input: any): EncuestaResultadoParticipantesModel {
    Object.assign(this, input);
    return this;
  }

}


