import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";

import {environment} from "../../environments/environment";

import {EncuestaResultadoCabeceraModel} from "../models/encuesta-resultado-cabecera.model";
import {EncuestaResultadoParticipantesModel} from "../models/encuesta-resultado-participantes.model";
import {EncuestaResultadoPreguntasModel} from "../models/encuesta-resultado-preguntas.model";

@Injectable({
  providedIn: 'root'
})
export class EncuestasResultadosService {

  constructor(private http: HttpClient) {
  }

  getCabecera(idEncuesta): Observable<EncuestaResultadoCabeceraModel> {
    return this.http
      .get<EncuestaResultadoCabeceraModel>(`${environment.API_DOMAINS.ENCUESTAS_RESULTADOS}/cabecera/${idEncuesta}`)
      .pipe(
        map((result: any) => {
          return new EncuestaResultadoCabeceraModel(result);
        })
      );
  }

  getParticipantes(idEncuesta): Observable<EncuestaResultadoParticipantesModel[]> {
    return this.http
      .get<EncuestaResultadoParticipantesModel[]>(`${environment.API_DOMAINS.ENCUESTAS_RESULTADOS}/participantes/${idEncuesta}`)
      .pipe(
        map((result: any) => {
          return result.map((participante: any) => {
            return new EncuestaResultadoParticipantesModel(participante);
          });

        })
      );
  }

  getPreguntas(idEncuesta): Observable<EncuestaResultadoPreguntasModel[]> {
    return this.http
      .get<EncuestaResultadoPreguntasModel[]>(`${environment.API_DOMAINS.ENCUESTAS_RESULTADOS}/preguntas/${idEncuesta}`)
      .pipe(
        map((result: any) => {
          return result.map((pregunta: any) => {
            return new EncuestaResultadoPreguntasModel(pregunta);
          });

        })
      );
  }
}
