//angular
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

//shared
import {GLOBALS} from '../../shared/globals.shared';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {PlantillaCorreoService} from '../../services/plantilla-correo.service';
import {ComunesService} from '../../services/comunes.service';

//models
import {PlantillasCorreosListModel} from '../../models/plantillas-correos-list.model';
import {PlantillasCorreosModel} from '../../models/plantillas-correos.model';
import {TiposPlantillasCorreosModel} from '../../models/tipos-plantillas-correos.model';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-plantillas-correos-list',
  templateUrl: './crud-plantilla-correo.component.html',
  styleUrls: ['./crud-plantilla-correo.component.css'],
  providers: [PlantillaCorreoService]
})
export class CrudPlantillaCorreoComponent implements OnInit, LoggedInCallback {
  @ViewChild('fechaCreacionHeaderTpl') fechaCreacionHdrTpl: TemplateRef<any>;
  @ViewChild('fechaCreacionRowTpl') fechaCreacionRowTpl: TemplateRef<any>;
  @ViewChild('opcionesHeaderTpl') opcionesHdrTpl: TemplateRef<any>;
  @ViewChild('opcionesRowTpl') opcionesRowTpl: TemplateRef<any>;
  @ViewChild(DatatableComponent) gridPlantillasCorreosList: DatatableComponent;

  listCmbPlantillasCorreos: PlantillasCorreosListModel[];
  plantillasCorreosListRows: PlantillasCorreosListModel[];
  plantillasCorreosListRowsTemp: PlantillasCorreosListModel[];
  plantillasCorreosListColumns: any[];
  filtersModel: any = {
    nombrePlantilla : '',
    tipoPlantilla : ''
  };
  clonModel: any = {
    idPlantillaCorreo: ''
  };
  listCmbTipoPlantillaCorreo: TiposPlantillasCorreosModel[];
  private modalRef: NgbModalRef;

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaCorreoService: PlantillaCorreoService,
              private comunesService: ComunesService,
              private modalService: NgbModal) {
    console.log('CrudPlantillaCorreoComponent: constructor');

  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudPlantillaCorreoComponent: verificacion sesion');

    if (!isLoggedIn) {
      console.log('CrudPlantillaCorreoComponent: usuario no autenticado, redireccionando al login.');
      // this.router.navigate(['/login']);
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    } else {
      this.gridPlantillasCorreosList.messages.emptyMessage = "No hay registros disponibles.";
      this.initListTiposPlantillasCorreos();
      this.initGridColumns();
      this.loadPlantillasCorreos();
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);
  }

  initListTiposPlantillasCorreos() {
    this.spinner.show();
    this.comunesService.listTipoPlantillasCorreos().subscribe(
      (result: TiposPlantillasCorreosModel[]) => {
        this.spinner.hide();
        this.listCmbTipoPlantillaCorreo = result;
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          // this.toastr.info(
          //   "No se encontraron Tipos de Plantillas de Correos.",
          //   GLOBALS.TOASTER.TITLE_INFO
          // );
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  initGridColumns(): void {
    this.plantillasCorreosListColumns = [
      {name: 'Nombre Plantilla Correo', prop: 'nombrePlantillaCorreo'},
      {name: 'Tipo Plantilla Correo', prop: 'nombreTipoPlantillaCorreo'},
      {
        cellTemplate: this.fechaCreacionRowTpl,
        headerTemplate: this.fechaCreacionHdrTpl,
        name: 'Fecha Inicio Ejecución',
        prop: 'fechaCreacion'
      },
      {
        cellTemplate: this.opcionesRowTpl,
        headerTemplate: this.opcionesHdrTpl,
        name: 'Opciones',
        prop: 'idPlantillaCorreo'
      }
    ];
  }

  loadPlantillasCorreos(): void {
    console.log('CrudPlantillaCorreoComponent: recuperando el listado de plantillas de correos.');
    this.spinner.show();
    this.plantillaCorreoService.list().subscribe(
      (result: PlantillasCorreosListModel[]) => {
        this.spinner.hide();
        this.plantillasCorreosListRows = result;
        this.plantillasCorreosListRowsTemp = result;
        this.listCmbPlantillasCorreos = result;
      }, error => {
        this.spinner.hide();

        if (error.status === 404) {
          // this.toastr.info('No se encontraron plantillas de correos.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  openModalDeletePlantilla(refModalDeletePlantilla): void {
    this.modalRef = this.modalService.open(refModalDeletePlantilla);
  }

  openModalClonPlantilla(refModalClonPlantilla): void {
    this.modalRef = this.modalService.open(refModalClonPlantilla);
  }

  deletePlantillaCorreo(row: any): void {
    this.plantillaCorreoService.get(row.idPlantillaCorreo).subscribe(
      (result: PlantillasCorreosModel) => {
        console.log(result);
        result.eliminado = true;
        this.plantillaCorreoService.update(result).subscribe(
          (result) => {
            this.modalRef.close();
            this.spinner.hide();
            this.toastr.success('Plantilla eliminada con éxito.', GLOBALS.TOASTER.TITLE_OK);
            console.log('CrudPlantillaCorreoComponent: Plantilla de correo eliminada');
            this.initGridColumns();
            this.loadPlantillasCorreos();
          }, error => {
            if (error.status === 404) {
              this.toastr.info('Ocurrio un error en el servicio.', GLOBALS.TOASTER.TITLE_INFO);
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          })
      }, error => {
        this.spinner.hide();

        if (error.status === 404) {
          this.toastr.info('No se encontraro la plantilla de correo.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  gridFilter(event: any, tipoOrigen: string): void{
    switch (tipoOrigen){
      case 'NOMBRE_PLANTILLA': {
        if(event !== undefined){
          this.filtersModel.nombrePlantilla = event.target.value.toString().toLowerCase();
          break;
        }
        else{
          this.filtersModel.nombrePlantilla = '';
        }
      }
      case 'TIPO_PLANTILLA': {
        if(event !== undefined){
          this.filtersModel.tipoPlantilla = event.nombreTipoPlantillaCorreo.toLowerCase();
          break;
        }
        else{
          this.filtersModel.tipoPlantilla = '';
        }
      }
    }

    if (this.filtersModel.nombrePlantilla !== null ||
      this.filtersModel.tipoPlantilla !== null )
    {

      //filtrado de data
      const temp = this.plantillasCorreosListRowsTemp.filter(d => {
        return (d.nombrePlantillaCorreo.toLowerCase().indexOf(this.filtersModel.nombrePlantilla) !== -1
          && d.nombreTipoPlantillaCorreo.toLowerCase().indexOf(this.filtersModel.tipoPlantilla) !== -1);
      });

      this.plantillasCorreosListRows = temp;
      //this.gridCursosList.offset = 0;

      if (this.plantillasCorreosListRows.length == 0){
        this.toastr.info('No se encontraron coincidencias para su búsqueda', 'Información');
      }
    }else{
      this.plantillasCorreosListRows = this.plantillasCorreosListRowsTemp;
    }
  }

  clonarPlantilla(): void {
    console.log(this.clonModel);
    this.modalRef.close();
    this.router.navigate(['/crud-plantilla-correo-clon/' + this.clonModel.idPlantillaCorreo]);
  }
}
