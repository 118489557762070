<app-header></app-header>
<div class="container-fluid margin-bottom-30" style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
    <section>
        <div class="fondo-blanco top-70" style="background:url(assets/img/transparencia.png) repeat;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col margin-top-20">
                        <i>Bienvenido</i>
                    </div>
                    <div class="col text-right margin-top-20">
                        Encuestas / Mantención de Plantillas de Correo / Nueva Plantilla de Correo
                    </div>
                </div>
            </div>
            <div class="row-fluid">
                <div class="col-xl-10 col-12">
                    <h1 class="margin-top-20">Nueva Plantilla de Correo</h1>
                </div>
            </div>
            <div class="col-xl-12 col-12 padding-inside">
                <div class="col-xl-12 col-12 card">
                    <form name="form" #formPlantilla="ngForm" novalidate>
                        <div class="col-xl-10 col-lg-12 mx-auto col-12 margin-top-20">
                            <div class="row card col-xl-12 col-12">
                                <div class="row-fluid">
                                    <h2>Nueva Plantilla de Correo</h2>
                                </div>
                                <div class="form-row mx-auto col-xl-8 col-12">
                                    <div class="form-group col-xl-8 col-12">
                                        <label>
                      <span class="obligatorio">*</span>Nombre Plantilla de Correo:
                    </label>
                                        <input type="text" id="nombrePlantillaCorreo" name="nombrePlantillaCorreo" class="form-control" placeholder="Ingrese" [(ngModel)]="formModel.nombrePlantillaCorreo" #nombrePlantillaCorreo="ngModel" [ngClass]="{ 'is-invalid': formPlantilla.submitted && nombrePlantillaCorreo.invalid }"
                                            required>
                                        <div *ngIf="formPlantilla.submitted && nombrePlantillaCorreo.invalid" style="color: #dc3545;">
                                            <div *ngIf="nombrePlantillaCorreo.errors.required">Debe ingresar el nombre de la plantilla.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-12">
                                        <label>
                      <span class="obligatorio">*</span>Tipo Plantilla de Correo:
                    </label>
                                        <ng-select class="form-control" id="tipoplantilla" name="tipoplantilla" [items]="listCmbTipoPlantillaCorreo" bindLabel="nombreTipoPlantillaCorreo" bindValue="idTipoPlantillaCorreo" [ngStyle]="{'border-color' : (formPlantilla.submitted && tipoplantilla.invalid) ? '#dc3545' : 'white'}"
                                            [(ngModel)]="formModel.idTipoPlantillaCorreo" #tipoplantilla="ngModel" required>
                                        </ng-select>
                                        <div *ngIf="formPlantilla.submitted && tipoplantilla.invalid" style="color: #dc3545;">
                                            <div *ngIf="tipoplantilla.errors.required">Debe seleccionar un tipo de plantilla.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mx-auto col-xl-8 col-12">
                                    <div class="form-group col-xl-6 col-12">
                                        <label>
                      <span class="obligatorio">*</span>Encabezado:
                    </label>
                                        <input type="text" id="encabezado" name="encabezado" class="form-control" placeholder="Ingrese" [(ngModel)]="formModel.encabezado" #encabezado="ngModel" required [ngClass]="{ 'is-invalid': formPlantilla.submitted && encabezado.invalid }">
                                        <div *ngIf="formPlantilla.submitted && encabezado.invalid" style="color: #dc3545;">
                                            <div *ngIf="encabezado.errors.required">Debe ingresar el encabezado.</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 col-12">
                                        <label>
                      <span class="obligatorio">*</span>Asunto:
                    </label>
                                        <input type="text" id="asunto" name="asunto" class="form-control" placeholder="Ingrese" [(ngModel)]="formModel.asunto" #asunto="ngModel" required [ngClass]="{ 'is-invalid': formPlantilla.submitted && asunto.invalid }">
                                        <div *ngIf="formPlantilla.submitted && asunto.invalid" style="color: #dc3545;">
                                            <div *ngIf="asunto.errors.required">Debe ingresar el asunto.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mx-auto col-xl-8 col-12">
                                    <div class="col-xl-5 col-lg-6 col-12 seleccion-tema form-group">
                                        <label>Seleccione Tema:</label>
                                        <input class="form-control" [style.background]="formModel.tema" [colorPicker]="formModel.tema" (colorPickerChange)="temaSelected($event)" [cpPosition]="'left'">
                                    </div>
                                    <div class="form-group col-xl-7 col-lg-6 col-12">
                                        <label data-toggle="tooltip" data-title="El Logotipo Seleccionado se utilizará en la Plantilla de Encuesta y Plantilla de Correo">Cargar
                      Logotipo:</label>
                                        <input type="file" class="form-control" id="logo" name="logo" (change)="cargarArchivo($event)">
                                    </div>
                                </div>

                                <div class="form-row mx-auto col-xl-8 col-12">
                                    <div class="col-xl-12 col-12">
                                        <label>Mensaje:</label>

                                        <app-ngx-editor class="form-control" [config]="config" [(ngModel)]="formModel.mensaje" id="html" name="html" #mensaje="ngModel" required [ngStyle]="{'border-color' : (formPlantilla.submitted && mensaje.invalid) ? '#dc3545' : 'white'}"></app-ngx-editor>
                                        <div *ngIf="formPlantilla.submitted && mensaje.invalid" style="color: #dc3545;">
                                            <div *ngIf="mensaje.errors.required">Debe ingresar el mensaje.</div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div class="row-fluid">
                                <div class="col-xl-12 col-12 card">
                                    <div class="form-row mx-auto col-xl-8 col-12 mx-auto">
                                        <h2>Vista Previa Correo:</h2>
                                    </div>
                                    <div class="form-row mx-auto col-xl-8 col-12 mx-auto text-center">
                                        <div class="form-group col-xl-12 col-12 text-center mx-auto">
                                            <ng-container *ngTemplateOutlet="templateEmail"></ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-12 text-right mx-auto float-right margin-bottom-30">
                            <a [routerLink]="['/crud-plantilla-correo']" class="btn btn-gris float-right text-right margin-left-15">
                Volver a Listado de Plantillas de Correo
              </a>
                            <input type="submit" value="Guardar Plantilla de Correo" class="btn btn-primary float-right text-right" (click)="guardarPlantillaCorreo(formPlantilla)">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

</div>

<!--<ng-template #holaPrueba>-->
<!--<div [ngStyle]="{'background-color': formModel.tema}">-->
<!--Hola-->
<!--</div>-->
<!--<div [ngStyle]="{'background-color': formModel.tema}">-->
<!--{{formModel.mensaje}}-->
<!--</div>-->
<!--</ng-template>-->

<ng-template #templateEmail>
    <!doctype html>
    <html lang="en">

    <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <title>SISTEMA DE ENCUESTAS DE EFECTIVIDAD</title>
    </head>

    <body style="height:auto;width:550px;font-family:'Arial, sans-serif';background: #ffffff !important;">
        <table [ngStyle]="{'background':'#ffffff','width': '550px','height': 'auto','border':'1px solid #ccc'}">

            <body style="background-color: #FFFFFF !important;">
                <tr>
                    <td [ngStyle]="{'height':'10px','width':'100%','background-color': formModel.tema}"></td>
                </tr>

                <tr *ngIf="formModel.logo !== null">
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <img *ngIf="formModel.logo !== null" [src]="formModel.logo == null ? 'assets/img/monos.png' : formModel.logo" width="200" height="100">
                    </td>
                </tr>
                <tr>
                    <td style="width:550px;height:50px;padding:10px 5px;text-align:center;">
                        <span style="font-size:25px;color:blue;">{{formModel.encabezado}}</span>
                    </td>
                </tr>

                <tr>
                    <td style="width:550px;height:auto;padding:10px 5px;">
                        <div [innerHTML]="formModel.mensaje | safe"></div>
                    </td>
                </tr>
                <tr>
                    <td [ngStyle]="{'width':'550px','height':'auto','padding':'10px 5px','color':'#fff','text-align':'center', 'background':formModel.tema}">
                        OTIC Sofofa 2018, Servicio de Encuestas
                    </td>
                </tr>
            </body>
        </table>
    </body>

    </html>
</ng-template>

<app-footer></app-footer>