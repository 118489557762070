import {Deserializable} from "./deserializable.interface";
import {RespuestaModel} from './respuesta.model';


export class EncuestaParticipanteModel implements Deserializable<EncuestaParticipanteModel> {
  public idEncuesta: string;
  public idParticipante: string;
  public respuestas: any;
  public encuestaRespondida: boolean;
  public fechaRespuesta: string;

  constructor(encuesta?: any) {
    this.idEncuesta = encuesta && encuesta.idEncuesta || null;
    this.idParticipante = encuesta && encuesta.idParticipante || null;
    this.respuestas = encuesta && encuesta.respuestas || null;
    this.fechaRespuesta = encuesta && encuesta.fechaRespuesta || null;
    if (encuesta)
      this.encuestaRespondida = encuesta.encuestaRespondida;
    else
      this.encuestaRespondida = null
  }

  deserialize(input: any): EncuestaParticipanteModel {
    Object.assign(this, input);
    return this;
  }
}
