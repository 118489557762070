import { Component, OnInit } from '@angular/core';
import {EncuestaPreviewModel} from '../../models/encuesta-preview.model';
import {GLOBALS} from "../../shared/globals.shared";
import {PlantillaEncuestaService} from "../../services/plantilla-encuesta.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {ExternoService} from "../../services/externo.service";
import {UserLoginService} from "../../services/user-login.service";
import {ToastrService} from "ngx-toastr";
import {EncuestaService} from "../../services/encuesta.service";
import {ComunesService} from "../../services/comunes.service";
import {PlantillaCorreoService} from "../../services/plantilla-correo.service";
import * as moment from 'moment';



@Component({
  selector: 'app-crud-encuesta-preview',
  templateUrl: './crud-encuesta-preview.component.html',
  styleUrls: ['./crud-encuesta-preview.component.css']
})
export class CrudEncuestaPreviewComponent implements OnInit {

  pathParams: any;
  modelEncuesta: EncuestaPreviewModel = new EncuestaPreviewModel();
  estructuraPreguntas: any[];
  itemsByPage: any[];
  pager: any = {
    pages : [],
    totalPages: '',
    currentPage: 1
  };

  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private plantillaCorreoService: PlantillaCorreoService,
              private comunesService: ComunesService,
              private externoService: ExternoService,
              private encuestaService: EncuestaService) { }

  ngOnInit() {
    this.route.params.subscribe(params => this.pathParams = params);
    console.log(this.pathParams.id);
    if (Number.isNaN(this.pathParams.id)) {
      this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
      this.router.navigate(['/crud-encuesta']);
    }
    this.loadEncuesta(this.pathParams.id);
  }

  loadEncuesta(id) {
    this.spinner.show();
    this.encuestaService.getPreview(id).subscribe((result: EncuestaPreviewModel) => {
        console.log('CrudPlantillaEncuestaPreviewComponent: plantilla de encuesta obtenida');
        this.modelEncuesta = result;
        this.modelEncuesta.fechaEnvio = moment.unix(parseInt(result.fechaEnvio)).format('DD/MM/YYYY HH:mm');
        this.modelEncuesta.fechaLimiteRespuesta = this.modelEncuesta.fechaLimiteRespuesta != null ?  moment.unix(parseInt(result.fechaLimiteRespuesta)).format('DD/MM/YYYY HH:mm') : null;
        this.estructuraPreguntas = JSON.parse(result.preguntas);
        this.setCountPage();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('No se encontro la encuesta.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  setCountPage() {
    this.estructuraPreguntas.map(item=> {
      this.pager.pages.push(item.pagina)
    });
    this.pager.totalPages = this.pager.pages.length;
    this.setItemsByPage(this.pager.currentPage);
  }

  setItemsByPage(page: number){
    this.itemsByPage = this.estructuraPreguntas.filter( d => {
      return d.pagina === page;
    })[0].preguntas;
  }

  changePage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager.currentPage = page;
    this.setItemsByPage(this.pager.currentPage);
  }

}
