import {Component, OnInit, AfterContentInit, ViewChild} from '@angular/core';


//utils
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from "@angular/router";
import {DateTimeAdapter} from 'ng-pick-datetime';
import * as moment from 'moment';
import {SimpleCrypt} from 'ngx-simple-crypt';
import {environment} from '../../../environments/environment';
import * as cloneDeep from 'lodash/cloneDeep';

//services
import {UserLoginService} from '../../services/user-login.service';
import {LoggedInCallback} from '../../services/cognito.service';
import {PlantillaEncuestaService} from '../../services/plantilla-encuesta.service';
import {PlantillaCorreoService} from '../../services/plantilla-correo.service';
import {ComunesService} from '../../services/comunes.service';
import {ExternoService} from '../../services/externo.service';
import {EncuestaService} from '../../services/encuesta.service';

//shared
import {GLOBALS, Utils} from '../../shared/globals.shared';

//models
import {PlantillasEncuestasListModel} from '../../models/plantillas-encuestas-list.model';
import {PlantillasCorreosListModel} from '../../models/plantillas-correos-list.model';
import {EmpresaListModel} from '../../models/empresa-list.model';
import {CatalogoContenidoCursoListModel} from '../../models/catalogo-contenido-curso-list.model';
import {EncuestaModel} from '../../models/encuesta.model';
import {ParametroComunModel} from '../../models/parametro-comun.model';
import {PlantillasCorreosModel} from '../../models/plantillas-correos.model';
import {ParticipantesModel} from "../../models/participantes.model";
import {EncuestaListModel} from "../../models/encuesta-list.model";

@Component({
  selector: 'app-crud-encuesta-edit',
  templateUrl: './crud-encuesta-edit.component.html',
  styleUrls: ['./crud-encuesta-edit.component.css']
})
export class CrudEncuestaEditComponent implements OnInit, LoggedInCallback, AfterContentInit {

  @ViewChild('templateFechaEnvio') modalFechaEnvio;
  pathParams: any;
  todos: boolean = true;
  config: any = GLOBALS.CONFIG_EDITOR;
  modalRef: NgbModalRef;
  html: any;
  url: string;
  listCmbPlantillasEncuestas: PlantillasEncuestasListModel[];
  listCmbEmpresas: EmpresaListModel[];
  listCmbCursos: CatalogoContenidoCursoListModel[];
  listCmbEtapas: ParametroComunModel[];
  listCmbPlantillasCorreosCierre: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosInvitacion: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosRecordatorio: PlantillasCorreosListModel[];
  listCmbPlantillasCorreosConfirmacion: PlantillasCorreosListModel[];
  modelEncuesta: EncuestaModel = new EncuestaModel();
  modelAddParticipante: ParticipantesModel = new ParticipantesModel();
  encuestaListRows: EncuestaListModel[];
  modelEmails: any = {
    invitacion: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      encabezado: '',
      asunto: '',
      mensaje: '',
      logo: ''
    },
    recordatorio: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      encabezado: '',
      asunto: '',
      mensaje: '',
      logo: ''
    },
    cierre: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      encabezado: '',
      asunto: '',
      mensaje: '',
      logo: ''
    },
    envioRpta: {
      idPlantillaCorreo: null,
      tema: '#FFFFFF',
      encabezado: '',
      asunto: '',
      mensaje: '',
      logo: ''
    }
  };
  empresaSeleccionada: boolean = false;
  cursoModel: any;
  empresaModel: any;
  etapaCursoModel: any;
  plantillaInvitacionModel: any;
  plantillaRecordatorioModel: any;
  plantillaCierreModel: any;
  plantillaEnvioRptaModel: any;
  dateStart: Date;
  dateLimit: Date;

  recopiladores: any = [{
    label: 'URL'
  }, {
    label: 'Email'
  }];

  dummyEmpresa: any = [{
    razonSocial: 'Comercializadora Costanera'
  }];

  dummyCurso: any = [{
    nombreCurso: 'Excel Avanzado pro',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Básico',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }, {
    nombreCurso: 'Excel Ofimática',
    etapaCurso: '',
    tipoCurso: 'Franquicia',
    fechaInicio: '15/09/2018',
    fechaFin: '16/09/2018'
  }];

  dummyParticipantes: any = [];
  dummyCloneParticipantes: any = [];


  constructor(private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private router: Router,
              private userLoginService: UserLoginService,
              private plantillaEncuestaService: PlantillaEncuestaService,
              private plantillaCorreoService: PlantillaCorreoService,
              private comunesService: ComunesService,
              private externoService: ExternoService,
              private encuestaService: EncuestaService,
              private modalService: NgbModal,
              private dateTimeAdapter: DateTimeAdapter<any>) {
    this.dateTimeAdapter.setLocale('es-CL');
    console.log('CrudPlantillaCorreoEditComponent: constructor');

  }


  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('CrudEncuestaEditComponent: verificacion sesion');
    if (!isLoggedIn) {
      console.log('CrudEncuestaEditComponent: usuario no autenticado, redireccionando al login.');
      // this.router.navigate(['/login']);
      window.location.href = `${environment.URL_SIGNOUT}?callback=${environment.URL_AUTH}&code=${environment.CLIENT_ID}`;
    } else {
      this.route.params.subscribe(params => this.pathParams = params);
      if (Number.isNaN(this.pathParams.id)) {
        this.toastr.error('Parámetro inválido', GLOBALS.TOASTER.TITLE_ERROR);
        this.router.navigate(['/crud-plantilla-correo']);
      }
      this.url = environment.LINK_BASE_URL_RESPONDER;
      this.loadEncuestas();
      this.loadEncuesta(this.pathParams.id);
      // this.initEmpresas();
      this.initPlantillasEncuestas();
      this.initParametroComun();
      this.initPlantillasCorreos();
    }
  }

  ngOnInit() {
    this.userLoginService.isAuthenticated(this);

  }

  ngAfterContentInit() {
    // this.loadEncuesta(this.pathParams.id);
    // this.initEmpresas();
    // this.initPlantillasEncuestas();
    // this.initParametroComun();
    // this.initPlantillasCorreos();
  }

  loadEncuesta(id) {
    console.log('CrudEncuestaEditComponent: obteniendo datos de la planilla de correo con id  ' + id);
    this.spinner.show();
    this.encuestaService.get(id).subscribe(
      (result: EncuestaModel) => {
        console.log('CrudEncuestaEditComponent: plantilla de correo obtenida');
        this.modelEncuesta = result;
        this.empresaModel = this.modelEncuesta.empresa.razonSocial;
        this.cursoModel = this.modelEncuesta.curso.nombreCurso;
        this.etapaCursoModel = this.modelEncuesta.curso.etapaCurso;
        this.dateStart = moment.unix(parseInt(this.modelEncuesta.fechaEnvio)).toDate();
        this.dateLimit = this.modelEncuesta.fechaLimiteRespuesta != null ? moment.unix(parseInt(this.modelEncuesta.fechaLimiteRespuesta)).toDate() : null;
        this.empresaSeleccionada = true;
        this.dummyParticipantes = this.formatParticipantes(result.participantes);
        this.dummyCloneParticipantes = cloneDeep(this.dummyParticipantes);
        this.modelEmails = JSON.parse(result.plantillasCorreos);
        this.plantillaInvitacionModel = this.modelEmails.invitacion.idPlantillaCorreo;
        this.plantillaRecordatorioModel = this.modelEmails.recordatorio.idPlantillaCorreo;
        this.plantillaCierreModel = this.modelEmails.cierre.idPlantillaCorreo;
        this.plantillaEnvioRptaModel = this.modelEmails.envioRpta.idPlantillaCorreo;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();

        if (error.status === 404) {
          // this.toastr.info('No se encontro planilla de correo.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      }
    );
  }

  /**
   * Lista las encuestas
   */
  loadEncuestas(): void {
    this.spinner.show();
    console.log('CrudEncuestaComponent: recuperando el listado de encuestas de efectividad.');
    this.encuestaService.list().subscribe(
      (result: EncuestaListModel[]) => {
        this.encuestaListRows = result;
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.encuestaListRows = [];
          //this.toastr.info('No se encontraron encuestas de efectividad.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }


  initPlantillasEncuestas(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.plantillaEncuestaService.list().subscribe(
      (result: PlantillasEncuestasListModel[]) => {
        this.spinner.hide();
        this.listCmbPlantillasEncuestas = result.filter(function (item) {
          return !item.eliminado
        });
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  initEmpresas(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de encuestas.');
    this.spinner.show();
    this.externoService.listEmpresas().subscribe(
      (result: EmpresaListModel[]) => {
        this.spinner.hide();
        this.listCmbEmpresas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron plantillas de encuestas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  initParametroComun(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de etapas de curso.');
    this.spinner.show();
    this.externoService.listParametroComun(14).subscribe(
      (result: ParametroComunModel[]) => {
        this.spinner.hide();
        this.listCmbEtapas = result;
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron estapas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      });
  }

  /**
   * Lista las plantillas de correos.
   */
  initPlantillasCorreos(): void {
    console.log('CrudEncuestaAddComponent: recuperando el listado de plantillas de correos.');
    this.spinner.show();
    this.plantillaCorreoService.list().subscribe(
      (result: PlantillasCorreosListModel[]) => {
        this.spinner.hide();
        this.listCmbPlantillasCorreosInvitacion = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 1;
        });
        this.listCmbPlantillasCorreosRecordatorio = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 2;
        });
        this.listCmbPlantillasCorreosCierre = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 3;
        });
        this.listCmbPlantillasCorreosConfirmacion = result.filter((item) => {
          return item.idTipoPlantillaCorreo === 4;
        });
      }, error => {
        this.spinner.hide();
        if (error.status === 404) {
          //this.toastr.info('No se encontraron estapas.', GLOBALS.TOASTER.TITLE_INFO);
        } else {
          console.log(<any>error);
          this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
        }
      })
  }

  selectPlantillaEncuesta(event) {
    this.modelEncuesta.preguntasPlantillaEncuesta = event.preguntas;
  }

  /**
   * Obtiene los datos completos de la plantilla de correo seleccionada.
   * @param event
   * @param type
   */
  selectPlantillaCorreo(event, type) {
    // this.getDetailPlantillaCorreo(event.idPlantillaCorreo, type);
    if (event) {
      this.spinner.show();
      this.plantillaCorreoService.get(event.idPlantillaCorreo).subscribe
      ((result: PlantillasCorreosModel) => {
          this.getDetailPlantillaCorreo(result, type);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          if (error.status === 404) {
            this.getDetailPlantillaCorreo(null, type);
            // this.toastr.info(            "Ocurrio un error en el servicio.",            GLOBALS.TOASTER.TITLE_INFO          );
          } else {
            console.log(<any>error);
            this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
          }
        })
    } else {
      this.getDetailPlantillaCorreo(null, type);
    }
  }

  getDetailPlantillaCorreo(detail: PlantillasCorreosModel, type: string) {
    switch (type) {
      case 'INVITACION':
        this.modelEmails.invitacion.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.invitacion.encabezado = detail && detail.encabezado || null;
        this.modelEmails.invitacion.mensaje = detail && detail.mensaje || null;
        this.modelEmails.invitacion.asunto = detail && detail.asunto || null;
        this.modelEmails.invitacion.tema = detail && detail.tema || null;
        this.modelEmails.invitacion.logo = detail && detail.logo || null;
        break;
      case 'RECORDATORIO':
        this.modelEmails.recordatorio.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.recordatorio.encabezado = detail && detail.encabezado || null;
        this.modelEmails.recordatorio.mensaje = detail && detail.mensaje || null;
        this.modelEmails.recordatorio.asunto = detail && detail.asunto || null;
        this.modelEmails.recordatorio.tema = detail && detail.tema || null;
        this.modelEmails.recordatorio.logo = detail && detail.logo || null;
        break;
      case 'CIERRE':
        this.modelEmails.cierre.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.cierre.encabezado = detail && detail.encabezado || null;
        this.modelEmails.cierre.mensaje = detail && detail.mensaje || null;
        this.modelEmails.cierre.asunto = detail && detail.asunto || null;
        this.modelEmails.cierre.tema = detail && detail.tema || null;
        this.modelEmails.cierre.logo = detail && detail.logo || null;
        break;
      case 'ENVIORPTA':
        this.modelEmails.envioRpta.idPlantillaCorreo = detail && detail.idPlantillaCorreo || null;
        this.modelEmails.envioRpta.encabezado = detail && detail.encabezado || null;
        this.modelEmails.envioRpta.mensaje = detail && detail.mensaje || null;
        this.modelEmails.envioRpta.asunto = detail && detail.asunto || null;
        this.modelEmails.envioRpta.tema = detail && detail.tema || null;
        this.modelEmails.envioRpta.logo = detail && detail.logo || null;
        break;
    }
  }

  selectCurso(event): void {
    this.modelEncuesta.curso = event;
  }

  selectEmpresa(event): void {
    if (event) {
      this.modelEncuesta.empresa = event;
      this.modelEncuesta.curso = null;
      this.empresaSeleccionada = true;
    } else {
      this.cursoModel = null;
      this.modelEncuesta.curso = null;
      this.empresaSeleccionada = false;
    }
  }

  selectEtapa(event): void {
    this.modelEncuesta.curso.etapaCurso = event.nombreDetalle
  }

  onPage(event) {
    console.log('paged!', event);
  }

  setNameUrl(event) {
    this.modelEncuesta.urlEncuesta = event.target.value.replace(" ", "_").toString().toLowerCase();
  }


  saveEncuesta(form): void {
    if (!form.valid) {
      this.toastr.warning('Existen campos incompletos');
      return;
    }
    if (this.validateUrl()) {
      if (this.validateCode()) {
        // if (this.validateDateSend()) {

        if (this.validateSelectedParticipantes()) {
          if (this.validateInputIndentificador()) {
            this.modelEncuesta.estadoServicio = true;
            this.modalRef = this.modalService.open(this.modalFechaEnvio);

          } else {
            this.toastr.warning('Existen identificadores no completados.');
          }
        }
        else {
          this.toastr.warning('Debe seleccionar al menos un participante antes de guardar');
        }

        // }
        // else {
        //   this.toastr.warning('La fecha de envío debe ser mayor a la fecha actual');
        // }
      }
      else {
        this.toastr.warning('El código de encuesta ingresado ya se encuentra registrado.');
      }
    } else {
      this.toastr.warning('La URL ingresada ya se encuestra registrada.');
    }

  }

  updateValue(event, name, index) {
    console.log(event.target.value);
    if (event.target.type == 'checkbox') {
      this.dummyParticipantes[index][name] = event.target.checked;
      this.dummyCloneParticipantes[index][name] = event.target.checked;
    } else {
      this.dummyParticipantes[index][name] = event.target.value;
      this.dummyCloneParticipantes[index][name] = event.target.value;
    }
  }


  /**
   * selecciona a todos los participantes
   * @param event
   */
  seleccionarTodos(event) {
    this.dummyParticipantes.map(function (item) {
      item.recibeEncuesta = event.target.checked;
    });
    this.dummyCloneParticipantes.map(function (item) {
      item.recibeEncuesta = event.target.checked;
    });
  }

  /**
   * Valida si se ha seleccionado al menos un participante.
   * @returns {boolean}
   */
  validateSelectedParticipantes() {
    let filter = this.dummyParticipantes.filter((item) => {
      return item.recibeEncuesta;
    });
    return filter.length > 0;
  }


  /**
   * Valida los identificadores de los participantes
   */
  validateInputIndentificador() {
    let array = [];
    if (this.modelEncuesta.identificadorEncuesta != 'rut') {
      this.dummyParticipantes.filter((item) => {
        return item.recibeEncuesta;
      }).map((item) => {
        switch (item.recopilador) {
          case 'url':
            if (item.identificador == '')
              array.push(item);
            break;
          case 'email':
            if (item.email == '')
              array.push(item);
            break;
        }
      });
    } else if (this.modelEncuesta.identificadorEncuesta == 'rut') {
      this.dummyParticipantes.filter((item) => {
        return item.recibeEncuesta;
      }).map((item) => {
        switch (item.recopilador) {
          case 'email':
            if (item.email == '')
              array.push(item);
            break;
        }
      });
    }

    return array.length <= 0;
  }

  /**
   * Formatea la información de los participantes obtenidos desde la bd.
   * @param participantes
   * @returns {any[]}
   */
  formatParticipantes(participantes) {
    let array = [];
    let count = 0;
    participantes.map((item) => {
      count += item.recibeEncuesta ? 1 : 0;
      array.push({
        recibeEncuesta: item.recibeEncuesta,
        recordar: item.recordar ? item.recordar : false,
        identificador: item.identificador ? item.identificador : '',
        recopilador: item.recopilador ? item.recopilador : '',
        rutParticipante: item.rutParticipante,
        nombreParticipante: item.nombreParticipante,
        email: item.email,
        contrasena: item.contrasena ? item.contrasena : Utils.randomUuid(),
        idParticipante: item.idParticipante,
        encuestaRespondida: item.encuestaRespondida ? item.encuestaRespondida : false,
        eliminado: item.eliminado ? item.eliminado : false
      });
    });
    this.todos = count === array.length;
    return array;
  }

  /**
   * Valida que la fecha limite sea mayor a la de envío
   */
  validateDates() {
    if (this.modelEncuesta.fechaMaxRpta) {
      let fechaEnvio = moment(this.dateStart);
      let fechaLimite = moment(this.dateLimit);
      let after = fechaLimite.isAfter(fechaEnvio);
      return after;
    } else {
      return true;
    }
  }

  /**
   * Mostrar modal para agregar participante
   */
  openModalParticipante(refModalAddParticipante): void {
    this.modelAddParticipante = new ParticipantesModel();
    this.modalRef = this.modalService.open(refModalAddParticipante);
  }

  openModalDeleteParticipante(refModalDeleteParticipante): void {
    this.modalRef = this.modalService.open(refModalDeleteParticipante);
  }

  /**
   * Agrega un nuevo participantes a la tabla
   */
  agregarParticipante(form) {
    if (!form.valid) {
      return;
    }
    this.modelAddParticipante.contrasena = Utils.randomUuid();
    this.modelAddParticipante.recibeEncuesta = false;
    this.modelAddParticipante.idEncuesta = this.pathParams.id;
    this.modelAddParticipante.recopilador = 'url';
    var clone = cloneDeep(this.dummyParticipantes);
    clone.push(this.modelAddParticipante);
    this.dummyCloneParticipantes = clone;
    this.dummyParticipantes = clone;
    this.modalRef.close();
  }

  /**
   * Quita un participante desde la lista
   * @param row
   * @param index
   */
  quitarParticipante(row, index) {
    if (row.idParticipante !== '') {
      row.eliminado = true;
      this.dummyCloneParticipantes = cloneDeep(this.dummyParticipantes);
      this.dummyParticipantes = this.dummyParticipantes.filter((item) => {
        return !item.eliminado
      });
    } else {
      this.dummyCloneParticipantes.splice(index, 1);
      this.dummyParticipantes.splice(index, 1);
    }
    this.modalRef.close();
  }

  /**
   * Valida si la fecha de envío es mayor a la actual
   */
  validateDateSend() {
    let fechaEnvio = moment(this.dateStart);
    let fechaActual = moment();
    let after = fechaEnvio.isAfter(fechaActual);
    return after;
  }

  /**
   * Valida si el código de la encuesta existe ya creada
   */
  validateCode() {
    let filterId = this.encuestaListRows.filter((item) => {
      return item.idEncuesta != this.modelEncuesta.idEncuesta;
    });
    let filter = filterId.filter((item) => {
      return item.codigoEncuesta !== null ? item.codigoEncuesta.toLowerCase() === this.modelEncuesta.codigoEncuesta.toLowerCase() : false
    });
    return filter.length <= 0;
  }

  /**
   * Valida si la URL personalizada ya existe
   */
  validateUrl() {
    let filterId = this.encuestaListRows.filter((item) => {
      return item.urlEncuesta != this.modelEncuesta.urlEncuesta;
    });
    let filter = filterId.filter((item) => {
      return item.urlEncuesta.toLowerCase() === this.modelEncuesta.urlEncuesta.toLowerCase()
    });
    return filter.length <= 0;
  }

  /**
   * Una vez que es seleccionada la fecha envío, llama al servicio que guarda la encuesta.
   * @param form
   */
  guardarFechaEnvio(form) {
    if (!form.valid) {
      this.toastr.warning('Existen campos incompletos');
      return;
    }
    if (this.validateDateSend()) {
      if (this.validateDates()) {
        this.spinner.show();
        this.modelEncuesta.participantes = this.dummyCloneParticipantes;
        this.modelEncuesta.plantillasCorreos = JSON.stringify(this.modelEmails);
        this.modelEncuesta.fechaEnvio = moment(this.dateStart).unix().toString();
        this.modelEncuesta.fechaLimiteRespuesta = this.dateLimit != null ? moment(this.dateLimit).unix().toString() : null;
        this.modelEncuesta.permiteMasRespuestas = this.modelEncuesta.permiteMasRespuestas ? this.modelEncuesta.permiteMasRespuestas : false;
        if (this.modelEncuesta.recordatorio > 0) {
          this.modelEncuesta.fechaRecordatorio = moment(this.dateStart).add(this.modelEncuesta.recordatorio, 'days').unix().toString();
        }
        this.encuestaService.update(this.modelEncuesta).subscribe(
          (result) => {
            this.modalRef.close();
            this.spinner.hide();
            console.log("CrudEncuestaEditComponent: Encuesta de satisifacción creada");
            this.router.navigate(['/crud-encuesta']);
            this.toastr.success('Encuesta de satifacción modificada con éxito.')
          },
          error => {
            this.spinner.hide();
            if (error.status === 404) {
              this.toastr.info("Ocurrio un error en el servicio.", GLOBALS.TOASTER.TITLE_INFO
              );
            } else {
              console.log(<any>error);
              this.toastr.error(error.error, GLOBALS.TOASTER.TITLE_ERROR);
            }
          }
        );
      } else {
        this.toastr.warning('La fecha de límite de Rpta. debe ser mayor a la fecha de envío');
      }
    } else {
      this.toastr.warning('La fecha de envío debe ser mayor a la fecha actual');
    }
  }


  /**
   * Si se des-seleccionado la fecha limite, este limpia los campos de la fecha y el recoratorio.
   * @param event
   */
  checkSelectedDateLimit(event) {
    if (!event.target.checked) {
      this.dateLimit = null;
      this.modelEncuesta.recordatorio = null;
    }
  }
}
