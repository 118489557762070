import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {map} from "rxjs/operators";

import {environment} from "../../environments/environment";

import {EncuestaModel} from '../models/encuesta.model';
import {EncuestaListModel} from '../models/encuesta-list.model';
import {EncuestaPreviewModel} from '../models/encuesta-preview.model';
import {EncuestaRespuestaModel} from "../models/encuesta-respuesta.model";


@Injectable({
  providedIn: 'root'
})
export class EncuestaService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<EncuestaListModel[]> {
    return this.http
      .get<EncuestaListModel[]>(`${environment.API_DOMAINS.ENCUESTAS}/encuesta`)
      .pipe(
        map((result: any) => {
          return result.map((plantilla: any) => {
            return new EncuestaListModel(plantilla);
          });
        })
      );
  }

  add(encuesta: EncuestaModel): Observable<any> {
    const body = JSON.stringify(encuesta);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.ENCUESTAS}/encuesta`, body, {headers: headers});
  }

  get(id): Observable<EncuestaModel> {
    return this.http
      .get<EncuestaModel>(`${environment.API_DOMAINS.ENCUESTAS}/encuesta/${id}`)
      .pipe(
        map((result: any) => {
          return new EncuestaModel(result)
        })
      );
  }

  getPreview(id): Observable<EncuestaPreviewModel> {
    return this.http
      .get<EncuestaPreviewModel>(`${environment.API_DOMAINS.ENCUESTAS}/encuesta/preview/${id}`)
      .pipe(
        map((result: any) => {
          return new EncuestaPreviewModel(result)
        })
      );
  }

  getEncuestaResponder(id): Observable<EncuestaRespuestaModel> {
    return this.http
      .get<EncuestaRespuestaModel>(`${environment.API_DOMAINS.ENCUESTAS}/encuesta/responder/${id}`)
      .pipe(
        map((result) => {
          return new EncuestaRespuestaModel(result)
        })
      );
  }

  update(encuesta: EncuestaModel): Observable<any> {
    const body = JSON.stringify(encuesta);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.ENCUESTAS}/encuesta`, body, {headers: headers});
  }

  getUUID(url): Observable<string> {
    return this.http
      .get<string>(`${environment.API_DOMAINS.ENCUESTAS}/encuesta/uuid/${url}`)
      .pipe(
        map((result) => {
          return result;
        })
      );
  }
}
