import {Deserializable} from "./deserializable.interface";

export class ParticipantesModel implements Deserializable<ParticipantesModel> {
  public idParticipante: string;
  public rutParticipante: string;
  public nombreParticipante: string;
  public identificador: string;
  public idEncuesta: string;
  public recopilador: string;
  public recordar: boolean;
  public recibeEncuesta: boolean;
  public contrasena: string;
  public email: string;
  public eliminado: boolean;

  constructor(participante?: any) {
    this.idParticipante = participante && participante.idParticipante || '';
    this.rutParticipante = participante && participante.rutParticipante || '';
    this.nombreParticipante = participante && participante.nombreParticipante || '';
    this.identificador = participante && participante.identificador || '';
    this.idEncuesta = participante && participante.idEncuesta || '';
    this.recopilador = participante && participante.recopilador || null;
    this.recordar = participante && participante.recordar || '';
    this.recibeEncuesta = participante && participante.recibeEncuesta || '';
    this.contrasena = participante && participante.contrasena || '';
    this.email = participante && participante.email || '';
    if(participante && participante.eliminado){
      this.eliminado = participante.eliminado
    } else {
      this.eliminado = false
    }
  }

  deserialize(input: any): ParticipantesModel {
    Object.assign(this, input);
    return this;
  }
}
