import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';


import {PlantillasEncuestasModel} from '../models/plantillas-encuestas.model';
import {PlantillasEncuestasListModel} from '../models/plantillas-encuestas-list.model';

import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PlantillaEncuestaService {

  constructor(private http: HttpClient) {
  }

  list(): Observable<PlantillasEncuestasListModel[]> {
    return this.http
      .get<PlantillasEncuestasListModel[]>(`${environment.API_DOMAINS.PLANTILLAS_ENCUESTAS}/plantillaEncuesta`)
      .pipe(
        map((result: any) => {
          return result.map((plantilla: any) => {
            return new PlantillasEncuestasListModel(plantilla);
          });
        })
      );
  }

  add(plantillaEncuesta: PlantillasEncuestasModel): Observable<any> {
    const body = JSON.stringify(plantillaEncuesta);
    console.log(body);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.post(`${environment.API_DOMAINS.PLANTILLAS_ENCUESTAS}/plantillaEncuesta`, body, {headers: headers});
  }

  get(id): Observable<PlantillasEncuestasModel> {
    return this.http
      .get<PlantillasEncuestasModel>(`${environment.API_DOMAINS.PLANTILLAS_ENCUESTAS}/plantillaEncuesta/${id}`)
      .pipe(
        map((result: any) => {
          return new PlantillasEncuestasModel(result)
        })
      );
  }

  update(plantillaEncuesta: PlantillasEncuestasModel): Observable<any> {
    const body = JSON.stringify(plantillaEncuesta);
    let headers = {'Content-Type': 'application/x-www-form-urlencoded'};
    return this.http.put(`${environment.API_DOMAINS.PLANTILLAS_ENCUESTAS}/plantillaEncuesta`, body, {headers: headers});
  }
}
