import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../environments/environment';

import {TiposPlantillasCorreosModel} from '../models/tipos-plantillas-correos.model';
import {TiposPlantillasEncuestasModel} from '../models/tipos-plantillas-encuestas.model';
import {EstadosPlantillasEncuestasModel} from '../models/estados-plantillas-encuestas.model';
import {EstadosEncuestasModel} from '../models/estados-encuestas.model';



@Injectable({
  providedIn: 'root'
})
export class ComunesService {

  constructor(private http: HttpClient) {
  }

  listTipoPlantillasCorreos(): Observable<TiposPlantillasCorreosModel[]> {
    return this.http
      .get<TiposPlantillasCorreosModel[]>(`${environment.API_DOMAINS.COMUNES}/tipoPlantillaCorreo`)
      .pipe(
        map((result: any) => {
          return result.map((tipo: any) => {
            return new TiposPlantillasCorreosModel(tipo);
          });
        })
      );
  }

  listTipoPlantillasEncuestas(): Observable<TiposPlantillasEncuestasModel[]> {
    return this.http
      .get<TiposPlantillasEncuestasModel[]>(`${environment.API_DOMAINS.COMUNES}/tipoEncuesta`)
      .pipe(
        map((result: any) => {
          return result.map((tipo: any) => {
            return new TiposPlantillasEncuestasModel(tipo);
          });
        })
      );
  }

  listEstadosPlantillasEncuestas(): Observable<EstadosPlantillasEncuestasModel[]> {
    return this.http
      .get<EstadosPlantillasEncuestasModel[]>(`${environment.API_DOMAINS.COMUNES}/estadoPlantillaEncuesta`)
      .pipe(
        map((result: any) => {
          return result.map((tipo: any) => {
            return new EstadosPlantillasEncuestasModel(tipo);
          });
        })
      );
  }

  listEstadosEncuestas(): Observable<EstadosEncuestasModel[]> {
    return this.http
      .get<EstadosEncuestasModel[]>(`${environment.API_DOMAINS.COMUNES}/estadoEncuesta`)
      .pipe(
        map((result: any) => {
          return result.map((tipo: any) => {
            return new EstadosEncuestasModel(tipo);
          });
        })
      );
  }

}

