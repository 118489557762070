import {Deserializable} from "./deserializable.interface";

export class PlantillasEncuestasModel implements Deserializable<PlantillasEncuestasModel> {
  public idPlantillaEncuesta: string;
  public tituloPlantilla: string;
  public idTipoEncuesta: number;
  public instruccionesOnline: string;
  public tema: string;
  public logo: string;
  public preguntas: string;
  public eliminado: boolean;
  public estado: number;


  constructor(plantillaEncuestaJson?: any) {
    this.idPlantillaEncuesta = plantillaEncuestaJson && plantillaEncuestaJson.idPlantillaEncuesta || null;
    this.tituloPlantilla = plantillaEncuestaJson && plantillaEncuestaJson.tituloPlantilla || null;
    this.idTipoEncuesta = plantillaEncuestaJson && plantillaEncuestaJson.idTipoEncuesta || null;
    this.instruccionesOnline = plantillaEncuestaJson && plantillaEncuestaJson.instruccionesOnline || null;
    this.tema = plantillaEncuestaJson && plantillaEncuestaJson.tema || null;
    this.logo = plantillaEncuestaJson && plantillaEncuestaJson.logo || null;
    this.preguntas = plantillaEncuestaJson && plantillaEncuestaJson.preguntas || null;
    if (plantillaEncuestaJson) {
      this.eliminado = plantillaEncuestaJson.eliminado;
    } else {
      this.eliminado = null;
    }
    this.estado = plantillaEncuestaJson && plantillaEncuestaJson.estado || null;
  }

  deserialize(input: any): PlantillasEncuestasModel {
    Object.assign(this, input);
    return this;
  }
}
