import {Deserializable} from "./deserializable.interface";
import {EncuestaResultadoCabeceraModel} from "./encuesta-resultado-cabecera.model";
import {EncuestaResultadoPreguntasModel} from "./encuesta-resultado-preguntas.model";

export class EncuestaReportePdfModel implements Deserializable<EncuestaReportePdfModel> {
  public informacionEncuesta: EncuestaResultadoCabeceraModel;
  public informacionRespuestas: EncuestaResultadoPreguntasModel[];


  constructor(informacion?: any) {
    this.informacionEncuesta = informacion && informacion.informacionEncuesta || null;
    this.informacionRespuestas = informacion && informacion.informacionRespuestas || null;

  }

  deserialize(input: any): EncuestaReportePdfModel {
    Object.assign(this, input);
    return this;
  }
}

