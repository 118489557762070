<!--<app-header></app-header>-->
<div class="container-fluid margin-bottom-10"
     style="background: url(assets/img/bg-1.jpg) no-repeat;margin-top:55px;background-size:cover!important;">
  <section>
    <div style=" margin-top: -55px;">
      <div class="col-xl-12 col-12 padding-inside">
        <div class="col-xl-12 col-12" [ngStyle]="{'background': modelEncuesta.tema, 'height': '30px'}">
        </div>
        <div class="col-xl-12 col-12 card">
          <div class="col-xl-10 mx-auto col-12 margin-top-20">
            <div class="row-fluid text-center mx-auto">
              <h1 class="text-center">{{modelEncuesta.nombreEncuesta}}</h1>
            </div>
            <div class="row-fluid text-center mx-auto">
              <h2 class="text-center">Curso: <span style="font-size:22px!important;" class="bold">{{modelEncuesta.curso}}</span>
              </h2>
              <h2 class="text-center">Fecha Envío Encuestas: <span style="font-size:22px!important;" class="bold">{{modelEncuesta.fechaEnvio}}</span>
              </h2>
              <h2 class="text-center obligatorio" *ngIf="modelEncuesta.fechaLimiteRespuesta != null">Fecha Límite
                Respuesta: <span style="font-size:22px!important;"
                                 class="bold">{{modelEncuesta.fechaLimiteRespuesta}}</span>
              </h2>
            </div>
            <div class="row-fluid text-center mx-auto">
              <p>Instrucciones: {{modelEncuesta.instruccionesOnline}}</p>
            </div>
            <div class="row-fluid text-center mx-auto margin-top-20 text-center">
              <h2>Preguntas de la Encuesta:</h2>
            </div>
            <div class="row-fluid mx-auto margin-top-20">
              <div class="tab-content">
                <div class="form-group col-xl-6 col-12 mx-auto" *ngFor="let pregunta of itemsByPage">
                  <label class="margin-top-20" *ngIf="pregunta.tipo != 'title'">
                    <span class="obligatorio" *ngIf="pregunta.requerido">*</span>{{pregunta.nombre}}:
                  </label>
                  <div [ngSwitch]="pregunta.tipo">
                    <div class="form-group col-xl-12 col-xs-12">
                      <input *ngSwitchCase="'text'" class="form-control"
                             [id]="pregunta.key"
                             [type]="pregunta.tipo">
                      <textarea *ngSwitchCase="'textarea'" class="form-control">
                      </textarea>

                      <input *ngSwitchCase="'number'"
                             [id]="pregunta.key"
                             [type]="pregunta.tipo">
                    </div>
                    <div *ngSwitchCase="'radio'">
                      <label *ngFor="let option of pregunta.options">
                        <input
                          type="radio"
                          [name]="pregunta.key"
                          [value]="option.label"> {{option.label}}
                      </label>
                    </div>
                    <div *ngSwitchCase="'checkbox'">
                      <label *ngFor="let option of pregunta.options">
                        <input
                          type="checkbox"
                          [name]="pregunta.key"
                          [value]="option.label"> {{option.label}}
                      </label>
                    </div>
                    <div *ngSwitchCase="'select'">
                      <select class="form-control">
                        <option *ngFor="let option of pregunta.options" [value]="option.value">
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                    <div *ngSwitchCase="'stars'">
                      <bar-rating name="rating" [max]="pregunta.valor"></bar-rating>
                    </div>
                    <div *ngSwitchCase="'matriz'">
                      <table class="table table-sm">
                        <tr>
                          <td></td>
                          <td *ngFor="let alternativa of pregunta.alternativas">
                            {{alternativa.label}}
                          </td>
                        </tr>
                        <tr *ngFor="let estructura of pregunta.estructura, let indexPregunta = index">
                          <td>
                            {{estructura.titulo}}
                          </td>
                          <td *ngFor="let alternativa of estructura.alternativas , let indexAlternativa = index">
                            <input *ngIf="pregunta.tipoCampo === 'checkbox'" type="checkbox"
                                   name="{{pregunta.numeroPregunta}}_{{indexPregunta}}"
                                   [value]="alternativa.value"
                                   [checked]="alternativa.value">
                            <input *ngIf="pregunta.tipoCampo === 'radio'" type="radio"
                                   name="{{pregunta.numeroPregunta}}_{{indexPregunta}}"
                                   [value]="alternativa.value"
                                   [checked]="alternativa.value">
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div *ngSwitchCase="'title'">
                      <h2>{{pregunta.nombre}}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-fluid text-center mx-auto">
            <div class="col-12 mx-auto margin-top-20">
              <!-- pager -->
              <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a class="page-link" (click)="changePage(1)">Primero</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.currentPage - 1)">Anterior</a>
                </li>
                <li *ngFor="let page of pager.pages" class="page-item" [ngClass]="{active:pager.currentPage === page}">
                  <a class="page-link" (click)="changePage(page)">{{page}}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.currentPage + 1)">Siguiente</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
                  <a class="page-link" (click)="changePage(pager.totalPages)">Último</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>
